import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { StartAPensionService } from 'src/app/services/start-a-pension.service';
import { ApplicationDataResponseAction } from '../start-a-pension-form/actions';
import { SaveApplicationDataForUpdateSubmittedAction } from '../start-a-pension-update-submitted/actions';

@Injectable()
export class StartAPensionStartComponentEffects {
  constructor(private actions$: Actions,
    private service: StartAPensionService) {
  }

  getApplicationList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getApplicationList(action.accountId).pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  cancelApplication$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CancelApplicationAction),
    switchMap((action: any) => this.service.cancelApplication(action.accountId, action.applicationId).pipe(
      map((data: any) => {
        return ActionsList.SetApplicationToCancelledAction({ applicationId: action.applicationId })
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ))

  continueApplication$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.ContinueApplicationRequestAction),
    switchMap((action: any) => this.service.getApplicationData(action.accountId, action.applicationId).pipe(
      map((data: any) => {
        return ApplicationDataResponseAction({ applicationData: data.data })
      }),
      catchError(_err => {
        return EMPTY;
      })
    )),
  ))

  updateSubmitted$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.UpdateSubmittedApplicationRequestAction),
    switchMap((action: any) => this.service.getApplicationData(action.accountId, action.applicationId, false).pipe(
      map((data: any) => {
        return SaveApplicationDataForUpdateSubmittedAction({ model: data.data })
      }),
      catchError(_err => {
        return EMPTY;
      })
    )),
  ))
}
