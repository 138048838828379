<div id="start-a-pension-form">

    <ng-container [ngSwitch]="currentStep">
        <ng-container *ngIf="(initialFormData$|async); let initialFormData">

            <!-- not eligible to start a pension -->
            <ng-container *ngIf="initialFormData.errorMessage">
                <h2 class="mb-3">Starting a new pension</h2>
                <div class="">
                    <i> {{initialFormData.errorMessage}}
                    </i>
                </div>
                <div class="d-flex justify-content-between">
                    <app-button class="mt-2" title="Exit" icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                        color="{{'BTN.start-a-pension.cancel.color' | translate}}" (onClick)="exit()">
                    </app-button>
                </div>
            </ng-container>

            <ng-container *ngIf="!initialFormData.errorMessage">
                <!-- step -1 -->
                <ng-container *ngSwitchCase="-1">
                    <h2 class="mb-3">Starting a new pension</h2>
                    <div [innerHtml]="initialFormData.formWording.initialDisclaimer"></div>
                    <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                        class="d-flex">
                        <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}" title="Exit"
                            icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                            color="{{'BTN.start-a-pension.cancel.color' | translate}}" (onClick)="exit()">
                        </app-button>
                        <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" title="Next"
                            icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                            color="{{'BTN.start-a-pension.continue.color' | translate}}"
                            (onClick)="nextStep()"></app-button>
                    </div>
                </ng-container>

                <!-- step 0 -->
                <app-start-a-pension-eligibility [submitting]="submitting" *ngSwitchCase="0" [account]="accountId"
                    [data]="initialFormData"></app-start-a-pension-eligibility>
            </ng-container>
        </ng-container>



        <ng-container *ngIf="applicationData$|async; let applicationData">

            <!-- stepper -->
            <div *ngIf="showStepper && screenWidth > 425" class="d-flex flex-row w-100 pb-1 stepper-container">
                <app-start-a-pension-stepper [stepTitles]="stepperData" [step]="currentStep"
                    [onSelectionChangeFn]="onStepperSelectionChange">
                </app-start-a-pension-stepper>
            </div>
            <!-- step 1 -->
            <ng-container *ngSwitchCase="1">
                <h2 class="mb-3">Confirm your personal details</h2>
                <div class="alert alert-info" *ngIf="validationErrorList.length >0">
                    <ul class="mb-0">
                        <li *ngFor="let data of validationErrorList" [innerHTML]="data"></li>
                    </ul>
                </div>

                <app-member-view [hideMemberNumber]="true" [hidePostalAddress]="true" [tfnTooltip]="tfnTooltipText"
                    [hideTfn]="false" [hideDob]="false" [hideOccupation]="true" [greenIdValidation]="true"
                    [hideCommunicationPreference]="true"></app-member-view>

                <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                    class="d-flex flex-wrap">
                    <div [ngClass]="{'mt-1': screenWidth<768}">
                        <app-button class="flex-fill" title="Exit"
                            icon="{{'BTN.start-a-pension.cancel.icon' | translate}}" [isDisabled]="submitting"
                            color="{{'BTN.start-a-pension.cancel.color' | translate}}" (onClick)="exit()"></app-button>
                    </div>
                    <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                        class="d-flex">
                        <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                            class="flex-fill {{'BTN.start-a-pension.update-details.class'|translate}}"
                            title="Update details" icon="{{'BTN.start-a-pension.update-details.icon' | translate}}"
                            color="{{'BTN.start-a-pension.update-details.color' | translate}}" [isDisabled]="submitting"
                            (onClick)="updatePersonalDetails()">
                        </app-button>
                        <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                            title="Next" icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                            [isDisabled]="validationErrorList.length >0"
                            color="{{'BTN.start-a-pension.continue.color' | translate}}" [isDisabled]="submitting"
                            loading (onClick)="nextStepMemberDetails()"></app-button>
                    </div>
                </div>
            </ng-container>

            <!-- step 2 -->
            <ng-container *ngSwitchCase="2">
                <app-start-a-pension-account-details [submitting]="submitting" [application]="applicationId"
                    [account]="accountId" [options]="applicationData.pensionInvestmentOptions"
                    [data]="applicationData.pensionApplicationAccountDetails"
                    [balance]="applicationData.pensionApplicationBaseDetails.accountBalance"></app-start-a-pension-account-details>
            </ng-container>

            <!-- step 3 -->
            <ng-container *ngSwitchCase="3">
                <app-start-a-pension-payment-details [submitting]="submitting" [application]="applicationId"
                    [account]="accountId"
                    [options]="applicationData.pensionApplicationAccountDetails.savedData.currentOptions"
                    [data]="applicationData.pensionApplicationPaymentDetails"
                    [ttr]="applicationData.pensionApplicationBaseDetails.isTtrApplication"
                    [balance]="applicationData.pensionApplicationBaseDetails.accountBalance"></app-start-a-pension-payment-details>
            </ng-container>

            <!-- step 4 -->
            <ng-container *ngSwitchCase="4">
                <app-start-a-pension-bank-details [submitting]="submitting" [application]="applicationId"
                    [account]="accountId"
                    [data]="applicationData.pensionApplicationBankDetails"></app-start-a-pension-bank-details>
            </ng-container>

            <!-- step 5 -->
            <ng-container *ngSwitchCase="5">
                <app-start-a-pension-beneficiary [submitting]="submitting" [application]="applicationId"
                    [account]="accountId"
                    [data]="applicationData.pensionApplicationBeneficiaryDetails"></app-start-a-pension-beneficiary>
            </ng-container>

            <!-- step 6 -->
            <ng-container *ngSwitchCase="6">
                <app-start-a-pension-documents [submitting]="submitting" [application]="applicationId"
                    [account]="accountId" [data]="applicationData.pensionApplicationDocumentsDetails"
                    (onDocumentsSaved)="onDocumentsSaved($event)"></app-start-a-pension-documents>
            </ng-container>

            <ng-container *ngSwitchCase="7">
                <app-start-a-pension-review [submitting]="submitting" [application]="applicationId"
                    [account]="accountId" [data]="applicationData"
                    (onSubmissionSuccesful)="onSubmissionSuccesful($event)"></app-start-a-pension-review>
            </ng-container>
        </ng-container>
    </ng-container>
</div>