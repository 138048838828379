<ngContainer *ngIf="formControl">
    <table class="table mr-2 access-your-super-drawdown" *ngIf="screenWidth > 767">
        <thead>
            <tr>
                <th scope="col" width="50%">Option</th>
                <th scope="col" width="50%">{{isCurrentOptions ? 'Current balance (%)' :
                    'Future pension payments (%)'}}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of formControl?.controls; let i = index; trackBy: trackByFn">
                <tr>
                    <td>{{item.value.investmentOptionName}}</td>
                    <td>
                        <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="0"
                            [showincremental]="false">
                        </app-entry-number>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td colspan="2">
                    <div class="d-flex justify-content-end">
                        <div *ngIf="formControl.value.length > 0" class="mb-0 access-your-super-drawdown alert "
                            [ngClass]="{'alert-success': total === 100, 'alert-danger': total !== 100}">
                            Total: {{total | convertNan }}% of 100%
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <table class="table mr-2 access-your-super-drawdown" *ngIf="screenWidth < 768">
        <thead></thead>
        <tbody>
            <ng-container *ngFor="let item of formControl?.controls; let i = index; trackBy: trackByFn">
                <tr>
                    <td>Option:</td>
                    <td>{{item.value.investmentOptionName}}</td>
                </tr>
                <tr>
                    <td>{{isCurrentOptions ? 'Current balance (%)' :
                        'Future pension payments (%)'}}:</td>
                    <td>
                        <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="0"
                            [showincremental]="false">
                        </app-entry-number>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td colspan="2">
                    <div class="d-flex justify-content-end">
                        <div *ngIf="formControl.value.length > 0" class="mb-0 access-your-super-drawdown alert "
                            [ngClass]="{'alert-success': total === 100, 'alert-danger': total !== 100}">
                            Total: {{total | convertNan }}% of 100%
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</ngContainer>