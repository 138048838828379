import { KeyValueModel } from '@ifaa-components/ui-components';
import { createSelector } from '@ngrx/store';
import { AppState } from '../app.states';
import { ICommonState } from './common.state';

export const getCommon = (state: AppState) => state.commonState;

export const commomState_IsLoading = createSelector(
  getCommon,
  (state: ICommonState) => state.isLoading
);

export const commonState_IsFetching = createSelector(
  getCommon,
  (state: ICommonState) => state.isFetching
);

export const commonState_IsSending = createSelector(
  getCommon,
  (state: ICommonState) => state.isSending
);

export const commomState_MenuList = createSelector(
  getCommon,
  (state: ICommonState) => state.menuList
);

export const commomState_loginViewVideoUrl = createSelector(
  getCommon,
  (state: ICommonState) => state.loginViewVideoUrl
);

export const commomState_SystemConfig_FeatureToggle = createSelector(
  getCommon,
  (state: ICommonState) => {
    return state.systemConfig.featureToggle;
  }
);

export const commonState_SystemConfig_AccountFeatureToggles = createSelector(
  getCommon,
  (state: ICommonState) => {
    return state.systemConfig.accountFeatureToggles
  }
);

export const commonState_SystemConfig_AccountIdFeatureToggles = createSelector(
  getCommon,
  (state: ICommonState) => {
    return state.systemConfig.accountIdFeatureToggles
  }
);

export const commonState_SystemConfig_LimitedServiceToggles = createSelector(
  getCommon,
  (state: ICommonState) => {
    return state.systemConfig.limitedServiceToggles
  }
);

export const commonState_SystemConfig_SiteError = createSelector(
  getCommon,
  (state: ICommonState) => {
    return state.siteError
  }
)

export const commomState_SystemConfig_Messages = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.messages
);

export const commomState_SystemConfig_Config = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.tenantConfig
);

export const commomState_Env = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.env
);

export const commomState_Version = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.version
);


export const commomState_LoggedinInfo = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel
);

export const commomState_GenderList = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.genderList
);

// USE THIS WHEN COMPONENTS NEED TO BE DISABLED FOR BOTH ADVISER OR FUND
export const commomState_ElevatedAccess = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.isAdviserElevated || state.loggedInModel.isFundElevated
);

export const commonState_IsAdviserElevatedAccess = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.isAdviserElevated
);

export const commonState_IsFundElevatedAccess = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.isFundElevated
);

export const commomState_AmountToTransferList = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.amountToTransfer
);

export const commomState_EmploymentArrangementList = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.employmentArrangement
);

export const commomState_OccupationDescriptionList = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.occupationDescription
);

export const commomState_AustralianCitizenOrAustralianPermanentResident = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.australianCitizenOrAustralianPermanentResident
);

export const commomState_ScreenWidth = createSelector(
  getCommon,
  (state: ICommonState) => state.screenWidth
);

export const commomState_PersonalContributions_ElegibilityToContribute = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.personalContributions_ElegibilityToContribute
);

export const commomState_PersonalContributions_ContributeFrequency = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.personalContributions_ContributeFrequency
);


export const commomState_PersonalContributions_PaymentsMethods = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.personalContributions_PaymentsMethods
);

export const commomState_PersonalContributions_BankPayments = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.personalContributions_BankPayments
);

export const commomState_BooleanList = createSelector(
  getCommon,
  (state: ICommonState) => [
    { key: true, value: 'True' } as KeyValueModel,
    { key: false, value: 'False' } as KeyValueModel,
  ]
);

export const commomState_YesNoList = createSelector(
  getCommon,
  (state: ICommonState) => [
    { key: true, value: 'Yes' } as KeyValueModel,
    { key: false, value: 'No' } as KeyValueModel,
  ]
);

export const commomState_AmountTypeList = createSelector(
  getCommon,
  (state: ICommonState) => [
    { key: 2, value: 'Full Claim' } as KeyValueModel,
    { key: 1, value: 'Partial Claim' } as KeyValueModel,
  ]
);


export const commomState_ClaimTypeList = createSelector(
  getCommon,
  (state: ICommonState) => [
    { key: 1, value: 'Redundancy' } as KeyValueModel,
    { key: 2, value: 'Retirement' } as KeyValueModel,
    { key: 3, value: 'Withdrawal' } as KeyValueModel,
  ]
);

export const commomState_CommunicationPreferenceList = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.communicationPreferenceList
);

export const commonState_AccessYourSuperApplicationStatuses = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.accessYourSuperApplicationStatuses
);

export const commonState_AccessYourSuperPaymentTypes = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.accessYourSuperPaymentTypes
);

export const commonState_StartAPensionApplicationStatuses = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.startAPensionApplicationStatuses
);

export const commonState_MemberData = createSelector(
  getCommon,
  (state: ICommonState) => state.member
)
