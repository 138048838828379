<div id="start-a-pension-start" *ngIf="filter$|async; let filter">
  <div class="d-flex justify-content-end">
    <app-button-toggle [control]="filter.controls.activeOptionButton"
      list="startAPensionApplicationStatuses"></app-button-toggle>
  </div>
  <p class="pt-2" *ngIf="filter.value.activeOptionButton === 'Draft'">Your applications in draft will be automatically
    cancelled after 30 days from when they were created.</p>
  <p class="pt-2" *ngIf="filter.value.activeOptionButton === 'Submitted'">You can add additional documentation to your
    submitted applications which have not been completed.</p>
  <div class="table-wrapper mt-3" *ngIf="screenWidth > 425">
    <table class="table {{'VIEWS.Global.table-default-style' | translate}}">
      <thead>
        <tr>
          <th width="33%" scope="col">Type of account</th>
          <th *ngIf="filter.value.activeOptionButton !== 'Submitted'" width="20%" scope="col">Created on</th>
          <th *ngIf="filter.value.activeOptionButton === 'Submitted'" width="20%" scope="col">Submitted on</th>
          <th *ngIf="filter.value.activeOptionButton === 'Submitted'" width="20%" scope="col">Case status</th>
          <th *ngIf="filter.value.activeOptionButton !== 'Cancelled' && (screenWidth > 768)" width="30%" scope="col">
            Actions
          </th>
          <th *ngIf="filter.value.activeOptionButton === 'Cancelled'" width="20%" scope="col">Cancelled on</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of currentList">
          <tr>
            <td>{{item.typeOfAccount}}</td>
            <!-- created date -->
            <td *ngIf="filter.value.activeOptionButton !== 'Submitted'">{{item.createdOn | date:'dd MMM yyyy'}}</td>
            <!-- submitted on / cancelled on field -->
            <td *ngIf="filter.value.activeOptionButton === 'Cancelled'">{{item.cancelledOn | date:'dd MMM yyyy'}}</td>
            <td *ngIf="filter.value.activeOptionButton === 'Submitted'">{{item.submittedOn | date:'dd MMM yyyy'}}</td>
            <td *ngIf="filter.value.activeOptionButton === 'Submitted'">{{item.caseStatus}}</td> <!-- case status -->
            <td *ngIf="filter.value.activeOptionButton === 'Draft' && (screenWidth > 768)">
              <div class="d-flex flex-row">
                <app-button class="continue-btn" title="Continue"
                  icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                  color="{{'BTN.start-a-pension.continue.color' | translate}}" [isDisabled]="isSubmitting$|async"
                  (onClick)="continueApplication(item.pensionApplicationId)"></app-button>
                <app-button class="ml-2 cancel-btn" title="Cancel"
                  icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                  color="{{'BTN.start-a-pension.cancel.color' | translate}}" [isDisabled]="isSubmitting$|async"
                  (onClick)="cancelApplication($event, item.pensionApplicationId)" [displayDialog]="true"
                  [dialogText]="cancelDialogText"></app-button>
              </div>
            </td>
            <td *ngIf="filter.value.activeOptionButton === 'Submitted' && (screenWidth > 768)">
              <div class="d-flex flex-row flex-fill">
                <app-button *ngIf="item.caseStatusId !== 3" class="continue-btn" title="Update documents"
                  icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                  color="{{'BTN.start-a-pension.continue.color' | translate}}" [isDisabled]="isSubmitting$|async"
                  (onClick)="updateSubmittedApp(item.pensionApplicationId)"></app-button>
              </div>
            </td>
          </tr>
          <tr *ngIf="filter.value.activeOptionButton === 'Draft' && (screenWidth < 769)">
            <td colspan="2">
              <div class="d-flex flex-row flex-fill">
                <app-button class="mr-1 flex-fill continue-btn" title="Continue"
                  icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                  color="{{'BTN.start-a-pension.continue.color' | translate}}" [isDisabled]="isSubmitting$|async"
                  (onClick)="continueApplication(item.pensionApplicationId)"></app-button>
                <app-button class="ml-1 flex-fill cancel-btn" title="Cancel"
                  icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                  color="{{'BTN.start-a-pension.cancel.color' | translate}}" [isDisabled]="isSubmitting$|async"
                  (onClick)="cancelApplication($event, item.pensionApplicationId)" [displayDialog]="true"
                  [dialogText]="cancelDialogText"></app-button>
              </div>
            </td>
          </tr>
          <tr *ngIf="filter.value.activeOptionButton === 'Submitted' && (screenWidth < 769)">
            <td colspan="3">
              <app-button *ngIf="item.caseStatusId !== 3" class="continue-btn" title="Update documents"
                icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                color="{{'BTN.start-a-pension.continue.color' | translate}}" [isDisabled]="isSubmitting$|async"
                (onClick)="updateSubmittedApp(item.pensionApplicationId)"></app-button>
            </td>
          </tr>

        </ng-container>
      </tbody>
    </table>
  </div>
  <div *ngIf="screenWidth < 426">
    <div class="mobile-application-view" *ngFor="let item of currentList">
      <h3>{{item.typeOfAccount}}</h3>
      <h5 *ngIf="filter.value.activeOptionButton !== 'Submitted'">Created on: {{item.createdOn | date:'dd MMM yyyy'}}
      </h5>
      <h5 *ngIf="filter.value.activeOptionButton === 'Cancelled'">Cancelled on: {{item.cancelledOn | date:'dd MMM
        yyyy'}}</h5>
      <h5 *ngIf="filter.value.activeOptionButton === 'Submitted'">Submitted on: {{item.submittedOn | date:'dd MMM
        yyyy'}}</h5>
      <h5 *ngIf="filter.value.activeOptionButton === 'Submitted'">Case status: {{item.caseStatus}}</h5>
      <div class="d-flex flex-column flex-fill mt-2" *ngIf="filter.value.activeOptionButton === 'Draft'">
        <app-button class="flex-fill mb-1 continue-btn" title="Continue"
          icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
          color="{{'BTN.start-a-pension.continue.color' | translate}}" [isDisabled]="isSubmitting$|async"
          (onClick)="continueApplication(item.pensionApplicationId)"></app-button>
        <app-button class="flex-fill cancel-btn" title="Cancel" icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
          color="{{'BTN.start-a-pension.cancel.color' | translate}}" [isDisabled]="isSubmitting$|async"
          (onClick)="cancelApplication($event, item.pensionApplicationId)" [displayDialog]="true"
          [dialogText]="cancelDialogText"></app-button>

      </div>
      <app-button *ngIf="item.caseStatusId !== 3 && filter.value.activeOptionButton === 'Submitted'"
        class="continue-btn mt-2" title="Update documents" icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
        color="{{'BTN.start-a-pension.continue.color' | translate}}" [isDisabled]="isSubmitting$|async"
        (onClick)="updateSubmittedApp(item.pensionApplicationId)"></app-button>
    </div>
  </div>
  <ng-container *ngIf="model$|async; let model">
    <div *ngIf="(canCreateNewApp$|async)" class="d-flex justify-content-end">
      <app-button class="mt-3 ml-1" title="Start new application"
        icon="{{'BTN.start-a-pension.start.icon' | translate}}" [isDisabled]="isSubmitting$|async"
        color="{{'BTN.start-a-pension.start.color' | translate}}" (onClick)="createNewApplication()">
      </app-button>
    </div>
  </ng-container>
</div>