import { UnitPricesComponent } from './views/unit-prices/unit-prices.component';
import { TransactionsComponent } from './views/transactions/transactions.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GetTenantDashboard, GetTenantMainLayout, GetTenantNotLoggedInLayout } from './tenant.helper';
import { PersonalDetailsComponent } from './views/personal-details/personal-details.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { PersonalEditComponent } from './views/personal-edit/personal-edit.component';
import { BeneficiaryEditComponent } from './views/beneficiary-edit/beneficiary-edit.component';
import { TransactionListViewComponent } from './views/transaction-list/transaction-list.component';
import { QuestionnaireViewComponent } from './views/questionnaire/questionnaire-view.component';
import { ShowQuestionnaireGuardService } from './route-guard/show-questionnaire-guard.service';
import { InsuranceViewComponent } from './views/insurance-view/insurance-view.component';
import { RollInViewComponent } from './views/rollin-view/rollin-view.component';
import { ContributionsViewComponent } from './views/contributions-view/contributions-view.component';
import { PersonalContributionViewComponent } from './views/personal-contributions-view/personal-contributions-view.component';
import { AssetsViewComponent } from './views/assets-view/assets-view.component';
import { MemberStatementsViewComponent } from './views/member-statements/member-statements-view.component';
import { OtherDocumentsViewComponent } from './views/other-documents/other-documents-view.component';
import { CalculatorViewComponent } from './views/calculator/calculator-view.component';
import { BeneficiaryViewComponent } from './views/beneficiary-view/beneficiary-view.component';
import { ElevatedAccessComponent } from './views/elevated-access/elevated-access.component';
import { MakeContributionViewComponent } from './views/make-a-contribution-view/make-a-contribution-view.component';
import { PensionViewComponent } from './views/pension-view/pension-view.component';
import { InvestmentEditViewComponent } from './views/investment-view/investment-view.component';
import { AccessYourSuperViewComponent } from './views/access-your-super-view/access-your-super-view.component';
import { AccessYourSuperCreateComponent } from './views/access-your-super-create/access-your-super-create.component';
import { UnderlyingInvestmentViewComponent } from './views/underlying-investment-view/underlying-investment-view.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AccountFeatureToggleGuardService } from './route-guard/account-feature-toggle-guard.service';
import { AccountDataGuardService } from './route-guard/account-data-guard.service';
import { FeatureToggleName } from './model/feature-toggle-name.model';
import { MaintenancePageComponent } from './views/maintenance-page/maintenance-page.component';
import { BlackoutGuardService } from './route-guard/blackout-guard.service';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { ErrorPageGuardService } from './route-guard/error-page-guard.service';
import { TakeMeWithYouViewComponent } from './views/take-me-with-you-view/take-me-with-you-view.component';
import { LoginErrorPageComponent } from './views/login/login-error.component';
import { MsalCheckService } from './route-guard/msal-check.service';
import { environment } from 'src/environments/environment';
import { AccessYourSuperUpdateSubmittedComponent } from './modules/shared/components/access-your-super-update-submitted/access-your-super-update-submitted.component';
import { AccessYourSuperReviewSubmittedComponent } from './views/access-your-super-review-submitted/access-your-super-review-submitted.component';
import { NoticeOfIntentToClaimTaxDeductionViewComponent } from './views/notice-of-intent-tax-deduction/notifice-of-intent-tax-deduction-view.component';
import { PensionChangeScheduleViewComponent } from './views/pension-change-schedule-view/pension-change-schedule-view.component';
import { PensionChangeScheduleRequestsViewComponent } from './views/pension-change-schedule-requests-view/pension-change-schedule-requests-view.component';
import { InvestmentHistoryViewComponent } from './views/investment-history-view/investment-history-view.component';
import { StartAPensionCreateComponent } from './views/start-a-pension-create/start-a-pension-create.component';
import { StartAPensionViewComponent } from './views/start-a-pension/start-a-pension-view.component';
import { StartAPensionUpdateSubmittedViewComponent } from './views/start-a-pension-update-submitted/start-a-pension-update-submitted-view.component';
import { InsuranceUpdateViewComponent } from './views/insurance-update-view/insurance-update-view.component';
import { InsuranceUpdateHistoryViewComponent } from './views/insurance-update-history-view/insurance-update-history-view.component';

var routes: Routes = [
  {
    path: 'verve',
    loadChildren: () => import('./modules/verv/verve-routing.module').then(m => m.VerveRoutingModule)
  },
  {
    path: 'cirt',
    loadChildren: () => import('./modules/cirt/cirt-routing.module').then(m => m.CIRTRoutingModule)
  },
  {
    path: 'smrt',
    loadChildren: () => import('./modules/smrt/smrt-routing.module').then(m => m.SMRTRoutingModule)
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent,
  },
  {
    path: 'maintenance',
    component: GetTenantNotLoggedInLayout.layout(),
    canActivate: [MsalGuard, AccountDataGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: MaintenancePageComponent
      }
    ]
  },
  {
    path: 'error',
    component: GetTenantNotLoggedInLayout.layout(),
    canActivate: [ErrorPageGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ErrorPageComponent
      }
    ]
  },
  {
    path: 'fundelevated',
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: ElevatedAccessComponent
      }
    ]
  },
  {
    path: 'adviserelevated',
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: ElevatedAccessComponent
      }
    ]
  },
  {
    path: '',
    canActivate: [MsalCheckService],
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: LoginErrorPageComponent
      }
    ]
  },
  {
    path: '', canActivate: [MsalGuard, AccountDataGuardService, BlackoutGuardService], children: [
      {
        path: 'change-password',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [],
        children: [
          {
            outlet: 'master',
            path: '',
            component: ChangePasswordComponent
          }
        ]
      },
      {
        path: 'dashboard',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [],
        children: [
          {
            outlet: 'master',
            path: '',
            component: GetTenantDashboard.layout()
          }
        ]
      },
      {
        path: 'transactions',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [],
        children: [
          {
            outlet: 'master',
            path: '',
            component: TransactionsComponent
          }
        ]
      },
      {
        path: 'personal-details',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.personalDetails.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: PersonalDetailsComponent
          }
        ]
      },
      {
        path: 'personal-edit',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.personalDetails.update] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: PersonalEditComponent
          }
        ]
      },
      {
        path: 'beneficiaries-edit',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.beneficiaries.update] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: BeneficiaryEditComponent
          }
        ]
      },
      {
        path: 'beneficiaries',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.beneficiaries.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: BeneficiaryViewComponent
          }
        ]
      },
      {
        path: environment.takeMeWithYouUrl,
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.takeMeWithYou.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: TakeMeWithYouViewComponent
          }
        ]
      },

      {
        path: 'transaction-listing',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.transactions.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: TransactionListViewComponent
          }
        ]
      },
      {
        path: 'start-questionnaire',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [ShowQuestionnaireGuardService, AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.insuranceQuestionnaire.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: QuestionnaireViewComponent
          }
        ]
      },
      {
        path: 'insurance',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: {
          flag: [FeatureToggleName.member.account.insuranceDetails.view,
          FeatureToggleName.member.account.insuranceQuestionnaire.view
          ]
        },
        children: [
          {
            outlet: 'master',
            path: '',
            component: InsuranceViewComponent
          }
        ]
      },
      {
        path: 'insurance/update',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: {
          flag: [FeatureToggleName.member.account.insuranceDetails.view, FeatureToggleName.member.account.insuranceChangeRequest.view]
        },
        children: [
          {
            outlet: 'master',
            path: '',
            component: InsuranceUpdateViewComponent
          }
        ]
      },
      {
        path: 'insurance/change-history',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: {
          flag: [FeatureToggleName.member.account.insuranceDetails.view, FeatureToggleName.member.account.insuranceChangeRequest.view]
        },
        children: [
          {
            outlet: 'master',
            path: '',
            component: InsuranceUpdateHistoryViewComponent
          }
        ]
      },
      // {
      //   path: 'switch-investments',
      //   component: GetTenantMainLayout.mainLayout(),
      //   canActivate: [],
      //   children: [
      //     {
      //       outlet: 'master',
      //       path: '',
      //       component: SwitchInvestmentsViewComponent
      //     }
      //   ]
      // },
      {
        path: 'rollin',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.rollIn.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: RollInViewComponent
          }
        ]
      },
      {
        path: 'contributions',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.contributionCapLimits.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: ContributionsViewComponent
          }
        ]
      },
      {
        path: 'personal-contributions',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.personalContribution.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: PersonalContributionViewComponent
          }
        ]
      },
      {
        path: 'make-a-contribution',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.makeAContribution.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: MakeContributionViewComponent
          }
        ]
      },

      {
        path: 'view-assets',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.underlyingInvestmentView.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: AssetsViewComponent
          }
        ]
      },
      {
        path: 'unit-prices',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.unitPriceListing.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: UnitPricesComponent
          }
        ]
      },
      {
        path: 'member-statements',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.viewStatements.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: MemberStatementsViewComponent
          }
        ]
      },
      {
        path: 'other-documents',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.otherDocuments.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: OtherDocumentsViewComponent
          }
        ]
      },
      {
        path: 'pension-details',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.pensionDetails.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: PensionViewComponent
          }
        ]
      },
      {
        path: 'pension-details/change-schedule',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.changePensionSchedule.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: PensionChangeScheduleViewComponent
          }
        ]
      },
      {
        path: 'pension-details/change-schedule/requests',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.changePensionSchedule.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: PensionChangeScheduleRequestsViewComponent
          }
        ]
      },
      {
        path: 'calculator',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.superCalculator.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: CalculatorViewComponent
          }
        ]
      },
      {
        path: 'investment',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.manageInvestments.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: InvestmentEditViewComponent
          }
        ]
      },
      {
        path: 'investment/switch-history',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.manageInvestments.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: InvestmentHistoryViewComponent
          }
        ]
      },
      {
        path: environment.underlyingInvestment,
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.underlyingInvestmentSummary.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: UnderlyingInvestmentViewComponent
          }
        ]
      },
      {
        path: 'access-your-super',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.accessYourSuper.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: AccessYourSuperViewComponent
          }
        ]
      },
      {
        path: 'access-your-super/create-application',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.accessYourSuper.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: AccessYourSuperCreateComponent
          }
        ]
      },
      {
        path: 'access-your-super/review',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.accessYourSuper.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: AccessYourSuperReviewSubmittedComponent
          }
        ]
      },
      {
        path: 'claim-a-tax-deduction',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.intentToClaimTaxDeduction.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: NoticeOfIntentToClaimTaxDeductionViewComponent
          }
        ]
      },
      {
        path: 'start-a-pension',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.startAPension.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: StartAPensionViewComponent
          }
        ]
      },
      {
        path: 'start-a-pension/create-application',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.startAPension.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: StartAPensionCreateComponent
          }
        ]
      },
      {
        path: 'start-a-pension/review',
        component: GetTenantMainLayout.mainLayout(),
        canActivate: [AccountFeatureToggleGuardService],
        data: { flag: [FeatureToggleName.member.account.startAPension.view] },
        children: [
          {
            outlet: 'master',
            path: '',
            component: StartAPensionUpdateSubmittedViewComponent
          }
        ]
      },
      {
        path: '**',
        redirectTo: '/dashboard'
      }

    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //initialNavigation:'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
