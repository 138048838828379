import { createReducer, Action, on } from '@ngrx/store';
import {
    createFormGroupState,
    onNgrxForms, onNgrxFormsAction, setValue, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import {
    SetupSupportingDocumentOverviewAction, ResetFileRequestFailedAction, GreenIdVerifiedAction, ResetComponentStateAction,
    SetFileRequestFailedAction, UpdateSavedDocumentAction, UpdateDeletedDocumentAction, AddNewAppFileAction,
    RemoveAppFileAction, UpdateReplacedDocumentAction,
    SetDocumentsFormDataAction,
} from './actions';
import { StartAPensionDocumentsForm, IStartAPensionDocumentsFormComponentState } from './state';
import { requiredTrue } from 'ngrx-forms/validation';
import { AppFileModel, Helper } from '@ifaa-components/ui-components';

export const initialState: IStartAPensionDocumentsFormComponentState = {
    formData: null,
    form: createFormGroupState('StartAPensionDocumentsForm', new StartAPensionDocumentsForm()),
    uploadedDocument: null,
    deletedDocument: null,
    replacedDocument: null,
    fileRequestFailed: null,
};

var helper = new Helper();

export const validateStartAPensionDocumentsForm = updateGroup<StartAPensionDocumentsForm>({
    greenIdVerified: validate(requiredTrue),
})

const reducer = createReducer(initialState,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, _action) => {
        return state;
    }),
    on(SetDocumentsFormDataAction, (state, { data }) => {
        return {
            ...state,
            formData: data,
        }
    }),
    on(ResetComponentStateAction, (_state) => initialState),
    on(GreenIdVerifiedAction, (state, { verified }) => {
        return {
            ...state,
            form: setValue(Object.assign(new StartAPensionDocumentsForm(),
                {
                    ...state.form.value,
                    greenIdVerified: verified
                }
            ))(state.form)
        }
    }),
    on(SetupSupportingDocumentOverviewAction, (state, { documentTypeId, documentAmount, isDocumentRequired }) => {
        var clone = helper.clone(state.form.value.supportingDocuments)
        var files = [];

        for (let i = 0; i < documentAmount; i++) {
            files.push(new AppFileModel())
        }

        var documentOverview = {
            documentTypeId,
            files,
            isDocumentRequired
        }

        return {
            ...state,
            form: setValue(Object.assign(new StartAPensionDocumentsForm(),
                {
                    ...state.form.value,
                    supportingDocuments: [...clone, documentOverview]
                }
            ))(state.form)
        }
    }),
    on(AddNewAppFileAction, (state, { overviewIndex }) => {
        var clone = helper.clone(state.form.value.supportingDocuments)

        clone[overviewIndex].files.push(new AppFileModel())

        return {
            ...state,
            form: setValue(Object.assign(new StartAPensionDocumentsForm(),
                {
                    ...state.form.value,
                    supportingDocuments: [...clone]
                }
            ))(state.form),
            uploadedDocument: null
        }
    }),
    on(RemoveAppFileAction, (state, { overviewIndex, uploadIndex }) => {
        var clone = helper.clone(state.form.value.supportingDocuments);

        clone[overviewIndex].files.splice(uploadIndex, 1);

        return {
            ...state,
            form: setValue(Object.assign(new StartAPensionDocumentsForm(),
                {
                    ...state.form.value,
                    supportingDocuments: [...clone]
                }
            ))(state.form)
        }
    }),
    on(UpdateSavedDocumentAction, (state, { uploadedDocumentId, fileName, documentTypeId, documentIndex, controlId }) => {
        return {
            ...state,
            uploadedDocument: {
                uploadedDocumentId,
                fileName,
                documentTypeId,
                documentIndex,
                controlId
            }
        }
    }),
    on(UpdateDeletedDocumentAction, (state, { uploadedDocumentId, overviewIndex, uploadIndex, controlId }) => {
        return {
            ...state,
            deletedDocument: {
                uploadedDocumentId,
                overviewIndex,
                uploadIndex,
                controlId
            }
        }
    }),
    on(UpdateReplacedDocumentAction, (state, { documentTypeId, controlId, documentIndex, fileName, payload }) => {
        return {
            ...state,
            replacedDocument: {
                documentTypeId,
                controlId,
                documentIndex,
                fileName,
                payload
            },
        }
    }),
    on(SetFileRequestFailedAction, (state, { controlId, file }) => {
        return {
            ...state,
            fileRequestFailed: {
                controlId,
                file
            }
        }
    }),
    on(ResetFileRequestFailedAction, (state, { }) => {
        return {
            ...state,
            fileRequestFailed: null
        }
    }),
);

const editStartAPensionDocumentsReducerFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateStartAPensionDocumentsForm
)

export function startAPensionDocumentFormComponentReducer(state: any | undefined, action: Action) {
    return editStartAPensionDocumentsReducerFormState(state, action);
}
