import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import {
    SetupSupportingDocumentOverviewAction, ResetFileRequestFailedAction, GreenIdVerifiedAction, ResetComponentStateAction, SaveDocumentAction, DeleteDocumentAction,
    AddNewAppFileAction, SubmitFormAction, RemoveAppFileAction, ReplaceDocumentAction,
    SetDocumentsFormDataAction
} from "./actions";
import { AppFileModel, Helper } from "@ifaa-components/ui-components";
import { MarkAsTouchedAction, SetErrorsAction, SetValueAction } from "ngrx-forms";
import { debounceTime, distinctUntilChanged, } from "rxjs/operators";
import { commomState_ElevatedAccess } from 'src/app/store/common/common.selectors';
import { PensionApplicationDocumentModel, PensionApplicationDocumentsFormModel, PensionApplicationSupportingDocumentModel } from "src/app/model/start-a-pension-form.model";
import { startAPensionDocumentsForm_DeletedDocument, startAPensionDocumentsForm_FileRequestFailed, startAPensionDocumentsForm_Files, startAPensionDocumentsForm_Form, startAPensionDocumentsForm_ReplacedDocument, startAPensionDocumentsForm_SupportingDocuments, startAPensionDocumentsForm_UploadedDocument } from "./selectors";
import { ResetParentFormStateAction, SetCurrentStepAction } from "../actions";

@Component({
    selector: 'app-start-a-pension-documents',
    templateUrl: './start-a-pension-documents-form.component.html',
    styleUrls: ['./start-a-pension-documents-form.component.scss'],
    host: {
        class: 'w-100'
    }
})

export class StartAPensionDocumentsFormComponent extends ComponentBase implements OnInit, OnDestroy {
    helper = new Helper();

    @Output() onDocumentsSaved: EventEmitter<any> = new EventEmitter();

    @Input() set data(value: PensionApplicationDocumentsFormModel) {
        this.model = this.helper.clone(value.documents);
        this.formData = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set application(value: number) {
        this.applicationId = value;
    }
    @Input() set draftApplication(value: boolean) {
        this.applicationInDraft = value;
    }
    @Input() set componentTitle(value: string) {
        this.title = value;
    }
    @Input() set submitting(value: boolean) {
        this.isSubmitting = value;
    }

    form$ = this.store.pipe(select(startAPensionDocumentsForm_Form));
    uploadedDocument$ = this.store.pipe(select(startAPensionDocumentsForm_UploadedDocument));
    deletedDocument$ = this.store.pipe(select(startAPensionDocumentsForm_DeletedDocument));
    replacedDocument$ = this.store.pipe(select(startAPensionDocumentsForm_ReplacedDocument));
    uploadFileFailed$ = this.store.pipe(select(startAPensionDocumentsForm_FileRequestFailed));
    supportingDocuments$ = this.store.pipe(select(startAPensionDocumentsForm_SupportingDocuments));
    files$ = this.store.pipe(select(startAPensionDocumentsForm_Files));
    elevatedAccess$ = this.store.pipe(select(commomState_ElevatedAccess));

    formData: PensionApplicationDocumentsFormModel = null;
    model: PensionApplicationDocumentModel[] = null;
    applicationId: number = null;
    accountId: number = null;
    extensions: string[] = ['.pdf', '.png', '.jpg'];
    title: string = "Documents";
    applicationInDraft: boolean = true;
    elevatedAccess = false;
    isSubmitting: boolean = false;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();

        this.store.dispatch(SetDocumentsFormDataAction({ data: this.formData, }))

        if (this.model.length) {
            this.model = this.model.sort((x, y) => x.isDocumentRequired === y.isDocumentRequired ? 0 : x.isDocumentRequired ? -1 : 1);
            this.model.forEach((documentOverview) => {
                this.dispatch(this.store, SetupSupportingDocumentOverviewAction(
                    {
                        documentTypeId: documentOverview.documentTypeId,
                        documentAmount: documentOverview.documentList.length === 0 ? 1 : documentOverview.documentList.length,
                        isDocumentRequired: documentOverview.isDocumentRequired
                    }
                ))
            })
        }

        this.elevatedAccess$.subscribe(x => {
            this.elevatedAccess = x;
        });

        // Setup the form values once the document arrays have been created
        // Debounce of 100ms so this is only called once
        this.sub = this.supportingDocuments$.pipe(
            distinctUntilChanged((x, y) => {
                return x.controls.length === y.controls.length;
            }),
            debounceTime(100)
        ).subscribe(x => {
            if (x.controls.length > 0) {
                this.model.forEach((documentOverview, overviewIndex) => {
                    var docList = documentOverview.documentList;
                    // Create the placeholders for the documents if they dont exist
                    if (!docList.length) {
                        docList.push(new PensionApplicationSupportingDocumentModel())
                    }
                    else {
                        if (this.applicationInDraft) {
                            // If the uploaded documents exist, we need update the form values
                            docList.forEach((uploadedDocument, uploadIndex) => {
                                if (x.controls[overviewIndex].controls.files.controls[uploadIndex] && uploadedDocument.originalFileName) {
                                    this.dispatch(this.store, new SetValueAction(x.controls[overviewIndex].controls.files.controls[uploadIndex]?.id, {
                                        filename: uploadedDocument.originalFileName
                                    } as AppFileModel))
                                }
                            })

                            // If the last index of the documentList has a fileName attached, we know the application is a continuation
                            // so add an empty file placeholder
                            if (docList[docList.length - 1].originalFileName) {
                                this.dispatch(this.store, AddNewAppFileAction({ overviewIndex: overviewIndex }))
                                docList.push(new PensionApplicationSupportingDocumentModel())
                            }
                        }
                    }
                })
            }
        })

        // Subscription listens for changes in files and authorities to handle form validation
        this.sub = this.files$.pipe(
            distinctUntilChanged((x, y) => {
                return JSON.stringify(x) === JSON.stringify(y)
            })
        ).subscribe(x => {
            if (x) {
                for (const index in x) {
                    var control = x[index];

                    // Only validate if the document is required
                    if (control.isRequired) {
                        var uploadedFileIndex = control.files.findIndex(o => o.filename !== "" && o.filename !== undefined);

                        // No file uploaded and no authority given
                        if (uploadedFileIndex === -1 && !control.authority) {
                            const errors = {};
                            errors['required'] = 'At least one document is required';
                            this.dispatch(this.store, new SetErrorsAction(control.filesControlId, errors))
                        }
                        // file uploaded
                        else {
                            this.dispatch(this.store, new SetErrorsAction(control.filesControlId, {}));
                        }
                    }
                }
            }
        })

        // Subscription to handle successful saving of document
        this.sub = this.uploadedDocument$.subscribe(async x => {
            if (x) {
                // Add document to parent model
                var overviewIndex = this.model.findIndex(o => o.documentTypeId === x.documentTypeId);
                var docList = this.model[overviewIndex].documentList;

                docList[x.documentIndex].uploadedDocumentId = x.uploadedDocumentId;
                docList[x.documentIndex].originalFileName = x.fileName;
                this.dispatch(this.store, new SetValueAction(x.controlId, { filename: x.fileName, submitting: false }))

                // Add new file to parent placeholder and ui component
                if (docList[docList.length - 1].originalFileName) {
                    this.model[overviewIndex].documentList.push(new PensionApplicationSupportingDocumentModel());
                    this.dispatch(this.store, AddNewAppFileAction({ overviewIndex: overviewIndex }))
                }

                this.onDocumentsSaved.emit(this.model);
            }
        })

        // Subscription to handle successful deletion of document
        this.sub = this.deletedDocument$.subscribe(x => {
            if (x) {
                this.dispatch(this.store, new MarkAsTouchedAction(x.controlId));

                // Remove the document from the model
                var documentList = this.model[x.overviewIndex].documentList;
                documentList.splice(x.uploadIndex, 1);

                // delete the entry-file-component
                this.dispatch(this.store, RemoveAppFileAction({ overviewIndex: x.overviewIndex, uploadIndex: x.uploadIndex }))

                this.onDocumentsSaved.emit(this.model);
            }
        })

        // Subscription to handle replacing an uploaded document
        this.sub = this.replacedDocument$.subscribe(x => {
            if (x) {
                // Remove the replaced document from the model
                var overviewIndex = this.model.findIndex(o => o.documentTypeId === x.documentTypeId);
                this.model[overviewIndex].documentList[x.documentIndex] = new PensionApplicationSupportingDocumentModel();

                // Send the save document request once the replaced document is deleted
                this.dispatch(this.store, new SetErrorsAction(x.controlId, {}));
                this.dispatch(this.store, SaveDocumentAction({ accountId: this.accountId, applicationId: this.applicationId, documentTypeId: x.documentTypeId, controlId: x.controlId, documentIndex: x.documentIndex, fileName: x.fileName, payload: x.payload, draft: this.applicationInDraft }))

                this.onDocumentsSaved.emit(this.model);
            }
        })

        this.sub = this.uploadFileFailed$.subscribe(x => {
            if (x) {
                this.dispatch(this.store, new SetValueAction(x.controlId, { ...x.file, submitting: false }))
                this.dispatch(this.store, new SetErrorsAction(x.controlId, { customError: 'There was an error uploading your file, please try again.' }))
                this.dispatch(this.store, ResetFileRequestFailedAction())
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction())
    }

    exit() {
        this.store.dispatch(ResetParentFormStateAction());
        this.store.dispatch(ResetComponentStateAction())
        this.router.navigate(['/start-a-pension'])
    }

    goBack() {
        this.store.dispatch(SetCurrentStepAction({ step: 5 }))
    }


    onVerified(verified: boolean) {
        this.dispatch(this.store, GreenIdVerifiedAction({ verified }));
    }

    async goNextStep() {
        this.dispatch(this.store, SubmitFormAction({ accountId: this.accountId, applicationId: this.applicationId }));
    }

    trackByFn(index, item) {
        return index;
    }

    async uploadDocument(document: any, documentTypeId: number, overviewIndex: number, documentIndex: number, fileName: string, controlId: any) {
        var uploadedDocumentId = this.model[overviewIndex].documentList[documentIndex]?.uploadedDocumentId;

        if (uploadedDocumentId) {
            var docs = await this.helper.getValue(this.supportingDocuments$);
            var originalFile = docs.value[overviewIndex].files[documentIndex];
            this.dispatch(this.store, ReplaceDocumentAction({ accountId: this.accountId, applicationId: this.applicationId, uploadedDocumentId: uploadedDocumentId, overviewIndex: overviewIndex, documentIndex: documentIndex, controlId: controlId, documentTypeId: documentTypeId, fileName: fileName, payload: document, originalFile: originalFile, draft: this.applicationInDraft }))
        }
        else {
            console.log(this.draftApplication)
            this.dispatch(this.store, SaveDocumentAction({ accountId: this.accountId, applicationId: this.applicationId, documentTypeId: documentTypeId, controlId: controlId, documentIndex: documentIndex, fileName: fileName, payload: document, draft: this.applicationInDraft }))
            this.dispatch(this.store, new SetErrorsAction(controlId, {}));
        }
    }

    // controlId is emitted from the component as we dont want to remove the data from within the component
    // until we confirm that if its been uploaded, the delete operation is successful
    removeFile(controlId: any, overviewIndex: number, uploadIndex: number) {
        var uploadedDocumentId = this.model[overviewIndex].documentList[uploadIndex].uploadedDocumentId;

        if (uploadedDocumentId) {
            // send api request to delete file which will then reset the field if successful
            this.dispatch(this.store, DeleteDocumentAction({ accountId: this.accountId, applicationId: this.applicationId, uploadedDocumentId: uploadedDocumentId, overviewIndex: overviewIndex, uploadIndex: uploadIndex, controlId: controlId, draft: this.applicationInDraft }))
        }
        // if the file hasnt been uploaded yet with an id, just reset the field
        else {
            this.store.dispatch(new SetValueAction(controlId, new AppFileModel()));
        }
    }

    checkFileUploaded(files: AppFileModel[]) {
        var fileUploaded = false;

        files.forEach(file => {
            if (file.filename?.length > 0)
                fileUploaded = true;
        })

        return fileUploaded;
    }
}
