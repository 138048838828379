import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StartAPensionService } from 'src/app/services/start-a-pension.service';
import { SetApplicationIdAction } from '../actions';

@Injectable()
export class StartAPensionEligibilityFormComponentEffects {
    constructor(private actions$: Actions,
        private service: StartAPensionService) {
    }

    createBaseApplication$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.SubmitFormAction),
        switchMap((action: any) => this.service.createBaseApplication(action.accountId, action.conditionId, action.submission).pipe(
            map((data: any) => {
                return SetApplicationIdAction({ applicationId: data.data })
            }),
            catchError(_err => {
                return of(ActionsList.SubmitFormErrorAction());
            })
        )),
    ))
}
