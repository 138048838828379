import { Component, OnDestroy, OnInit, } from "@angular/core";
import { ComponentBase } from "src/app/views/component-base";
import { AppState } from "src/app/store/app.states";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { startAPensionUpdateSubmitted_ApplicationData } from "./selectors";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { memberAccountDropdown_SelectedAccount } from "../../member-account-dropdown/selectors";
import { ResetComponentStateAction } from "./actions";
import { ResetParentFormStateAction } from "../start-a-pension-form/actions";
import { commonState_IsSending } from "src/app/store/common/common.selectors";

@Component({
    selector: 'app-start-a-pension-update-submitted',
    templateUrl: './start-a-pension-update-submitted.component.html',
    styleUrls: ['./start-a-pension-update-submitted.component.scss']
})

export class StartAPensionUpdateSubmittedComponent extends ComponentBase implements OnInit, OnDestroy {

    applicationData$ = this.store.pipe(select(startAPensionUpdateSubmitted_ApplicationData));
    selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
    isSubmitting$ = this.store.pipe(select(commonState_IsSending));

    applicationUpdated: boolean = false;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();

        this.sub = combineLatest([this.applicationData$])
            .pipe(
                map(([applicationData]) => ({ applicationData }))
            ).subscribe(x => {

                if (!x.applicationData) {
                    this.router.navigate(['/start-a-pension']);
                }
            })
    }

    ngOnDestroy() {
        this.dispatch(this.store, ResetParentFormStateAction());
        this.dispatch(this.store, ResetComponentStateAction());
        super.ngOnDestroyBase();
    }

    leavePage() {
        this.dispatch(this.store, ResetParentFormStateAction())
        this.router.navigate(['/start-a-pension'])
    }

    submit() {
        this.applicationUpdated = true;
    }
}
