<div *ngIf="model">
    <h2 class="mb-3">Employment and final contribution</h2>
    <div *ngIf="form$|async; let form">
        <form [ngrxFormState]="form" class="ml-3 mr-3">
            <div class="question d-flex flex-row description mt-4">
                <div class="pr-5" [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                    <div class="title mb-1">Last employer name:</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry title="Employer name" [control]="form.controls.lastEmployerName"></app-entry>
                    </div>
                </div>
            </div>
            <div class="question d-flex flex-row description mt-2 pl-2">
                <div>
                    <div class="title">Are you still working with this employer?</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio list="yesNo" [control]="form.controls.stillWithEmployer"></app-entry-radio>
                    </div>
                </div>
            </div>
            <div *ngIf="form.value.stillWithEmployer === false"
                class="question d-flex flex-row description mt-4 pr-5 pl-2">
                <div>
                    <div class="title">What date did you finish with this employer?</div>
                    <div class="custom-control answer">
                        <app-entry-date [maxdate]="maxDate" [date]="form.controls.finishDate"></app-entry-date>
                    </div>
                </div>
            </div>
            <div *ngIf="form.value.stillWithEmployer === false" class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">Are you waiting for your final employer
                        contribution?
                        <mat-icon class="ml-2 tooltip-readonly" [matTooltip]="finalContTooltip">help_outline</mat-icon>
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio list="yesNo"
                            [control]="form.controls.waitingOnFinalContribution"></app-entry-radio>
                    </div>
                </div>
            </div>
        </form>
        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                    [isDisabled]="isSubmitting" color="{{'BTN.access-your-super.cancel.color' | translate}}"
                    (onClick)="exitForm()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button *ngIf="showBackButton" [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.access-your-super.back.class' | translate}}" title="Back"
                    icon="{{'BTN.access-your-super.back.icon' | translate}}"
                    color="{{'BTN.access-your-super.back.color' | translate}}" [isDisabled]="isSubmitting"
                    (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                    color="{{'BTN.access-your-super.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || isSubmitting" loading (onClick)="goNextStep()"></app-button>
            </div>
        </div>
    </div>
</div>