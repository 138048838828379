import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../../store/shared.reducers';
import { MemberPortalSharedState } from '../../../store/shared.states';
import { IStartAPensionStartComponentState } from './state';
import { PensionApplicationConditionEnum } from 'src/app/enums/pension-application-condition.enum';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionStartState = createSelector(memberPortalState, (state) => state.startAPensionStartComponentState);

export const startAPensionStart_Model = createSelector(
    getStartAPensionStartState,
    (state: IStartAPensionStartComponentState) => state.model
);

export const startAPensionStart_Filter = createSelector(
    getStartAPensionStartState,
    (state: IStartAPensionStartComponentState) => state.filter
);

export const startAPensionStart_ProcessStarted = createSelector(
    getStartAPensionStartState,
    (state: IStartAPensionStartComponentState) => state.processStarted
);


export const startAPensionStart_UpdatingSubmitted = createSelector(
    getStartAPensionStartState,
    (state: IStartAPensionStartComponentState) => state.updatingSubmitted
);

export const startAPensionStart_CanCreateNewApp = createSelector(
    getStartAPensionStartState,
    (state: IStartAPensionStartComponentState) => {
        var ttrDraftExists = state.model.draftApplications
            .find(x => x.conditionId == PensionApplicationConditionEnum.Ttr);
        var rpDraftExists = state.model.draftApplications
            .find(x => x.conditionId != PensionApplicationConditionEnum.Ttr);

        return !(ttrDraftExists && rpDraftExists);
    }
);
