import { Action, createReducer, on } from "@ngrx/store";
import { onNgrxForms, onNgrxFormsAction, SetValueAction } from "ngrx-forms";
import { SaveApplicationDataForUpdateSubmittedAction } from "./actions";
import { IStartAPensionUpdateSubmittedComponentState } from "./state";

export const state: IStartAPensionUpdateSubmittedComponentState = {
    applicationData: null,
}

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        return state;
    }),
    on(SaveApplicationDataForUpdateSubmittedAction, (state, { model }) => {
        return {
            ...state,
            applicationData: model,
        }
    })
);

export function StartAPensionUpdateSubmittedComponentReducer(state: any | undefined, action: Action) {
    return reducer(state, action);
}
