import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { InsuranceChangeRequestHistoryModel, } from 'src/app/model/insurance-details.model';

export const ResetStateAction = createAction('[Insurance update history] reset state');
export const RequestAction = createAction('[Insurance update history] get insurance change history', props<{ accountId: number, page: number }>())
export const ResponseAction = createAction('[Insurance update history] set insurance change history', props<{ insuranceHistory: InsuranceChangeRequestHistoryModel[] }>());
export const RequestErrorAction = createAction('[Insurance update history] error');

export const IncrementPageAction = createAction('[Insurance update history] history increment page');
