import { AddressModel, KeyValueModel } from "@ifaa-components/ui-components";

export class StartAPensionInitialFormModel {
    formWording: { [key: string]: string };
    pensionTypeKeyValues: KeyValueModel[];
    conditionOfReleaseKeyValues: KeyValueModel[];
    hasReachedPreservationAge: boolean;
    memberSixtyFiveOrOver: boolean;
    errorMessage: string
}

export class PensionApplicationEligibilitySubmissionModel {
    otherReasonText: string;
}

export class PensionInvestmentOptionModel {
    investmentOptionId: number;
    investmentOptionName: string;
    percentage: number = 0;
}

export class PensionApplicationBaseDetailsModel {
    pensionApplicationId: number;
    pensionApplicationConditionId: number;
    accountId: number;
    accountBalance: number;
    currentStep: number;
    isTtrApplication: boolean;
}

export class PensionApplicationAccountDetailsModel {
    transferTypeId: number;
    transferAmount?: number;
    currentOptions: PensionInvestmentOptionModel[];
}

export class PensionApplicationAccountDetailsFormModel {
    customWording: { [key: string]: string };
    accumulationAccountInfo: string;
    transferAmountTypes: KeyValueModel[];
    savedData?: PensionApplicationAccountDetailsModel;
}

export class PensionApplicationAccountDetailsSubmissionModel {
    transferType: number;
    transferAmount?: number;
    currentOptions: PensionInvestmentOptionModel[];
}

export class PensionApplicationPaymentDetailsModel {
    frequencyId: number;
    paymentCommencementDate?: string;
    pensionPaymentTypeId: number;
    paymentAmount?: number;
    futureOptions?: PensionInvestmentOptionModel[];
}

export class PensionApplicationPaymentDetailsFormModel {
    customWording: { [key: string]: string };
    paymentFrequencyTypes: KeyValueModel[];
    paymentTypeTypes: KeyValueModel[];
    savedData?: PensionApplicationPaymentDetailsModel;
}

export class PensionApplicationPaymentDetailsSubmissionModel {
    frequencyId: number;
    commencementOfPayments?: string;
    paymentTypeId: number;
    paymentAmount?: number;
    futureOptions: PensionInvestmentOptionModel[];
}

export class PensionApplicationBankDetailsFormModel {
    customWording: { [key: string]: string };
    financialInstitutionName?: string;
    bankAccountBsb?: string;
    bankAccountName?: string;
    bankAccountNumber?: string;
}

export class PensionApplicationBankDetailsSubmissionModel {
    bankAccountBsb?: string;
    bankAccountName?: string;
    bankAccountNumber?: string;
    financialInstituteName?: string;
}

export class PensionApplicationBeneficiaryModel {
    beneficiaryName: string;
    beneficiaryDob: string;
    beneficiaryRelationshipId: number;
    genderId: number;
    beneficiaryAddress: AddressModel;
    isAddressSame: boolean;
}

export class PensionApplicationBeneficiaryFormModel {
    customWording: { [key: string]: string };
    relationshipTypes: KeyValueModel[];
    genderTypes: KeyValueModel[];
    memberPostalAddress: AddressModel;
    savedData?: PensionApplicationBeneficiaryModel;
}

export class PensionApplicationBeneficiarySubmissionModel {
    beneficiaryAdded: boolean;
    name: string;
    dob: string;
    relationshipId: number;
    genderId: number;
    isAddressSame: boolean;
    address?: AddressModel;
}

export class PensionApplicationSupportingDocumentModel {
    uploadedDocumentId: number;
    applicationId?: number;
    documentTypeId?: number;
    originalFileName?: string;
    systemFileName?: string;
    fileLocation?: string;
    fileCreatedDate?: Date;
}

export class PensionApplicationDocumentModel {
    documentTypeId: number;
    documentName: string;
    documentDescription: string;
    documentAmountRequired: number;
    isDocumentRequired: boolean;
    documentsUploaded: boolean;
    documentList: PensionApplicationSupportingDocumentModel[];
}

export class PensionApplicationDocumentsFormModel {
    customWording: { [key: string]: string };
    documents: PensionApplicationDocumentModel[];
}

export class PensionApplicationDocumentSubmissionModel {
    documents: PensionApplicationDocumentModel[];
}

export class PensionApplicationReviewFormModel {
    customWording: { [key: string]: string };
}

export class StartAPensionFormModel {
    pensionInvestmentOptions: PensionInvestmentOptionModel[];
    pensionApplicationBaseDetails: PensionApplicationBaseDetailsModel;
    pensionApplicationAccountDetails: PensionApplicationAccountDetailsFormModel;
    pensionApplicationPaymentDetails: PensionApplicationPaymentDetailsFormModel;
    pensionApplicationBankDetails: PensionApplicationBankDetailsFormModel;
    pensionApplicationBeneficiaryDetails: PensionApplicationBeneficiaryFormModel;
    pensionApplicationDocumentsDetails: PensionApplicationDocumentsFormModel;
    pensionApplicationReviewDetails: PensionApplicationReviewFormModel;
}