import { FormGroupState } from "ngrx-forms";
import { PensionApplicationBankDetailsFormModel } from "src/app/model/start-a-pension-form.model";

export interface IStartAPensionBankDetailsFormComponentState {
    formData: PensionApplicationBankDetailsFormModel;
    form: FormGroupState<StartAPensionBankDetailsForm>;
}

export class StartAPensionBankDetailsForm {
    financialInstitutionName: string = "";
    bankAccountName: string = "";
    bankAccountNumber: string = "";
    bankAccountBsb: string = "";
}