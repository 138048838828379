import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IStartAPensionUpdateSubmittedComponentState } from "./state";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { featureKey } from "../../../store/shared.reducers";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionUpdateSubmittedState = createSelector(memberPortalState, (state) => state.startAPensionUpdateSubmittedComponentState);

export const startAPensionUpdateSubmitted_ApplicationData = createSelector(
    getStartAPensionUpdateSubmittedState,
    (state: IStartAPensionUpdateSubmittedComponentState) => state.applicationData
);