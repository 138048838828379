import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { ResetComponentStateAction, SetPaymentDetailsFormDataAction, SubmitFormAction } from './actions';
import { PensionApplicationPaymentDetailsFormModel, PensionInvestmentOptionModel } from 'src/app/model/start-a-pension-form.model';
import { Helper } from '@ifaa-components/ui-components';
import { startAPensionPaymentDetailsForm_FutureTotalPercent, startAPensionPaymentDetailsForm_Form, startAPensionEligibilityForm_FrequencyTypes, startAPensionEligibilityForm_PaymentTypes, startAPensionEligibilityForm_CommencementOfDateTypes } from './selectors';
import { ResetParentFormStateAction, SetCurrentStepAction } from '../actions';
import { StartAPensionPaymentDetailsForm } from './state';
import { SetErrorsAction } from 'ngrx-forms';

@Component({
    selector: 'app-start-a-pension-payment-details',
    templateUrl: './start-a-pension-payment-details-form.component.html',
    styleUrls: ['./start-a-pension-payment-details-form.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class StartAPensionPaymentDetailsFormComponent extends ComponentBase implements OnInit, OnDestroy {
    @Input() set data(value: PensionApplicationPaymentDetailsFormModel) {
        this.formData = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set application(value: number) {
        this.applicationId = value;
    }
    @Input() set options(value: PensionInvestmentOptionModel[]) {
        this.investmentOptions = value;
    }
    @Input() set ttr(value: boolean) {
        this.isTtr = value;
    }
    @Input() set balance(value: number) {
        this.accountBalance = value;
    }
    @Input() set submitting(value: boolean) {
        this.isSubmitting = value;
    }

    form$ = this.store.pipe(select(startAPensionPaymentDetailsForm_Form));
    frequencyTypes$ = this.store.pipe(select(startAPensionEligibilityForm_FrequencyTypes));
    commencementTypes$ = this.store.pipe(select(startAPensionEligibilityForm_CommencementOfDateTypes));
    paymentTypes$ = this.store.pipe(select(startAPensionEligibilityForm_PaymentTypes));
    futureTotalPercent$ = this.store.pipe(select(startAPensionPaymentDetailsForm_FutureTotalPercent));

    formData: PensionApplicationPaymentDetailsFormModel = null;
    accountId: number = null;
    accountBalance: number = 0;
    applicationId: number = null;
    investmentOptions: PensionInvestmentOptionModel[] = [];
    isTtr: boolean = false;
    helper = new Helper();
    isSubmitting: boolean = false;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
        this.store.dispatch(SetPaymentDetailsFormDataAction({ data: this.formData, investmentOptions: this.investmentOptions, isTtr: this.isTtr, accountBalance: this.accountBalance }));

        this.sub = this.form$.subscribe(x => {
            if (x) {
                x.controls?.options?.controls?.forEach(option => {
                    if (option.value.percentage !== 0 && option.value.percentage < 5) {
                        const errors = {};
                        errors['customError'] = 'Percentage must be at least 5%.';
                        this.store.dispatch(new SetErrorsAction(option.controls.percentage.id, errors))
                    }
                    else {
                        this.store.dispatch(new SetErrorsAction(option.controls.percentage.id, {}))
                    }
                })
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.store.dispatch(ResetComponentStateAction())
    }

    exit() {
        this.store.dispatch(ResetParentFormStateAction());
        this.store.dispatch(ResetComponentStateAction())
        this.router.navigate(['/start-a-pension'])
    }

    goBack() {
        this.store.dispatch(SetCurrentStepAction({ step: 2 }))
    }

    async savePaymentDetails(submissionData: StartAPensionPaymentDetailsForm) {
        this.store.dispatch(SubmitFormAction({
            accountId: this.accountId, applicationId: this.applicationId, submission: {
                frequencyId: submissionData.frequencyType,
                paymentTypeId: submissionData.paymentType,
                paymentAmount: submissionData.paymentType === 1 ? submissionData.paymentAmount : null,
                commencementOfPayments: submissionData.commencementOfDateOption === 2 ? submissionData.commencementOfDate : null,
                futureOptions: submissionData.options.filter(x => x.percentage !== 0)
            }
        }))
    }

    isValidDate(dateString: string) {
        const date = new Date(dateString);
        return date instanceof Date && !isNaN(date.getTime());
    }

    isValidDatePeriod(dateString: string) {
        const currentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const selectedMonth = new Date(dateString);
        const selectedMonthFormatted = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
        return selectedMonthFormatted > currentMonth
    }
}
