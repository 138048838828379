<div id="member-view-container">

  <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
    <!-- <h5 class="title">Communication Preferences</h5> -->
    <nav *featuretoggle="editCommunicationPreferenceFeatureToggle"
      class="navbar navbar-expand-lg navbar-light bg-light mb-1">
      <div class="navbar-brand d-flex flex-fill align-items-center">
        <mat-icon class="mr-1" *ngIf="('VIEWS.Global.displayEntryIcons' | translate)">support_agent</mat-icon>
        Communication Preferences
      </div>
      <app-button-toggle [control]="form.controls.communicationPreference" title=""
        list="communicationPreferenceList"></app-button-toggle>

    </nav>
    <!-- <div class="d-flex flex-row" *featuretoggle="editCommunicationPreferenceFeatureToggle">
      <div>
        <h5 class="title">Communication Preferences</h5>
        <div>
          <app-entry-radio title="Communication Preferences" list="communicationPreferenceList"
            [control]="form.controls.communicationPreference" direction="row"></app-entry-radio>
        </div>
      </div>
    </div> -->
    <app-entry title="Email" [control]="form.controls.email" icon="email" entrytype="email"
      *featuretoggle="editEmailFeatureToggle"
      tooltip="This email address is your registered email address for the Member portal and is linked to your identity. Updating this will update your login email address.">
    </app-entry>

    <app-entry title="Chosen Name" [control]="form.controls.preferredName" [allowPunctuation]="false"
      tooltip="This is what we will call you but there may be some instances where we will need to use your legal name">
    </app-entry>


    <app-entry-date title="{{ 'DATE_OF_BIRTH' | translate }}" [date]="form.controls.dateOfBirth"
      *featuretoggle="editDobFeatureToggle">
    </app-entry-date>

    <app-entry title="{{ 'MOBILE' | translate }}" [control]="form.controls.mobile"
      [icon]="('VIEWS.Global.displayEntryIcons' | translate)?'phone':''">
    </app-entry>

    <app-entry title="Home Phone" [control]="form.controls.homePhone"
      [icon]="('VIEWS.Global.displayEntryIcons' | translate)?'phone':''">
    </app-entry>

    <div *featuretoggle="editTfnFeatureToggle">
      <app-entry title="Tax File Number" [control]="form.controls.taxFileNumber"
        [icon]="('VIEWS.Global.displayEntryIcons' | translate)?'pin':''" *ngIf="!form.value.hasTFN">
      </app-entry>
    </div>

    <app-entry title="Occupation" [control]="form.controls.occupation"
      [icon]="('VIEWS.Global.displayEntryIcons' | translate)?'work':''" *featuretoggle="editOccupationFeatureToggle">
    </app-entry>

    <app-entry-address-accurity title="{{ 'POSTAL_ADDRESS' | translate }}" [address]="form.controls.postalAddress"
      [isSearchMode]="false">
    </app-entry-address-accurity>

    <entry-slide-toggle title="Residential same as postal address?" [control]="form.controls.isPostalSameAsResidential"
      iconTrue="" iconFalse="">
    </entry-slide-toggle>

    <app-entry-address-accurity title="{{ 'RESIDENTIAL_ADDRESS' | translate }}"
      [address]="form.controls.residentialAddress" [isSearchMode]="false" *ngIf="!form.value.isPostalSameAsResidential">
    </app-entry-address-accurity>

    <div *featuretoggle="editTfnFeatureToggle">
      <div *ngIf="!form.value.hasTFN && form.value.taxFileNumber">
        <p>
          {{(tenantConfig$|async).name}} is authorised to ask for your tax file number (TFN) in line with the
          Superannuation Industry (Supervision) Act 1993. {{(tenantConfig$|async).name}} will only use your TFN for
          lawful
          purposes. These purposes may change in the future if there are changes to the legislation.
        </p>
        <p>
          {{(tenantConfig$|async).name}} may pass your TFN to any other super fund or account to which your super is
          transferred in the future unless you request in writing that this not be done.
        </p>
        <p>
          By providing your TFN :
        </p>
        <ul>
          <li>
            {{(tenantConfig$|async).name}} will use the ATO Member Account Attribute service (MAAS) to verify your TFN,
            name and date of birth.
          </li>
          <li>
            {{(tenantConfig$|async).name}} will be able to accept all types of contributions made by or for you (some
            limits may apply).
          </li>
          <li>
            You can avoid paying tax at a higher rate than would otherwise apply on your super benefit.
          </li>
          <li>
            It will be easier for you to find your super in the future and ensure that you receive all of your super
            benefits when you retire.
          </li>
        </ul>

        <app-checkbox [control]="form.controls.tfnTermsAndCondition" class="w-100  mr-1 ml-1"
          title="Yes, I acknowledge the above">
        </app-checkbox>
      </div>
    </div>

    <div class="components-actions">
      <app-button *featuretoggle="updateProfileFeatureToggle" loading class=" mr-1 ml-1" title="Save details"
        icon="{{'BTN.member-edit.save.icon' | translate}}" type="submit"
        [isDisabled]="form.isInvalid || (isSubmitting$|async)" loading (onClick)="onSubmitClick(form.value)">
      </app-button>
    </div>
  </form>
</div>