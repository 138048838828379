<div id="dashboard-container">
    <div class="page-header">
        <app-toolbar-options icon="verified_user">
            <div class="d-flex flex-column" slot="start">
                <div class="page-title">{{'Update Insurance' | textTransform}}</div>

            </div>
        </app-toolbar-options>
    </div>

    <div class="page-content" appanimatecss>
        <app-component-wrapper icon="admin_panel_settings" header="Change your cover">
            <div class="d-flex flex-column" slot="start">
                <app-insurance-update></app-insurance-update>
            </div>
        </app-component-wrapper>
    </div>
</div>