<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="verified_user">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">{{'Start A Pension' | textTransform}}</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper>
      <div class="d-flex flex-column" slot="start">
        <app-start-a-pension-update-submitted></app-start-a-pension-update-submitted>
      </div>
    </app-component-wrapper>



  </div>
</div>