import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { ResetComponentStateAction, SetAccountDetailsFormDataAction, SubmitFormAction } from './actions';
import { PensionApplicationAccountDetailsFormModel, PensionInvestmentOptionModel } from 'src/app/model/start-a-pension-form.model';
import { Helper } from '@ifaa-components/ui-components';
import { startAPensionAccountDetailsForm_CurrentTotalPercent, startAPensionAccountDetailsForm_Form, startAPensionEligibilityForm_TransferAmounts } from './selectors';
import { ResetParentFormStateAction, SetCurrentStepAction } from '../actions';
import { StartAPensionAccountDetailsForm } from './state';
import { SetErrorsAction } from 'ngrx-forms';

@Component({
    selector: 'app-start-a-pension-account-details',
    templateUrl: './start-a-pension-account-details-form.component.html',
    styleUrls: ['./start-a-pension-account-details-form.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class StartAPensionAccountDetailsFormComponent extends ComponentBase implements OnInit, OnDestroy {
    @Input() set data(value: PensionApplicationAccountDetailsFormModel) {
        this.formData = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set application(value: number) {
        this.applicationId = value;
    }
    @Input() set options(value: PensionInvestmentOptionModel[]) {
        this.investmentOptions = value;
    }
    @Input() set balance(value: number) {
        this.accountBalance = value;
    }
    @Input() set submitting(value: boolean) {
        this.isSubmitting = value;
    }

    form$ = this.store.pipe(select(startAPensionAccountDetailsForm_Form));
    transferTypes$ = this.store.pipe(select(startAPensionEligibilityForm_TransferAmounts));
    currentTotalPercent$ = this.store.pipe(select(startAPensionAccountDetailsForm_CurrentTotalPercent));

    formData: PensionApplicationAccountDetailsFormModel = null;
    accountId: number = null;
    accountBalance: number = 0;
    applicationId: number = null;
    investmentOptions: PensionInvestmentOptionModel[] = [];
    helper = new Helper();
    isSubmitting: boolean = false;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
        this.store.dispatch(SetAccountDetailsFormDataAction({ data: this.formData, investmentOptions: this.investmentOptions, accountBalance: this.accountBalance }))

        this.sub = this.form$.subscribe(x => {
            if (x) {
                x.controls?.options?.controls?.forEach(option => {
                    if (option.value.percentage !== 0 && option.value.percentage < 5) {
                        const errors = {};
                        errors['customError'] = 'Percentage must be at least 5%.';
                        this.store.dispatch(new SetErrorsAction(option.controls.percentage.id, errors))
                    }
                    else {
                        this.store.dispatch(new SetErrorsAction(option.controls.percentage.id, {}))
                    }
                })
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.store.dispatch(ResetComponentStateAction())
    }

    exit() {
        this.store.dispatch(ResetParentFormStateAction());
        this.store.dispatch(ResetComponentStateAction())
        this.router.navigate(['/start-a-pension'])
    }

    goBack() {
        this.store.dispatch(SetCurrentStepAction({ step: 1 }))
    }

    async saveAccountDetails(submissionData: StartAPensionAccountDetailsForm) {
        this.store.dispatch(SubmitFormAction({
            accountId: this.accountId, applicationId: this.applicationId, submission: {
                transferType: submissionData.transferType,
                transferAmount: submissionData.transferType === 3 ? submissionData.partialAmount : null,
                currentOptions: submissionData.options.filter(x => x.percentage !== 0)
            }
        }))
    }
}
