<div id="main-layout-container" class="d-flex flex-column min-h-100 verv-theme" screen-width>

  <mat-drawer-container class="flex-fill d-flex" autosize>
    <mat-drawer #drawer class="sidenav" [mode]="menuMode" [opened]="menuOpened" [openedChange]="openedChange()">
      <nav class="navbar-primary" (swipeleft)="onSwipeToggle($event)">
        <img src="{{'fundLogo' | tenantconfig | async }}" routerLink="/dashboard" class="logo">
        <div class="welcome">
          <div class="welcome-text">Welcome,</div>
          <h1>{{(user$ | async)?.firstName}}</h1>
        </div>

        <ul class="navbar-primary-menu">
          <li>

            <div *ngFor="let item of menuList$ | async">

              <!-- [attr.data-toggle]="item.link?.startsWith('http')? null:'collapse'" -->
              <!-- [attr.routerLink]="item.link?.startsWith('http')? null:item.link"
              [attr.href]="item.link?.startsWith('http')? item.link:null" -->
              <a class="btn menu-item d-flex align-items-center" [ngClass]="{'d-none': (item.featureFlag | featureToggle | async) === featureToggleValues?.hidden,
              'disabled': (item.featureFlag | featureToggle | async) === featureToggleValues?.disabled}"
                [class.active]="isMenuExpanded(item)" [attr.target]="item.target ? item.target : null"
                (click)="onItemSelectedClick(item)" data-toggle="collapse" aria-expanded="false">
                <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon><span class="nav-label">{{item.name}}</span>
                <mat-icon *ngIf="item.children.length > 0" class="item-arrow">expand_more</mat-icon>
              </a>
              <div class="collapse sub-menu" [class.show]="isMenuExpanded(item)" id="sub-menu-{{item.id}}"
                *ngIf="item.children.length > 0">
                <ul class="" style="background-color: #fafafa;">
                  <li *ngFor="let child of item.children">

                    <ng-container *ngIf="child.featureFlag | featureToggle | async; let childFlagValue;">
                      <a *ngIf="child.link?.startsWith('/') && childFlagValue !== featureToggleValues?.hidden"
                        [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                        class="btn menu-item sub-menu-item d-flex align-items-center" [routerLink]="child.link"
                        [fragment]="child.fragment">
                        <mat-icon *ngIf="child.icon">{{child.icon}}</mat-icon>
                        <div class="nav-label">{{child.name}}</div>
                      </a>
                      <a *ngIf="child.link?.startsWith('http') && childFlagValue !== featureToggleValues?.hidden"
                        [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                        class="btn menu-item sub-menu-item d-flex align-items-center" [href]="child.link"
                        [attr.target]="child.target ? child.target : null">
                        <mat-icon *ngIf="child.icon">{{child.icon}}</mat-icon>
                        <div class="nav-label">{{child.name}}</div>
                      </a>
                    </ng-container>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </nav>

    </mat-drawer>
    <mat-drawer-content class="d-flex flex-fill full overflow-x-hidden flex-column main">

      <div class="w-100 h-100 overlay" *ngIf="isFetching$|async">
      </div>

      <nav class="top navbar navbar-expand-lg">
        <div class="navbar-brand logo flex-fill">
          <button class="btn btn-link toggle-menu no-text-decoration" (click)="drawer.toggle()">
            <mat-icon>
              menu
            </mat-icon>
          </button>


        </div>

        <div class="form-inline float-right">
          <!-- <app-notification-dropdown></app-notification-dropdown> -->
          <app-member-account-dropdown></app-member-account-dropdown>
          <app-user-top-dropdown></app-user-top-dropdown>
        </div>

      </nav>

      <div class="main-content flex-fill d-flex flex-column w-100">
        <app-scroll-up></app-scroll-up>

        <div class="navbar-global-wrapper">
          <nav class="navbar navbar-inverse navbar-global navbar-fixed-top">
            <div class="container">
              <div class="navbar-header d-flex flex-row w-100">


              </div>

            </div>
          </nav>
        </div>

        <main class="container d-flex flex-fill" (swiperight)="onSwipeToggle($event)">
          <!-- <app-scroll-up></app-scroll-up> -->

          <router-outlet name="master"></router-outlet>

          <div class="spinner-container" *ngIf="isFetching$ | async">
            <mat-progress-spinner class="app-spinner animate__animated animate__bounceIn"
              mode="indeterminate"></mat-progress-spinner>
          </div>

        </main>
        <app-footer></app-footer>
        <!-- <nav class="navbar-secondary toolbar-bottom d-flex align-items-center flex-row" *ngIf="screenWidth<992"
          appanimatecss="animate__fadeInUp animate__delay-1s">
          <div class="d-flex flex-fill option flex-column align-items-center" (click)="drawer.toggle()">
            <mat-icon>menu_open</mat-icon>
            <label>Menu</label>
          </div>
          <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/">
            <mat-icon>house</mat-icon>
            <label>Overview</label>
          </div>
          <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/personal-details">
            <mat-icon>person</mat-icon>
            <label>Profile</label>
          </div>
        </nav> -->
      </div>

    </mat-drawer-content>
  </mat-drawer-container>

</div>