import { Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { ApplicationDataRequestAction, GetInitialFormDataRequestAction, IncrementCurrentStepWithApiRequestAction, ResetParentFormStateAction, SetApplicationDocumentsAction, SetCurrentStepAction } from './actions';
import { startAPensionForm_ApplicationData, startAPensionForm_ApplicationId, startAPensionForm_CurrentStep, startAPensionForm_InitialFormData } from './selectors';
import { memberAccountDropdown_SelectedAccount } from '../../member-account-dropdown/selectors';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { commonState_IsSending, commonState_MemberData } from 'src/app/store/common/common.selectors';
import { TranslateService } from '@ngx-translate/core';
import { StartAPensionStepper } from './start-a-pension-progress-stepper/start-a-pension-progress-stepper.component';
import { Helper } from '@ifaa-components/ui-components';
import { PensionApplicationDocumentModel, PensionApplicationSupportingDocumentModel } from 'src/app/model/start-a-pension-form.model';

@Component({
    selector: 'app-start-a-pension-form',
    templateUrl: './start-a-pension-form.component.html',
    styleUrls: ['./start-a-pension-form.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class StartAPensionFormComponent extends ComponentBase implements OnInit, OnDestroy {


    currentStep$ = this.store.pipe(select(startAPensionForm_CurrentStep));
    applicationData$ = this.store.pipe(select(startAPensionForm_ApplicationData));
    applicationId$ = this.store.pipe(select(startAPensionForm_ApplicationId));
    initialFormData$ = this.store.pipe(select(startAPensionForm_InitialFormData));
    accountDropdown$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
    memberViewModel$ = this.store.pipe(select(commonState_MemberData));

    isSubmitting$ = this.store.pipe(select(commonState_IsSending));

    accountId: number;
    applicationId: number;
    currentStep: number = null;
    validationErrorList: string[] = [];
    stepperData: any[] = [];
    showStepper: boolean = true;
    helper: Helper = new Helper();
    submitting: boolean = false;

    @ViewChild(StartAPensionStepper) private myStepper: StartAPensionStepper;

    constructor(
        public store: Store<AppState>,
        public translate: TranslateService,
        private router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();

        this.sub = this.isSubmitting$.subscribe(x => {
            this.submitting = x;
        })

        this.sub = combineLatest([this.accountDropdown$, this.currentStep$]).pipe(distinctUntilChanged((x, y) => {
            return x[0].accountId === y[0].accountId && x[1] === y[1]
        })).subscribe(x => {
            if (x[0].accountId) {
                this.accountId = x[0].accountId;

                // if the current step is not set, redirect back to start a pension landing page
                if (x[1] === null) {
                    this.router.navigate(['/start-a-pension'])
                }
                else {
                    this.currentStep = x[1];

                    // if current step is -1 we need to get the initial form data + disclaimer
                    if (x[1] === -1) {
                        this.store.dispatch(GetInitialFormDataRequestAction({ accountId: this.accountId }))
                    }
                }
            }
        })

        this.sub = combineLatest([this.applicationId$, this.applicationData$])
            .pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)))
            .subscribe(x => {

                // set applicationid
                if (!this.applicationId) {
                    this.applicationId = x[0];
                }

                // get application data if we have application id and no application data
                if (x[0] && !x[1]) {
                    this.store.dispatch(ApplicationDataRequestAction({ accountId: this.accountId, applicationId: this.applicationId }))
                }

                // set stepper data
                if (x[0] && x[1]) {
                    if (this.stepperData.length === 0) {
                        this.stepperData.push({
                            title: 'Personal details',
                            completed: x[1].pensionApplicationBaseDetails.currentStep > 1,
                            stepIndex: 1
                        })
                        this.stepperData.push({
                            title: 'Account details',
                            completed: x[1].pensionApplicationBaseDetails.currentStep > 2,
                            stepIndex: 2
                        })
                        this.stepperData.push({
                            title: 'Payment details',
                            completed: x[1].pensionApplicationBaseDetails.currentStep > 3,
                            stepIndex: 3
                        })
                        this.stepperData.push({
                            title: 'Bank details',
                            completed: x[1].pensionApplicationBaseDetails.currentStep > 4,
                            stepIndex: 4
                        })
                        this.stepperData.push({
                            title: 'Beneficiary',
                            completed: x[1].pensionApplicationBaseDetails.currentStep > 5,
                            stepIndex: 5
                        })
                        this.stepperData.push({
                            title: 'Documents',
                            completed: x[1].pensionApplicationBaseDetails.currentStep > 6,
                            stepIndex: 6
                        })
                        this.stepperData.push({
                            title: 'Review',
                            completed: x[1].pensionApplicationBaseDetails.currentStep > 7,
                            stepIndex: 7
                        })
                    }
                }
            })

        // updates the stepper 
        this.sub = this.currentStep$.subscribe(async x => {
            this.myStepper?.setSelectedIndex(x);
        })

        // validations for personal details page
        this.sub = this.memberViewModel$.subscribe(async x => {
            if (x) {
                this.validationErrorList = [];
                if (!x.residentialAddress || !x.residentialAddress.fullAddress)
                    this.validationErrorList.push("Please provide your Residential Address by clicking on the 'Update Details' button");
                var count = 0;
                var fieldsMissing = [];
                if (!x.firstName) {
                    count++;
                    fieldsMissing.push('First name');
                }
                if (!x.lastName) {
                    count++;
                    fieldsMissing.push('Last name');
                }
                if (!x.dateOfBirth) {
                    count++;
                    fieldsMissing.push('Date of birth');
                }
                if (count > 0) {
                    this.sub = await this.translate.get('PLEASE_CONTACT_US_MESSAGE').subscribe(x => {
                        var msg = fieldsMissing.join(', ');
                        msg += count > 1 ? ' are required. ' : ' is required. ';
                        msg += x.toString();
                        this.validationErrorList.push(msg);

                    });
                }
            }
        })
    }

    ngOnDestroy() {
        this.dispatch(this.store, ResetParentFormStateAction())
        super.ngOnDestroyBase();
    }

    exit() {
        this.dispatch(this.store, ResetParentFormStateAction())
        this.router.navigate(['/start-a-pension'])
    }

    back() {
        this.store.dispatch(SetCurrentStepAction({ step: this.currentStep - 1 }))
    }

    nextStep() {
        this.store.dispatch(SetCurrentStepAction({ step: this.currentStep + 1 }))
    }

    async nextStepMemberDetails() {
        var appData = await this.helper.getValue(this.applicationData$)
        if (appData.pensionApplicationBaseDetails.currentStep === 1)
            this.store.dispatch(IncrementCurrentStepWithApiRequestAction({ accountId: this.accountId, applicationId: this.applicationId }))
        else
            this.store.dispatch(SetCurrentStepAction({ step: this.currentStep + 1 }))
    }

    updatePersonalDetails() {
        this.router.navigate(['/personal-edit'])
    }

    onStepperSelectionChange(titles: any[], event: any) {
        this.dispatch(this.store, SetCurrentStepAction({ step: titles[event.selectedIndex].stepIndex }))
    }

    onDocumentsSaved(payload: PensionApplicationDocumentModel[]) {
        this.dispatch(this.store, SetApplicationDocumentsAction({ documents: payload }))
    }

    onSubmissionSuccesful(payload: boolean) {
        if (payload) {
            this.showStepper = false;
        }
    }
}
