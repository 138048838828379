import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { IncrementPageAction, RequestAction, ResetStateAction, } from './actions';
import { Router } from '@angular/router';
import { insuranceUpdateHistory_Filter, insuranceUpdateHistory_IsLoading, insuranceUpdateHistory_Model } from './selectors';
import { distinctUntilChanged } from 'rxjs';
import { InsuranceUpdateHistoryFilter } from './state';

@Component({
    selector: 'app-insurance-update-history',
    templateUrl: './insurance-update-history.component.html',
    styleUrls: ['./insurance-update-history.component.scss']
})
export class InsuranceUpdateHistoryComponent extends ComponentBase implements OnInit, OnDestroy {

    selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
    model$ = this.store.pipe(select(insuranceUpdateHistory_Model));
    filter$ = this.store.pipe(select(insuranceUpdateHistory_Filter));
    isLoading$ = this.store.pipe(select(insuranceUpdateHistory_IsLoading));

    query: InsuranceUpdateHistoryFilter = new InsuranceUpdateHistoryFilter();
    accountId: number = 0;

    constructor(public store: Store<MemberPortalSharedState>,
        public appstore: Store<AppState>,
        public router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();

        this.sub = this.selectedAccount$.pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))).subscribe(x => {
            if (x) {
                this.accountId = x.accountId;
                this.store.dispatch(RequestAction({ accountId: x.accountId, page: 0 }))
            }
        })

        this.sub = this.filter$.subscribe(async x => {
            if (!this.accountId) return;
            if (x) {
                if (this.isQueryDifferent(x.value)) {
                    this.store.dispatch(RequestAction({ accountId: this.accountId, page: x.value.currentPage }));
                }
            }
        });
    }

    isQueryDifferent(filter: InsuranceUpdateHistoryFilter): boolean {
        if (filter.currentPage == -1)
            return false;

        if (JSON.stringify(this.query) !== JSON.stringify(filter)) {
            this.query = filter;
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.store.dispatch(ResetStateAction());
    }

    goBack() {
        this.router.navigate(['/insurance'])
    }

    onLoadMore() {
        this.store.dispatch(IncrementPageAction());
    }
}
