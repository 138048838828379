import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ComponentBase } from "src/app/views/component-base";
import { AppState } from "src/app/store/app.states";
import { InsuranceChangeRequestHistoryModel } from "src/app/model/insurance-details.model";

@Component({
    selector: 'app-insurance-update-history-card',
    templateUrl: './insurance-update-history-card.component.html',
    styleUrls: ['./insurance-update-history-card.component.scss']
})
export class InsuranceUpdateHistoryCardComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set details(value: InsuranceChangeRequestHistoryModel) {
        this.insuranceUpdateDetails = value;
    }

    insuranceUpdateDetails: InsuranceChangeRequestHistoryModel = null;

    constructor(public store: Store<AppState>) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
    }

    getChangeType() {
        if (this.insuranceUpdateDetails?.coverTypes.every(detail => detail.details.every(inner => inner.amount === 0))) {
            return 'Cancel cover';
        }
        return 'Reduce cover';
    }
}
