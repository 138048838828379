import { FormGroupState } from 'ngrx-forms';
import { InsuranceDetailsModel, InsuranceItem } from './../../../../model/insurance-details.model';

export interface IInsuranceUpdateComponentState {
    model: InsuranceDetailsModel;
    initialForm: FormGroupState<InitialInsuranceSelectionForm>;
    reduceForm: FormGroupState<InsuranceUpdateReduceForm>;
    cancelForm: FormGroupState<InsuranceUpdateCancelForm>;
    submitted: boolean;
    insuranceToReduce: InsuranceItem[];
    insuranceToCancel: InsuranceItem[];
}

export class InitialInsuranceSelectionForm {
    insuranceItems: SelectionForm[];
}

export class SelectionForm {
    insuranceId: number = 0;
    amount: number = 0;
    description: string = "";
    checkbox: boolean = false;
}

export class InsuranceUpdateCancelForm {
    checkbox: boolean = false;
    insurance: InsuranceCancelForm[];
}

export class InsuranceCancelForm {
    insuranceId: number = 0;
    description: string = '';
    amount: number = 0;
    code: string = "";
    covers: CoverTypeCancelForm[];
}

export class CoverTypeCancelForm {
    insuranceCoverTypeDetailId: number = 0;
    coverType: string = '';
    checkbox: boolean = true;
}

export class InsuranceUpdateReduceForm {
    checkbox: boolean = false
    insurance: InsuranceReduceForm[];
}

export class InsuranceReduceForm {
    insuranceId: number = 0;
    description: string = '';
    amount: number = 0;
    code: string = "";
    covers: CoverTypeUpdateForm[];
}

export class CoverTypeUpdateForm {
    insuranceCoverTypeDetailId: number = 0;
    coverType: string;
    newAmount: number;
}
