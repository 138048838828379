import { InsuranceService } from './../../../../services/insurance.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable()
export class InsuranceUpdateComponentEffects {
    constructor(private actions$: Actions,
        private service: InsuranceService) {
    }

    reduceInsurance$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.SubmitInsuranceToReduceAction),
        switchMap((action: any) => this.service.reduceInsurance(action.accountId, action.submission).pipe(
            map((data: any) => {
                return ActionsList.SubmissionSuccessAction();
            }),
            catchError(_err => {
                return of(ActionsList.SubmissionErrorAction());
            })
        )),
    ));

    cancelInsurance$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.SubmitInsuranceToCancelAction),
        switchMap((action: any) => this.service.cancelInsurance(action.accountId, action.submission).pipe(
            map((data: any) => {
                return ActionsList.SubmissionSuccessAction();
            }),
            catchError(_err => {
                return of(ActionsList.SubmissionErrorAction());
            })
        )),
    ));
}
