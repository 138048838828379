export class InsuranceDetailsModel {
  hasInsurance: boolean = false;
  insuranceCoverUrl: string = '';
  fundDocuments: InsuranceDetailsFundDocumentModel[] = [];
  insurances: InsuranceItem[] = [];
  premiumStartDate?: Date = null;
  lastModified?: Date = null;
}

export class InsuranceDetailsFundDocumentModel {
  title: string = '';
  icon: string = '';
  url: string = '';
}

export class InsuranceItem {
  insuranceId: number = 0;
  insuranceCoverTypeCode: string = '';
  insuranceCoverTypeName: string = '';
  insuranceCoverTypeDescription: string = '';
  waitingPeriod: string = '';
  lapseDate: Date = null;
  premium: number = 0;
  amount: number = 0;
  insuranceCoverStatusCode: string = '';
  insuranceCoverToolTipText: string = '';
  coverTypeDetails: InsuranceItemCoverTypeDetailsModel[];
}

export class InsuranceItemCoverTypeDetailsModel {
  insuranceCoverTypeDetailId: number = 0;
  name: string = ''
}

export class InsuranceUpdateSubmissionModel {
  insurances: InsuranceCoverUpdateModel[];
}

export class InsuranceCoverUpdateModel {
  insuranceId: number = 0;
  originalAmount: number = 0;
  details: InsuranceCoverTypeDetailsUpdateModel[];
}

export class InsuranceCoverTypeDetailsUpdateModel {
  insuranceCoverTypeDetailId: number = 0;
  newAmount: number = 0;
}

export class InsuranceCoverTypeDetailChangeModel {
  insuranceCoverTypeDetail: string = '';
  amount: number = 0;
}

export class InsuranceCoverTypeChangeModel {
  coverType: string = '';
  details: InsuranceCoverTypeDetailChangeModel[];
}

export class InsuranceChangeRequestHistoryModel {
  coverTypes: InsuranceCoverTypeChangeModel[];
  submittedOn: Date = new Date();
  caseStatus: string = '';
  statusId: number = 0;
}