import { createReducer, on, Action } from '@ngrx/store';
import {
    createFormGroupState,
    onNgrxForms, onNgrxFormsAction, setValue, SetValueAction,
    updateGroup,
    validate,
    wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { IStartAPensionBeneficiaryFormComponentState, StartAPensionBeneficiaryForm } from './state';
import { AddBeneficiaryAction, RemoveBeneficiaryAction, ResetComponentStateAction, SetBeneficiaryFormDataAction, SubmitFormAction, SubmitFormErrorAction } from './actions';
import { AddressModel } from '@ifaa-components/ui-components';
import { required } from 'ngrx-forms/validation';

const formName = 'StartAPensionBeneficiaryForm';

export const state: IStartAPensionBeneficiaryFormComponentState = {
    formData: null,
    form: createFormGroupState(formName, new StartAPensionBeneficiaryForm()),
    relationshipTypes: [],
    genderTypes: []
};

export const validateStartAPensionBeneficiaryForm = updateGroup<StartAPensionBeneficiaryForm>({
    name: (state, parentState) => {
        const validateName = (name: string) => {
            const errors = {};
            if (!parentState.value.beneficiaryAdded)
                return errors;
            if (name.length === 0) {
                errors["customError"] = "Beneficiary name is required.";
            }
            return errors;
        }

        state = validate(state, validateName)
        return state;
    },
    dob: (state, parentState) => {
        const validateDob = (dob: string) => {
            const errors = {};
            if (!parentState.value.beneficiaryAdded)
                return errors;
            if (dob.length === 0) {
                errors["customError"] = "Beneficiary date of birth is required.";
            }
            if (new Date(dob) > new Date()) {
                errors["customError"] = "Beneficiary date of birth cannot be a future date."
            }
            return errors;
        }

        state = validate(state, validateDob)
        return state;
    },
    address: validate(required)
})

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        const actionSplit = action.controlId.split(".");
        const actionFormName = actionSplit[0];
        const controlId = actionSplit[1];

        if (actionFormName !== formName) return state;

        switch (controlId) {
            case "isAddressSame": {
                return {
                    ...state,
                    form: setValue(Object.assign(new StartAPensionBeneficiaryForm(),
                        {
                            ...state.form.value,
                            address: action.value ? state.formData.memberPostalAddress : state.formData.savedData?.beneficiaryAddress ?? {
                                ...state.formData.memberPostalAddress,
                                isRequired: true
                            } as AddressModel,
                        }
                    ))(state.form),
                }
            }
        }
        return state;
    }),
    on(SetBeneficiaryFormDataAction, (state, { data }) => {
        return {
            ...state,
            formData: data,
            form: setValue(Object.assign(new StartAPensionBeneficiaryForm(),
                {
                    ...state.form.value,
                    beneficiaryAdded: data.savedData?.beneficiaryName ? true : false,
                    name: data.savedData?.beneficiaryName ?? "",
                    dob: data.savedData?.beneficiaryDob.split("T")[0] ?? "",
                    relationshipId: data.savedData?.beneficiaryRelationshipId ?? 1,
                    genderId: data.savedData?.genderId ?? 1,
                    isAddressSame: data.savedData?.isAddressSame ?? true,
                    address: data.savedData?.beneficiaryAddress ?? data.memberPostalAddress,
                    memberPostalAddress: data.memberPostalAddress
                }
            ))(state.form),
            relationshipTypes: data.relationshipTypes,
            genderTypes: data.genderTypes
        }
    }),
    on(ResetComponentStateAction, (_state) => state),
    on(AddBeneficiaryAction, (state) => {
        return {
            ...state,
            form: setValue(Object.assign(new StartAPensionBeneficiaryForm(),
                {
                    ...state.form.value,
                    beneficiaryAdded: true,
                    name: state.formData.savedData?.beneficiaryName ?? "",
                    dob: state.formData.savedData?.beneficiaryDob.split("T")[0] ?? "",
                    relationshipId: state.formData.savedData?.beneficiaryRelationshipId ?? 1,
                    genderId: state.formData.savedData?.genderId ?? 1,
                    isAddressSame: state.formData.savedData?.isAddressSame ?? true,
                    address: state.formData.savedData?.beneficiaryAddress ?? {
                        ...state.formData.memberPostalAddress,
                        isRequired: true
                    } as AddressModel,
                    memberPostalAddress: state.formData.memberPostalAddress
                }
            ))(state.form),
        }
    }),
    on(RemoveBeneficiaryAction, (state) => {
        return {
            ...state,
            form: setValue(Object.assign(new StartAPensionBeneficiaryForm(),
                {
                    ...state.form.value,
                    beneficiaryAdded: false,
                    name: state.formData.savedData?.beneficiaryName ?? "",
                    dob: state.formData.savedData?.beneficiaryDob.split("T")[0] ?? "",
                    relationshipId: state.formData.savedData?.beneficiaryRelationshipId ?? 1,
                    genderId: state.formData.savedData?.genderId ?? 1,
                    isAddressSame: state.formData.savedData?.isAddressSame ?? true,
                    address: state.formData.savedData?.beneficiaryAddress ?? {
                        ...state.formData.memberPostalAddress,
                        isRequired: true
                    } as AddressModel,
                    memberPostalAddress: state.formData.memberPostalAddress
                }
            ))(state.form),
        }
    }),
);

const editStartAPensionBeneficiaryFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateStartAPensionBeneficiaryForm
)

export function startAPensionBeneficiaryFormComponentReducer(state: any | undefined, action: Action) {
    return editStartAPensionBeneficiaryFormState(state, action);
}
