import { InsuranceService } from './../../../../services/insurance.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable()
export class InsuranceUpdateHistoryComponentEffects {
    constructor(private actions$: Actions,
        private service: InsuranceService) {
    }

    getHistory$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.RequestAction),
        switchMap((action: any) => this.service.getHistory(action.accountId, action.page).pipe(
            map((data: any) => {
                return ActionsList.ResponseAction({ insuranceHistory: data.data });
            }),
            catchError(_err => {
                return of(ActionsList.RequestErrorAction());
            })
        )),
    ));
}
