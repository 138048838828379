import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../../store/shared.reducers';
import { MemberPortalSharedState } from '../../../store/shared.states';
import { IStartAPensionFormComponentState, } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionFormState = createSelector(memberPortalState, (state) => state.startAPensionFormComponentState);

export const startAPensionForm_CurrentStep = createSelector(
    getStartAPensionFormState,
    (state: IStartAPensionFormComponentState) => state.currentStep
);

export const startAPensionForm_ApplicationId = createSelector(
    getStartAPensionFormState,
    (state: IStartAPensionFormComponentState) => state.applicationId
);

export const startAPensionForm_ApplicationData = createSelector(
    getStartAPensionFormState,
    (state: IStartAPensionFormComponentState) => state.applicationData
);

export const startAPensionForm_InitialFormData = createSelector(
    getStartAPensionFormState,
    (state: IStartAPensionFormComponentState) => state.initialFormData
);