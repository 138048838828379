<div id="insurance-update-history-card" class="card" appanimatecss>
    <div class="top-row">
        <p class="change-type">{{getChangeType()}}</p>
        <p class="badge d-flex align-items-center justify-content-center"
            [ngClass]="(insuranceUpdateDetails.statusId === 1 ? 'badge-info' : insuranceUpdateDetails.statusId === 2 ? 'badge-danger' : 'badge-success' )">
            {{insuranceUpdateDetails.caseStatus}}</p>
    </div>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="d-flex align-items-center ">
                <p class="request-date">Requested on: {{insuranceUpdateDetails.submittedOn | date:'dd/MM/yyyy, h:mm
                    a'}}</p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex flex-row flex-wrap">
            <div class="body p-0 col-12">
                <div class="d-flex flex-column p-3 grouping" *ngFor="let detail of insuranceUpdateDetails.coverTypes">
                    <p class="cover-title">{{detail.coverType}}</p>
                    <div *ngFor="let coverTypeDetail of detail.details">
                        <p class="cover-info">{{coverTypeDetail.insuranceCoverTypeDetail}}
                            {{coverTypeDetail.amount ===
                            0 ? ' cover cancelled.' :
                            ' cover reduced to ' + (coverTypeDetail.amount | currency) + '.'}}</p>
                    </div>
                </div>
            </div>

        </div>

    </mat-expansion-panel>
</div>