import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ActionsList from './actions';
import { EMPTY } from 'rxjs';
import { StartAPensionService } from 'src/app/services/start-a-pension.service';

@Injectable()
export class StartAPensionReviewComponentEffects {
    constructor(private store: Store<any>,
        private actions$: Actions,
        private service: StartAPensionService) {
    }

    submitApplication$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.SubmitApplicationAction),
        switchMap((action: any) => this.service.submitApplication(action.accountId, action.applicationId).pipe(
            map((data: any) => {
                return ActionsList.ApplicationSubmittedAction()
            }),
            catchError(err => {
                return EMPTY;
            })
        ))
    ))
}
