import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { insuranceUpdate_ReduceForm, insuranceUpdate_InitialForm, insuranceUpdate_InsuranceToCancel, insuranceUpdate_InsuranceToReduce, insuranceUpdate_Model, insuranceUpdate_CancelForm, insuranceUpdate_ReduceFormKeyProps, insuranceUpdate_Submitted, insuranceUpdate_CancelFormKeyProps } from './selectors';
import { ResetStateAction, SetInsuranceDetailsToCancelAction, SetInsuranceDetailsToReduceAction, SubmitInsuranceToCancelAction, SubmitInsuranceToReduceAction } from './actions';
import { Router } from '@angular/router';
import { InsuranceCancelForm, InsuranceReduceForm, SelectionForm } from './state';
import { Helper } from '@ifaa-components/ui-components';
import { InsuranceCoverTypeDetailsUpdateModel, InsuranceUpdateSubmissionModel } from 'src/app/model/insurance-details.model';
import { SetErrorsAction } from 'ngrx-forms';
import { distinctUntilChanged } from 'rxjs';
import { commonState_IsSending } from 'src/app/store/common/common.selectors';

@Component({
    selector: 'app-insurance-update',
    templateUrl: './insurance-update.component.html',
    styleUrls: ['./insurance-update.component.scss']
})
export class InsuranceUpdateComponent extends ComponentBase implements OnInit, OnDestroy {

    model$ = this.store.pipe(select(insuranceUpdate_Model));
    initialForm$ = this.store.pipe(select(insuranceUpdate_InitialForm));
    reduceForm$ = this.store.pipe(select(insuranceUpdate_ReduceForm));
    reduceFormKeyProps$ = this.store.pipe(select(insuranceUpdate_ReduceFormKeyProps))
    cancelFormKeyProps$ = this.store.pipe(select(insuranceUpdate_CancelFormKeyProps))
    cancelForm$ = this.store.pipe(select(insuranceUpdate_CancelForm));
    submitted$ = this.store.pipe(select(insuranceUpdate_Submitted));
    selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
    insuranceToReduce$ = this.store.pipe(select(insuranceUpdate_InsuranceToReduce));
    insuranceToCancel$ = this.store.pipe(select(insuranceUpdate_InsuranceToCancel));

    submitting$ = this.appstore.pipe(select(commonState_IsSending));

    helper: Helper = new Helper();

    constructor(public store: Store<MemberPortalSharedState>,
        public appstore: Store<AppState>,
        public router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
        this.sub = this.model$.subscribe(x => {
            if (!x) {
                this.router.navigate(['/insurance'])
            }
        })

        this.sub = this.cancelFormKeyProps$.pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))).subscribe(x => {
            if (x) {
                x.forEach((insuranceType) => {
                    if (insuranceType.code === "B") {
                        let deathToggle = true;
                        let tpdToggle = true;
                        let deathCheckboxId = '';
                        let tpdCheckboxId = '';

                        insuranceType.coverTypes.forEach(cover => {
                            if (cover.coverType === "Death") {
                                deathCheckboxId = cover.checkboxId;
                                deathToggle = cover.toggled;
                            }
                            else {
                                tpdCheckboxId = cover.checkboxId;
                                tpdToggle = cover.toggled;
                            }
                        })

                        if (deathToggle && !tpdToggle) {
                            this.store.dispatch(new SetErrorsAction(deathCheckboxId, { 'customError': 'You cannot cancel Death Cover while retaining TPD.' }))
                        }
                        else {
                            this.store.dispatch(new SetErrorsAction(deathCheckboxId, {}))
                        }
                    }
                })
            }
        })

        this.sub = this.reduceFormKeyProps$.pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))).subscribe(x => {
            if (x) {
                x.forEach((insuranceType) => {
                    // have to match off code B for Death & TPD..
                    if (insuranceType.code === "B") {
                        const originalTotal = insuranceType.originalTotal;
                        let newDeathAmount = 0;
                        let newTpdAmount = 0;

                        let deathId = '';
                        let tpdId = '';

                        let deathErrors = {};
                        let tpdErrors = {};

                        insuranceType.coverTypes.forEach(cover => {
                            if (cover.coverType === "Death") {
                                newDeathAmount = cover.newAmount;
                                deathId = cover.newAmountId;
                            }
                            else {
                                newTpdAmount = cover.newAmount;
                                tpdId = cover.newAmountId;
                            }
                        })
                        if (originalTotal <= newTpdAmount) {
                            tpdErrors = { 'customError': 'TPD amount cannot be higher than or equal to your original cover amount.' };
                        }

                        if (originalTotal < newDeathAmount) {
                            deathErrors = { 'customError': 'Amount cannot be higher than your original cover amount.' };
                        }

                        if (newTpdAmount < 1000) {
                            tpdErrors = { 'customError': 'Cover amount must be at least $1000.' };
                        }

                        if (newDeathAmount < 1000) {
                            deathErrors = { 'customError': 'Cover amount must be at least $1000.' };
                        }

                        if (newTpdAmount > newDeathAmount) {
                            tpdErrors = { 'customError': 'TPD cover amount must be equal to or lower than death cover amount.' };
                        }

                        this.store.dispatch(new SetErrorsAction(deathId, deathErrors));
                        this.store.dispatch(new SetErrorsAction(tpdId, tpdErrors));
                    }
                    else {
                        const originalTotal = insuranceType.originalTotal;
                        let newAmount = 0;
                        let controlid = '';

                        // There will be only one control in this array
                        insuranceType.coverTypes.forEach(cover => {
                            newAmount = cover.newAmount;
                            controlid = cover.newAmountId;
                        })

                        // Income protection minimum is 1200
                        if (insuranceType.code === "I" && newAmount < 1200) {
                            this.store.dispatch(new SetErrorsAction(controlid, { 'customError': 'Cover amount must be at least $1200.' }))
                        }
                        // Other insurance minimum is 1000
                        else if (newAmount < 1000) {
                            this.store.dispatch(new SetErrorsAction(controlid, { 'customError': 'Cover amount must be at least $1000.' }))
                        }
                        else if (originalTotal <= newAmount) {
                            this.store.dispatch(new SetErrorsAction(controlid, { 'customError': 'Amount cannot be higher than or equal to your original cover amount.' }))
                        }
                        else {
                            this.store.dispatch(new SetErrorsAction(controlid, {}))
                        }
                    }
                })
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.store.dispatch(ResetStateAction());
    }

    insuranceNotSelected(form: SelectionForm[]) {
        return form.every(x => !x.checkbox)
    }

    exitForm() {
        this.store.dispatch(ResetStateAction());
        this.router.navigate(['/insurance'])
    }

    async cancelCover(form: SelectionForm[]) {
        const insuranceData = await this.helper.getValue(this.model$);
        const selectedIds = form.filter(x => x.checkbox).map(x => x.insuranceId);
        const insuranceToCancel = insuranceData.insurances.filter(x => selectedIds.findIndex(id => id == x.insuranceId) !== -1);

        this.store.dispatch(SetInsuranceDetailsToCancelAction({ insurances: insuranceToCancel }))
    }

    async reduceCover(form: SelectionForm[]) {
        const insuranceData = await this.helper.getValue(this.model$);
        const selectedIds = form.filter(x => x.checkbox).map(x => x.insuranceId);
        const insuranceToReduce = insuranceData.insurances.filter(x => selectedIds.findIndex(id => id == x.insuranceId) !== -1);

        this.store.dispatch(SetInsuranceDetailsToReduceAction({ insurances: insuranceToReduce }))
    }

    cancelReducingInsurance() {
        this.store.dispatch(SetInsuranceDetailsToReduceAction({ insurances: [] }))
    }

    cancelCancellingInsurance() {
        this.store.dispatch(SetInsuranceDetailsToCancelAction({ insurances: [] }))
    }

    async submitInsuranceToCancel(insuranceToCancel: InsuranceCancelForm[]) {
        const submission = {
            insurances: insuranceToCancel.map(insurance => ({
                insuranceId: insurance.insuranceId,
                originalAmount: insurance.amount,
                details: insurance.covers.filter(x => x.checkbox).map(coverType => ({
                    insuranceCoverTypeDetailId: coverType.insuranceCoverTypeDetailId,
                    newAmount: 0
                } as InsuranceCoverTypeDetailsUpdateModel))
            }))
        } as InsuranceUpdateSubmissionModel;

        var account = await this.helper.getValue(this.selectedAccount$)

        this.store.dispatch(SubmitInsuranceToCancelAction({ accountId: account.accountId, submission }));
    }

    async submitInsuranceToReduce(insuranceToReduce: InsuranceReduceForm[]) {
        const submission = {
            insurances: insuranceToReduce.map(insurance => ({
                insuranceId: insurance.insuranceId,
                originalAmount: insurance.amount,
                details: insurance.covers.map(coverType => ({
                    insuranceCoverTypeDetailId: coverType.insuranceCoverTypeDetailId,
                    newAmount: coverType.newAmount
                } as InsuranceCoverTypeDetailsUpdateModel))
            }))
        } as InsuranceUpdateSubmissionModel;

        var account = await this.helper.getValue(this.selectedAccount$)

        this.store.dispatch(SubmitInsuranceToReduceAction({ accountId: account.accountId, submission }));
    }

    trackByFn(index, item) {
        return index;
    }
}
