import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { InsuranceDetailsModel, InsuranceItem, InsuranceUpdateSubmissionModel } from 'src/app/model/insurance-details.model';

export const ResetStateAction = createAction('[Insurance update] reset state');
export const SetInsuranceDetailsAction = createAction('[Insurance update] set insurance details', props<{ insurance: InsuranceDetailsModel }>());

export const SetInsuranceDetailsToReduceAction = createAction('[Insurance update] set insurance details to reduce', props<{ insurances: InsuranceItem[] }>());
export const SetInsuranceDetailsToCancelAction = createAction('[Insurance update] set insurance details to cancel', props<{ insurances: InsuranceItem[] }>());

export const SubmitInsuranceToReduceAction = createAction('[Insurance update] submit reduce insurance', props<{ accountId: number, submission: InsuranceUpdateSubmissionModel }>());
export const SubmitInsuranceToCancelAction = createAction('[Insurance update] submit cancel insurance', props<{ accountId: number, submission: InsuranceUpdateSubmissionModel }>());

export const SubmissionErrorAction = createAction('[Insurance update] set submission error');
export const SubmissionSuccessAction = createAction('[Insurance update] set submission success');