import { createAction, props } from "@ngrx/store";
import { PensionApplicationBeneficiaryFormModel, PensionApplicationBeneficiarySubmissionModel, } from "src/app/model/start-a-pension-form.model";

export const SetBeneficiaryFormDataAction = createAction('[Start a pension beneficiary form component] set initial form data', props<{ data: PensionApplicationBeneficiaryFormModel }>())
export const ResetComponentStateAction = createAction('[Start a pension beneficiary form component] reset component state');

export const SubmitFormAction = createAction('[Start a pension beneficiary form component] submit form', props<{ accountId: number, applicationId: number, submission: PensionApplicationBeneficiarySubmissionModel }>());
export const SubmitFormErrorAction = createAction('[Start a pension beneficiary form component] submit form error');

export const AddBeneficiaryAction = createAction('[Start a pension beneficiary form component] add beneficiary');
export const RemoveBeneficiaryAction = createAction('[Start a pension beneficiary form component] remove beneficiary');