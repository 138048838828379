import { createReducer, on, Action } from '@ngrx/store';
import {
    createFormGroupState,
    onNgrxForms, onNgrxFormsAction, setValue, SetValueAction,
    updateGroup,
    validate,
    wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { IStartAPensionEligibilityFormComponentState, StartAPensionEligibilityForm } from './state';
import { ResetComponentStateAction, SetConditionsAction, SetEligibilityFormDataAction, SetTypesOfAccountsAction, SubmitFormAction, SubmitFormErrorAction } from './actions';
import { required } from 'ngrx-forms/validation';

const formName = 'StartAPensionEligibilityForm';

export const state: IStartAPensionEligibilityFormComponentState = {
    formData: null,
    form: createFormGroupState(formName, new StartAPensionEligibilityForm()),
    typeOfAccounts: [],
    conditionsOfRelease: [],
};

export const validateStartAPensionEligibilityForm = updateGroup<StartAPensionEligibilityForm>({
    sapAusCitizen: (state, _parentState) => {
        const valueValidator = (input: boolean) => {
            const errors = {};

            if (!input) {
                errors["customError"] = "You must be an Australian citizen, New Zealand Citizen or a permanent resident of Australia to proceed."
            }

            return errors;
        }
        state = validate(state, required, valueValidator)
        return state;
    },
    sapTypeOfAccount: (state, parentState) => {
        const valueValidator = (input: number) => {
            const errors = {};

            if (input === 2 && !parentState.value.hasReachedPreservationAge) {
                errors["customError"] = parentState.value.wording["preservationAgeWarning"]
            }
            return errors;
        }

        state = validate(state, required, valueValidator)
        return state;
    },
    otherReasonText: (state, parentState) => {
        const valueValidator = (input: string) => {
            const errors = {};

            if (!input && parentState.value.sapConditionOfRelease === 5) {
                errors["customError"] = 'You must provide a reason for this condition of release.'
            }

            return errors;
        }

        state = validate(state, valueValidator)
        return state;
    }
})

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        // get control id in index 1
        const actionSplit = action.controlId.split(".");
        const actionFormName = actionSplit[0];
        const controlId = actionSplit[1];

        if (actionFormName !== formName) return state;

        switch (controlId) {
            case "sapTypeOfAccount": {
                var conditionOfReleaseId = 3;

                if (action.value === 2) {
                    conditionOfReleaseId = 6;
                }

                return {
                    ...state,
                    form: setValue(Object.assign(new StartAPensionEligibilityForm(),
                        {
                            ...state.form.value,
                            sapTypeOfAccount: action.value,
                            sapConditionOfRelease: conditionOfReleaseId
                        }
                    ))(state.form),
                }
            }
        }
        return state;
    }),
    on(SetEligibilityFormDataAction, (state, { data }) => {
        // When the component initially gets the data we default to Retirement pension type and show the 5 conditions of release related to pension type
        return {
            ...state,
            formData: data,
            form: setValue(Object.assign(new StartAPensionEligibilityForm(),
                {
                    ...state.form.value,
                    sapAusCitizen: true,
                    sapTypeOfAccount: 1,
                    sapConditionOfRelease: 3,
                    hasReachedPreservationAge: data.hasReachedPreservationAge,
                    wording: data.formWording
                }
            ))(state.form),
            typeOfAccounts: data.memberSixtyFiveOrOver ? data.pensionTypeKeyValues.filter(x => x.key === 1) : data.pensionTypeKeyValues,
            conditionsOfRelease: data.conditionOfReleaseKeyValues.filter(x => x.key !== 6)
        }
    }),
    on(ResetComponentStateAction, (_state) => state),
    on(SetTypesOfAccountsAction, (state, { types }) => {
        return {
            ...state,
            typeOfAccounts: types
        }
    }),
    on(SetConditionsAction, (state, { conditions }) => {
        return {
            ...state,
            conditionsOfRelease: conditions
        }
    }),
);

const editStartAPensionEligibilityFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateStartAPensionEligibilityForm
)

export function startAPensionEligibilityFormComponentReducer(state: any | undefined, action: Action) {
    return editStartAPensionEligibilityFormState(state, action);
}
