import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-start-a-pension-view',
  templateUrl: './start-a-pension-view.component.html',
  styleUrls: ['./start-a-pension-view.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class StartAPensionViewComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}