import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from 'src/app/modules/shared/store/shared.reducers';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { IStartAPensionEligibilityFormComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionEligibilityFormState = createSelector(memberPortalState, (state) => state.startAPensionEligibilityFormComponentState);

export const startAPensionEligibilityForm_Form = createSelector(
    getStartAPensionEligibilityFormState,
    (state: IStartAPensionEligibilityFormComponentState) => state.form
);

export const startAPensionEligibilityForm_TypesOfAccounts = createSelector(
    getStartAPensionEligibilityFormState,
    (state: IStartAPensionEligibilityFormComponentState) => state.typeOfAccounts
);

export const startAPensionEligibilityForm_Conditions = createSelector(
    getStartAPensionEligibilityFormState,
    (state: IStartAPensionEligibilityFormComponentState) => state.conditionsOfRelease
);

export const startAPensionEligibilityForm_ShowConditionOfReleaseNote = createSelector(
    getStartAPensionEligibilityFormState,
    (state: IStartAPensionEligibilityFormComponentState) => state.form.value.sapConditionOfRelease === 1 || state.form.value.sapConditionOfRelease === 4 || state.form.value.sapConditionOfRelease === 5
);