import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { AddBeneficiaryAction, RemoveBeneficiaryAction, ResetComponentStateAction, SetBeneficiaryFormDataAction, SubmitFormAction } from './actions';
import { PensionApplicationBeneficiaryFormModel, } from 'src/app/model/start-a-pension-form.model';
import { Helper } from '@ifaa-components/ui-components';
import { startAPensionBeneficiaryForm_BeneficiaryAdded, startAPensionBeneficiaryForm_Form, startAPensionBeneficiaryForm_GenderTypes, startAPensionBeneficiaryForm_RelationshipTypes } from './selectors';
import { ResetParentFormStateAction, SetCurrentStepAction } from '../actions';
import { StartAPensionBeneficiaryForm } from './state';

@Component({
    selector: 'app-start-a-pension-beneficiary',
    templateUrl: './start-a-pension-beneficiary-form.component.html',
    styleUrls: ['./start-a-pension-beneficiary-form.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class StartAPensionBeneficiaryFormComponent extends ComponentBase implements OnInit, OnDestroy {
    @Input() set data(value: PensionApplicationBeneficiaryFormModel) {
        this.formData = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set application(value: number) {
        this.applicationId = value;
    }
    @Input() set submitting(value: boolean) {
        this.isSubmitting = value;
    }

    form$ = this.store.pipe(select(startAPensionBeneficiaryForm_Form));
    beneficiaryAdded$ = this.store.pipe(select(startAPensionBeneficiaryForm_BeneficiaryAdded));
    relationshipTypes$ = this.store.pipe(select(startAPensionBeneficiaryForm_RelationshipTypes));
    genderTypes$ = this.store.pipe(select(startAPensionBeneficiaryForm_GenderTypes));

    formData: PensionApplicationBeneficiaryFormModel = null;
    accountId: number = null;
    applicationId: number = null;
    helper = new Helper();
    isSubmitting: boolean = false;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
        this.store.dispatch(SetBeneficiaryFormDataAction({ data: this.formData, }))

    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.store.dispatch(ResetComponentStateAction())
    }

    exit() {
        this.store.dispatch(ResetParentFormStateAction());
        this.store.dispatch(ResetComponentStateAction())
        this.router.navigate(['/start-a-pension'])
    }

    goBack() {
        this.store.dispatch(SetCurrentStepAction({ step: 4 }))
    }

    addBeneficiary() {
        this.store.dispatch(AddBeneficiaryAction());
    }

    removeBeneficiary() {
        this.store.dispatch(RemoveBeneficiaryAction());
    }

    async saveBeneficiary(submissionData: StartAPensionBeneficiaryForm) {
        this.store.dispatch(SubmitFormAction({
            accountId: this.accountId, applicationId: this.applicationId, submission: {
                beneficiaryAdded: submissionData.beneficiaryAdded,
                name: submissionData.beneficiaryAdded ? submissionData.name : null,
                dob: submissionData.beneficiaryAdded ? submissionData.dob : null,
                relationshipId: submissionData.beneficiaryAdded ? submissionData.relationshipId : null,
                genderId: submissionData.beneficiaryAdded ? submissionData.genderId : null,
                isAddressSame: submissionData.beneficiaryAdded ? submissionData.isAddressSame : null,
                address: submissionData.beneficiaryAdded ? submissionData.address : null
            }
        }))
    }
}
