import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { ResetComponentStateAction, SearchBankNameAction, SetBankDetailsFormDataAction, SubmitFormAction } from './actions';
import { PensionApplicationBankDetailsFormModel } from 'src/app/model/start-a-pension-form.model';
import { Helper } from '@ifaa-components/ui-components';
import { startAPensionBankDetailsForm_Form } from './selectors';
import { ResetParentFormStateAction, SetCurrentStepAction } from '../actions';
import { StartAPensionBankDetailsForm } from './state';
import { debounceTime, distinctUntilChanged, pairwise } from 'rxjs';

@Component({
    selector: 'app-start-a-pension-bank-details',
    templateUrl: './start-a-pension-bank-details-form.component.html',
    styleUrls: ['./start-a-pension-bank-details-form.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class StartAPensionBankDetailsFormComponent extends ComponentBase implements OnInit, OnDestroy {
    @Input() set data(value: PensionApplicationBankDetailsFormModel) {
        this.formData = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set application(value: number) {
        this.applicationId = value;
    }
    @Input() set submitting(value: boolean) {
        this.isSubmitting = value;
    }

    form$ = this.store.pipe(select(startAPensionBankDetailsForm_Form));

    formData: PensionApplicationBankDetailsFormModel = null;
    accountId: number = null;
    applicationId: number = null;
    helper = new Helper();
    isSubmitting: boolean = false;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
        this.store.dispatch(SetBankDetailsFormDataAction({ data: this.formData, }))

        this.sub = this.form$.pipe(
            pairwise(),
            distinctUntilChanged((x, y) => {
                if (x[1].value.bankAccountBsb !== y[1].value.bankAccountBsb)
                    return false;

                return true;
            }),
            debounceTime(500)
        ).subscribe(form => {
            if (form[0].value.bankAccountBsb !== form[1].value.bankAccountBsb) {
                this.dispatch(this.store, SearchBankNameAction({ bsb: form[1].value.bankAccountBsb }));
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.store.dispatch(ResetComponentStateAction())
    }

    exit() {
        this.store.dispatch(ResetParentFormStateAction());
        this.store.dispatch(ResetComponentStateAction())
        this.router.navigate(['/start-a-pension'])
    }

    goBack() {
        this.store.dispatch(SetCurrentStepAction({ step: 3 }))
    }

    async saveBankDetails(submissionData: StartAPensionBankDetailsForm) {
        this.store.dispatch(SubmitFormAction({
            accountId: this.accountId, applicationId: this.applicationId, submission: {
                bankAccountBsb: submissionData.bankAccountBsb,
                bankAccountName: submissionData.bankAccountName,
                bankAccountNumber: submissionData.bankAccountNumber,
                financialInstituteName: submissionData.financialInstitutionName
            }
        }))
    }
}
