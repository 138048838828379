<div id="insurance-update-history" *ngIf="(model$ | async); let data">

    <div *ngIf="data.length > 0">
        <p>Your insurance change history for requests submitted online:</p>
        <div class="changes-container">

            <ng-container *ngFor="let item of data">
                <app-insurance-update-history-card [details]="item"></app-insurance-update-history-card>
            </ng-container>

        </div>
        <div *ngIf="(filter$|async).value.currentPage !== -1 && !(isLoading$|async)">

            <app-button (onClick)="onLoadMore()" class="d-flex justify-content-center mt-2" title="Load more"
                icon="add">
            </app-button>

        </div>
    </div>

    <ng-container *ngIf="data.length === 0">

        <app-no-items
            message="You have not submitted any requests to change your insurance cover. Once you have submitted a request you will be able to track its status from this page."></app-no-items>


    </ng-container>

    <div class="d-flex justify-content-end col-12">
        <app-button class="mt-2" title="Exit" icon="{{'BTN.insurance.update.icon' | translate}}"
            color="{{'BTN.insurance.update.color' | translate}}" (onClick)="goBack()">
        </app-button>
    </div>

</div>