import { IInsuranceUpdateHistoryComponentState, InsuranceUpdateHistoryFilter } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
    createFormGroupState,
    onNgrxForms, onNgrxFormsAction, setValue, SetValueAction,
} from 'ngrx-forms';
import { IncrementPageAction, RequestErrorAction, ResetStateAction, ResponseAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

const insuranceHistoryFilter = "InsuranceHistoryFilter";

const helper = new Helper();

export const initialState: IInsuranceUpdateHistoryComponentState = {
    model: null,
    filter: createFormGroupState(insuranceHistoryFilter, new InsuranceUpdateHistoryFilter()),
    isLoading: false,
};

const reducer = createReducer(initialState,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        return state;
    }),
    on(ResetStateAction, (_state) => initialState),
    on(ResponseAction, (state, { insuranceHistory }) => {
        var clone = helper.clone(state.filter.value);
        var dataClone = [...insuranceHistory];

        if (clone.currentPage !== 0) {
            dataClone = [...state.model, ...insuranceHistory];
        }

        if (insuranceHistory.length < 10) {
            clone.currentPage = -1;
        }

        return {
            ...state,
            filter: setValue(clone)(state.filter),
            model: dataClone,
            isLoading: false
        }

    }),
    on(IncrementPageAction, (state) => {
        if (state.filter.value.currentPage === -1 || state.isLoading)
            return state;

        var clone = helper.clone(state.filter.value);

        clone.currentPage = state.filter.value.currentPage + 1;

        return {
            ...state,
            isLoading: true,
            filter: setValue(clone)(state.filter)
        };
    }),
    on(RequestErrorAction, (state) => {
        return {
            ...state,
            isLoading: false
        }
    })
);

export function insuranceUpdateHistoryComponentReducer(state: any | undefined, action: Action) {
    return reducer(state, action);
}
