
import { featureKey } from 'src/app/modules/shared/store/shared.reducers';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { IStartAPensionDocumentsFormComponentState } from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionDocumentFormState = createSelector(memberPortalState, (state) => state.startAPensionDocumentFormComponentState);

export const startAPensionDocumentsForm_Form = createSelector(
    getStartAPensionDocumentFormState,
    (state: IStartAPensionDocumentsFormComponentState) => state.form
);

export const startAPensionDocumentsForm_UploadedDocument = createSelector(
    getStartAPensionDocumentFormState,
    (state: IStartAPensionDocumentsFormComponentState) => state.uploadedDocument
);

export const startAPensionDocumentsForm_DeletedDocument = createSelector(
    getStartAPensionDocumentFormState,
    (state: IStartAPensionDocumentsFormComponentState) => state.deletedDocument
);

export const startAPensionDocumentsForm_ReplacedDocument = createSelector(
    getStartAPensionDocumentFormState,
    (state: IStartAPensionDocumentsFormComponentState) => state.replacedDocument
);

export const startAPensionDocumentsForm_FileRequestFailed = createSelector(
    getStartAPensionDocumentFormState,
    (state: IStartAPensionDocumentsFormComponentState) => state.fileRequestFailed
);

export const startAPensionDocumentsForm_SupportingDocuments = createSelector(
    getStartAPensionDocumentFormState,
    (state: IStartAPensionDocumentsFormComponentState) => state.form.controls.supportingDocuments
);

export const startAPensionDocumentsForm_FormValidity = createSelector(
    getStartAPensionDocumentFormState,
    (state: IStartAPensionDocumentsFormComponentState) => state.form.isValid
);

export const startAPensionDocumentsForm_Files = createSelector(
    getStartAPensionDocumentFormState,
    (state: IStartAPensionDocumentsFormComponentState) => {
        var files = {};

        state.form.controls.supportingDocuments.controls.forEach((overview, index) => {
            files[index] = {};
            files[index]['filesControlId'] = overview.controls.files.id;
            files[index]['files'] = overview.controls.files.value;
            files[index]['isRequired'] = overview.controls.isDocumentRequired.value;
        });

        return files;
    }
);