import { createReducer, on, Action } from '@ngrx/store';
import {
    createFormGroupState,
    onNgrxForms, onNgrxFormsAction, setValue, SetValueAction,
    updateGroup,
    validate,
    wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { IStartAPensionAccountDetailsFormComponentState, StartAPensionAccountDetailsForm } from './state';
import { ResetComponentStateAction, SetAccountDetailsFormDataAction, SubmitFormAction, SubmitFormErrorAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

const formName = 'StartAPensionAccountDetailsForm';

var helper = new Helper();

export const state: IStartAPensionAccountDetailsFormComponentState = {
    formData: null,
    form: createFormGroupState(formName, new StartAPensionAccountDetailsForm()),
    transferTypes: [],
    investmentOptions: [],
};

export const validateStartAPensionAccountDetailsForm = updateGroup<StartAPensionAccountDetailsForm>({
    partialAmount: (state, parentState) => {
        const valueValidator = (input: number) => {
            const errors = {};

            if (parentState.value.transferType !== 3) return errors;

            if (input < 20000) {
                errors["customError"] = "Partial amount error."
                return errors;
            }

            if (input > parentState.value.accountBalance) {
                errors["customError"] = "Amount balance error."
                return errors;
            }
            return errors;
        }

        state = validate(state, valueValidator)
        return state;
    },
})

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, _action) => {
        return state;
    }),
    on(SetAccountDetailsFormDataAction, (state, { data, investmentOptions, accountBalance }) => {
        const options = helper.clone(investmentOptions);

        options.forEach(option => {
            const existingOption = data.savedData?.currentOptions.find(x => x.investmentOptionId == option.investmentOptionId);

            if (existingOption) {
                option.percentage = existingOption.percentage;
            }
            else {
                option.percentage = 0;
            }
        })

        return {
            ...state,
            formData: data,
            form: setValue(Object.assign(new StartAPensionAccountDetailsForm(),
                {
                    ...state.form.value,
                    transferType: data.savedData ? data.savedData.transferTypeId : 1,
                    partialAmount: data.savedData?.transferAmount ?? 0,
                    wording: data.customWording,
                    accountBalance: accountBalance,
                    options: options
                }
            ))(state.form),
            transferTypes: data.transferAmountTypes,
        }
    }),
    on(ResetComponentStateAction, (_state) => state),
);

const editStartAPensionAccountDetailsFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateStartAPensionAccountDetailsForm
)

export function startAPensionAccountDetailsFormComponentReducer(state: any | undefined, action: Action) {
    return editStartAPensionAccountDetailsFormState(state, action);
}
