import { editRollIn_Form, editRollIn_LastModified } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { ConfirmBackAction, ContinueAction, ResetAction, SelectedProviderAction, SubmitRequestAction } from './actions';
import { ResetAction as ResetSearchAction } from '../superannuation-provider-search/actions';
import { RollInEditModel, SuperannuationProviderItemModel } from 'src/app/model/rollin-edit.model';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { Router } from '@angular/router';
import { commonState_IsSending } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-rollin-edit',
  templateUrl: './rollin-edit.component.html',
  styleUrls: ['./rollin-edit.component.scss']
})
export class RollinEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(editRollIn_Form));
  lastModified$ = this.store.pipe(select(editRollIn_LastModified));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  isSubmitting$ = this.appstore.pipe(select(commonState_IsSending));

  accountId: number = 0;
  isMemberVerified = false;
  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.accountId = x.accountId;
        this.store.dispatch(ResetAction());
        this.store.dispatch(ResetSearchAction());
      }
    })
  }

  onVerified(verified: boolean) {
    this.isMemberVerified = verified;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onConfirmClick() {
    this.dispatch(this.store, ContinueAction());
  }

  onSubmitClick(data: RollInEditModel) {
    this.dispatch(this.store, SubmitRequestAction({ accountId: this.accountId, payload: data }));
  }

  onSelectedOption($event: SuperannuationProviderItemModel) {
    this.dispatch(this.store, SelectedProviderAction({ payload: $event }));
  }

  onGoToDashboardClick() {
    this.router.navigate(['/dashboard']);
  }

  onConfirmBackClick() {
    this.dispatch(this.store, ConfirmBackAction());
  }

  invalidProvider(input: SuperannuationProviderItemModel) {
    if (!input.name || !input.productName || !input.abn || !input.usi) {
      return true;
    }
    return false;
  }
}
