<div id="start-a-pension-payment-details">
    <h2 class="mb-3">Pension income payment details</h2>
    <div *ngIf="form$|async; let form">
        <form [ngrxFormState]="form" class="ml-3 mr-3">

            <div class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">
                        {{formData.customWording.paymentFrequencyQuestion}}:
                        <mat-icon class="ml-2 tooltip-readonly"
                            [matTooltip]="formData.customWording.paymentFrequencyTooltip">help_outline</mat-icon>
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio [customList]="frequencyTypes$"
                            [control]="form.controls.frequencyType"></app-entry-radio>
                    </div>
                </div>
            </div>

            <div class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">
                        {{formData.customWording.commencementOfPaymentQuestion}}:
                        <mat-icon class="ml-2 tooltip-readonly"
                            [matTooltip]="formData.customWording.commencementOfPaymentsTooltip">help_outline</mat-icon>
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio [customList]="commencementTypes$"
                            [control]="form.controls.commencementOfDateOption"></app-entry-radio>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="form.value.commencementOfDateOption === 2">
                <div class="question d-flex flex-row description mt-4 pl-2 nominate-date">
                    <div>
                        <div class="mt-3 custom-control custom-radio answer">
                            <app-entry-date-month-year title="Start date"
                                [date]="form.controls.commencementOfDate"></app-entry-date-month-year>
                        </div>
                    </div>
                </div>
                <p class="error" *ngIf="!isValidDate(form.value.commencementOfDate)">Selected date is invalid.</p>
                <p class="error"
                    *ngIf="isValidDate(form.value.commencementOfDate) && !isValidDatePeriod(form.value.commencementOfDate)">
                    Commencement date must be in the future.</p>
            </ng-container>

            <div class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">
                        {{formData.customWording.paymentTypeQuestion}}:
                        <mat-icon class="ml-2 tooltip-readonly"
                            [matTooltip]="formData.customWording.paymentTypeWarning">help_outline</mat-icon>
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio [customList]="paymentTypes$"
                            [control]="form.controls.paymentType"></app-entry-radio>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="form.value.paymentType === 1">
                <div class="question d-flex flex-row description mt-4 pl-2">
                    <div>
                        <div class="title d-flex flex-row align-items-center">
                            {{formData.customWording.paymentAmountQuestion}}:
                            <mat-icon class="ml-2 tooltip-readonly"
                                [matTooltip]="formData.customWording.nominatedWarning">help_outline</mat-icon>
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-number icon="attach_money" title="" [control]="form.controls.paymentAmount"
                                [decimalplaces]="2" [showincremental]="false"></app-entry-number>
                        </div>
                    </div>
                </div>
                <p class="error" *ngIf="form.value.paymentAmount <= 0">Payment amount must be above $0.</p>
                <p class="error" *ngIf="form.value.paymentAmount > form.value.annualMax">Payment amount must be below
                    your annual maximum of {{form.value.annualMax | currency}}.</p>
            </ng-container>

            <div class="question d-flex flex-column description mt-3 pl-2 options-container">
                <div class="title mb-1 d-flex flex-row align-items-center">
                    {{formData.customWording.futurePensionPaymentsQuestion}}:
                    <mat-icon class="ml-2 tooltip-readonly"
                        [matTooltip]="formData.customWording.futurePensionPaymentsWarning">help_outline</mat-icon>
                </div>
                <app-start-a-pension-investment-options [form]="form.controls.options"
                    [totalCount]="futureTotalPercent$ | async"
                    [isCurrent]="false"></app-start-a-pension-investment-options>
            </div>

        </form>
        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                    [isDisabled]="isSubmitting" color="{{'BTN.start-a-pension.cancel.color' | translate}}"
                    (onClick)="exit()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.start-a-pension.back.class' | translate}}" title="Back"
                    icon="{{'BTN.start-a-pension.back.icon' | translate}}" [isDisabled]="isSubmitting"
                    color="{{'BTN.start-a-pension.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                    color="{{'BTN.start-a-pension.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || isSubmitting || ((futureTotalPercent$|async) !== 100)" loading
                    (onClick)="savePaymentDetails(form.value)"></app-button>
            </div>
        </div>
    </div>
</div>