import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StartAPensionService } from 'src/app/services/start-a-pension.service';
import { SetApplicationSavedBeneficiaryAction } from '../actions';
import { PensionApplicationBeneficiarySubmissionModel } from 'src/app/model/start-a-pension-form.model';

@Injectable()
export class StartAPensionBeneficiaryFormComponentEffects {
    constructor(private actions$: Actions,
        private service: StartAPensionService) {
    }

    saveBeneficiary$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.SubmitFormAction),
        switchMap((action: any) => this.service.saveBeneficiary(action.accountId, action.applicationId, action.submission).pipe(
            map((_data: any) => {
                return SetApplicationSavedBeneficiaryAction({ beneficiary: action.submission })
            }),
            catchError(_err => {
                return of(ActionsList.SubmitFormErrorAction());
            })
        )),
    ))
}
