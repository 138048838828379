import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { startAPensionStart_CanCreateNewApp, startAPensionStart_Filter, startAPensionStart_Model, startAPensionStart_ProcessStarted, startAPensionStart_UpdatingSubmitted } from './selectors';
import { CancelApplicationAction, ContinueApplicationRequestAction, ProcessStartedAction, RequestAction, ResetComponentStateAction, UpdateSubmittedApplicationRequestAction } from './actions';
import { StartAPensionApplicationSummaryModel } from 'src/app/model/start-a-pension-application-summaries.model';
import { SetCurrentStepAction } from '../start-a-pension-form/actions';
import { memberAccountDropdown_SelectedAccount } from '../../member-account-dropdown/selectors';
import { startAPensionForm_ApplicationData } from '../start-a-pension-form/selectors';
import { startAPensionUpdateSubmitted_ApplicationData } from '../start-a-pension-update-submitted/selectors';
import { commonState_IsSending } from 'src/app/store/common/common.selectors';

@Component({
  selector: 'app-start-a-pension-start',
  templateUrl: './start-a-pension-start.component.html',
  styleUrls: ['./start-a-pension-start.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class StartAPensionStartComponent extends ComponentBase implements OnInit, OnDestroy {

  model$ = this.store.pipe(select(startAPensionStart_Model));
  filter$ = this.store.pipe(select(startAPensionStart_Filter));
  accountDropdown$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  canCreateNewApp$ = this.store.pipe(select(startAPensionStart_CanCreateNewApp));

  // form selectors
  formApplicationData$ = this.store.pipe(select(startAPensionForm_ApplicationData));
  processStarted$ = this.store.pipe(select(startAPensionStart_ProcessStarted));

  // updating submitted selectors
  updatingSubmittedApplicationData$ = this.store.pipe(select(startAPensionUpdateSubmitted_ApplicationData));
  updatingSubmitted$ = this.store.pipe(select(startAPensionStart_UpdatingSubmitted));

  isSubmitting$ = this.store.pipe(select(commonState_IsSending));

  accountId: number;
  applicationId: number = 0;
  currentList: StartAPensionApplicationSummaryModel[] = [];
  cancelDialogText: string = "Please confirm that you would like to cancel this request. This action cannot be reversed. Once cancelled, you will need to start a new application if you wish to create a new pension account request."


  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.accountDropdown$.pipe(distinctUntilChanged((x, y) => x.accountId === y.accountId)).subscribe(x => {
      if (x.accountId) {
        this.accountId = x.accountId;
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    })


    this.sub = combineLatest([this.filter$, this.model$])
      .pipe(
        map(([filter, model]) => ({ filter, model })))
      .subscribe(x => {
        if (x.model && x.filter) {
          switch (x.filter.value.activeOptionButton) {
            case "Draft": {
              this.currentList = x.model.draftApplications;
              break;
            }
            case "Submitted": {
              this.currentList = x.model.submittedApplications;
              break;
            }
            case "Cancelled": {
              this.currentList = x.model.cancelledApplications;
              break;
            }
          }
        }
      }
      );

    // if we have the application data, redirect user to form process
    this.sub = combineLatest([this.formApplicationData$, this.processStarted$]).subscribe(x => {
      if (x[0] && x[1]) {
        this.router.navigate(['/start-a-pension/create-application']);
      }
    })

    // if we have the application data, redirect user to updating submitted component
    this.sub = combineLatest([this.updatingSubmittedApplicationData$, this.updatingSubmitted$]).subscribe(x => {
      if (x[0] && x[1]) {
        this.router.navigate(['/start-a-pension/review'])
      }
    })

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
    this.dispatch(this.store, ResetComponentStateAction())
  }

  createNewApplication() {
    this.dispatch(this.store, ProcessStartedAction());
    this.dispatch(this.store, SetCurrentStepAction({ step: -1 }))
    this.router.navigate(['/start-a-pension/create-application']);
  }

  async continueApplication(applicationId: number) {
    this.applicationId = applicationId;
    this.dispatch(this.store, ProcessStartedAction());
    this.dispatch(this.store, ContinueApplicationRequestAction({ accountId: this.accountId, applicationId: applicationId }))
  }

  async cancelApplication(event: any, applicationId: number) {
    if (event === 'ok') {
      this.dispatch(this.store, CancelApplicationAction({ accountId: this.accountId, applicationId: applicationId }))
    }
  }

  async updateSubmittedApp(applicationId: number) {
    this.applicationId = applicationId;
    this.dispatch(this.store, UpdateSubmittedApplicationRequestAction({ accountId: this.accountId, applicationId: applicationId }))
  }
}
