import { AppFileModel } from "@ifaa-components/ui-components";
import { createAction, props } from "@ngrx/store";
import { PensionApplicationDocumentsFormModel } from "src/app/model/start-a-pension-form.model";

export const SetDocumentsFormDataAction = createAction('[Start a pension documents form component] set initial form data', props<{ data: PensionApplicationDocumentsFormModel }>())
export const ResetComponentStateAction = createAction('[Start a pension documents form component] reset state');

export const GreenIdVerifiedAction = createAction('[Start a pension documents form component] green id verified', props<{ verified: boolean }>());

export const SetupSupportingDocumentOverviewAction = createAction('[Start a pension documents form component] add app file model', props<{ documentTypeId: number, documentAmount: number, isDocumentRequired: boolean }>());

export const AddNewAppFileAction = createAction('[Start a pension documents form component] add new document detail', props<{ overviewIndex: number }>())
export const RemoveAppFileAction = createAction('[Start a pension documents form component] remove document detail', props<{ overviewIndex: number, uploadIndex: number }>())

export const SaveDocumentAction = createAction('[Start a pension documents form component] save document', props<{ accountId: number, applicationId: number, documentTypeId: number, controlId: any, documentIndex: number, fileName: string, payload: AppFileModel, draft: boolean }>());
export const UpdateSavedDocumentAction = createAction('[Start a pension documents form component] update uploaded document state', props<{ uploadedDocumentId: number, fileName: string, documentTypeId: number, documentIndex: number, controlId: any }>());

export const ReplaceDocumentAction = createAction('[Start a pension documents form component] replace uploaded document', props<{ accountId: number, applicationId: number, uploadedDocumentId: number, overviewIndex: number, documentIndex: number, controlId: any, documentTypeId: number, fileName: string, payload: AppFileModel, originalFile: AppFileModel, draft: boolean }>());
export const UpdateReplacedDocumentAction = createAction('[Start a pension documents form component] handle replaced document', props<{ documentTypeId: number, controlId: any, documentIndex: number, fileName: string, payload: AppFileModel }>());

export const DeleteDocumentAction = createAction('[Start a pension documents form component] delete document', props<{ accountId: number, applicationId: number, uploadedDocumentId: number, overviewIndex: number, uploadIndex: number, controlId: any, draft: boolean }>());
export const UpdateDeletedDocumentAction = createAction('[Start a pension documents form component] update deleted document state', props<{ uploadedDocumentId: number, overviewIndex: number, uploadIndex: number, controlId: any }>());

export const SetFileRequestFailedAction = createAction('[Start a pension documents form component] set reset file field', props<{ controlId: any, file: AppFileModel }>());
export const ResetFileRequestFailedAction = createAction('[Start a pension documents form component] clear reset field field');

export const SubmitFormAction = createAction('[Start a pension documents form component] complete documents component', props<{ accountId: number, applicationId: number }>())
export const SubmitFormErrorAction = createAction('[Start a pension documents form component] submit form error');
