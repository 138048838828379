import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from 'src/app/modules/shared/store/shared.reducers';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { IStartAPensionBeneficiaryFormComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionBeneficiaryFormState = createSelector(memberPortalState, (state) => state.startAPensionBeneficiaryFormComponentState);

export const startAPensionBeneficiaryForm_Form = createSelector(
    getStartAPensionBeneficiaryFormState,
    (state: IStartAPensionBeneficiaryFormComponentState) => state.form
);

export const startAPensionBeneficiaryForm_BeneficiaryAdded = createSelector(
    getStartAPensionBeneficiaryFormState,
    (state: IStartAPensionBeneficiaryFormComponentState) => state.form.value.beneficiaryAdded
);

export const startAPensionBeneficiaryForm_RelationshipTypes = createSelector(
    getStartAPensionBeneficiaryFormState,
    (state: IStartAPensionBeneficiaryFormComponentState) => state.relationshipTypes
);

export const startAPensionBeneficiaryForm_GenderTypes = createSelector(
    getStartAPensionBeneficiaryFormState,
    (state: IStartAPensionBeneficiaryFormComponentState) => state.genderTypes
);