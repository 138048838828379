import { createAction, props } from "@ngrx/store";
import {
    PensionApplicationAccountDetailsSubmissionModel,
    PensionApplicationBankDetailsSubmissionModel,
    PensionApplicationBeneficiarySubmissionModel,
    PensionApplicationDocumentModel,
    PensionApplicationPaymentDetailsSubmissionModel,
    StartAPensionFormModel,
    StartAPensionInitialFormModel
} from "src/app/model/start-a-pension-form.model";

export const ResetParentFormStateAction = createAction('[Start a pension form component] reset component state')
export const SetCurrentStepAction = createAction('[Start a pension form component] set current step', props<{ step: number }>())

export const IncrementCurrentStepWithApiRequestAction = createAction('[Start a pension form component] increment current step with api request', props<{ accountId: number, applicationId: number }>());
export const IncrementCurrentStepWithApiResponseAction = createAction('[Start a pension form component] increment current step with api response');

export const GetInitialFormDataRequestAction = createAction(`[Start a pension form component] get initial form data`, props<{ accountId: number }>())
export const GetInitialFormDataResponseAction = createAction(`[Start a pension form component] set initial form data`, props<{ data: StartAPensionInitialFormModel }>())

export const SetApplicationIdAction = createAction(`[Start a pension form component] set applicationId`, props<{ applicationId: number }>())

export const ApplicationDataRequestAction = createAction(`[Start a pension form component] get form data`, props<{ accountId: number, applicationId: number }>())
export const ApplicationDataResponseAction = createAction(`[Start a pension form component] set form data`, props<{ applicationData: StartAPensionFormModel }>())

export const SetApplicationDocumentsAction = createAction(`[Start a pension form component] update application document data`, props<{ documents: PensionApplicationDocumentModel[] }>())

export const SetApplicationSavedAccountDetailsAction = createAction(`[Start a pension form component] set application account details`, props<{ accountDetails: PensionApplicationAccountDetailsSubmissionModel }>())
export const SetApplicationSavedPaymentDetailsAction = createAction(`[Start a pension form component] set application payment details`, props<{ paymentDetails: PensionApplicationPaymentDetailsSubmissionModel }>())
export const SetApplicationSavedBankDetailsAction = createAction(`[Start a pension form component] set application bank details`, props<{ bankDetails: PensionApplicationBankDetailsSubmissionModel }>());
export const SetApplicationSavedBeneficiaryAction = createAction(`[Start a pension form component] set application beneficiary`, props<{ beneficiary: PensionApplicationBeneficiarySubmissionModel }>());
export const SetApplicationSavedDocumentsAction = createAction(`[Start a pension form component] set application documents`);