import { Component, OnDestroy, OnInit, } from "@angular/core";
import { ComponentBase } from "src/app/views/component-base";
import { AppState } from "src/app/store/app.states";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { accessYourSuperUpdateSubmitted_ApplicationData, accessYourSuperUpdateSubmitted_ApplicationId, accessYourSuperUpdateSubmitted_CustomData } from "./selector";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { GetCustomDataAction, GetKiwiSaverDataAction, GetSfhDataAction } from "./actions";
import { AccessYourSuperApplicationTypeEnum } from "src/app/enums/access-your-super-application-type.enum";
import { AccessYourSuperKiwiSaverDetailSectionModel, AccessYourSuperPaymentDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { memberAccountDropdown_SelectedAccount } from "../member-account-dropdown/selectors";
import { ResetComponentStateAction } from "../access-your-super-form/actions";
import { commonState_IsSending } from "src/app/store/common/common.selectors";

@Component({
  selector: 'app-access-your-super-update-submitted',
  templateUrl: './access-your-super-update-submitted.component.html',
  styleUrls: ['./access-your-super-update-submitted.component.scss']
})

export class AccessYourSuperUpdateSubmittedComponent extends ComponentBase implements OnInit, OnDestroy {

  customData$ = this.store.pipe(select(accessYourSuperUpdateSubmitted_CustomData));
  applicationData$ = this.store.pipe(select(accessYourSuperUpdateSubmitted_ApplicationData));
  applicationId$ = this.store.pipe(select(accessYourSuperUpdateSubmitted_ApplicationId));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  isSubmitting$ = this.store.pipe(select(commonState_IsSending));

  applicationUpdated: boolean = false;
  customData: AccessYourSuperPaymentDetailSectionModel | AccessYourSuperKiwiSaverDetailSectionModel = null;

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  async ngOnInit() {
    super.ngOnInitBase();

    this.sub = combineLatest([this.customData$, this.applicationData$, this.applicationId$, this.selectedAccount$])
      .pipe(
        map(([customData, applicationData, applicationId, selectedAccount]) => ({ customData, applicationData, applicationId, selectedAccount }))
      ).subscribe(x => {
        if (x.selectedAccount && x.applicationData && x.applicationId) {
          if (!x.customData) {
            switch (x.applicationData.conditionData.applicationTypeId) {
              case AccessYourSuperApplicationTypeEnum.SevereFinancialHardship: {
                this.dispatch(this.store, GetSfhDataAction({ accountId: x.selectedAccount.accountId, applicationId: x.applicationId }))
                break;
              }
              case AccessYourSuperApplicationTypeEnum.TransferToKiwiSaver: {
                this.dispatch(this.store, GetKiwiSaverDataAction({ accountId: x.selectedAccount.accountId, applicationId: x.applicationId }))
                break;
              }
              default: {
                this.dispatch(this.store, GetCustomDataAction({ accountId: x.selectedAccount.accountId, applicationId: x.applicationId, applicationTypeId: x.applicationData.conditionData.applicationTypeId }));
                break;
              }
            }
          }
          if (x) {
            this.customData = x.customData;
          }
        }

        if (!x.applicationData && !x.applicationId) {
          this.router.navigate(['/access-your-super']);
        }
      })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
    this.dispatch(this.store, ResetComponentStateAction());
  }

  leavePage() {
    this.router.navigate(['/access-your-super']);
  }

  submit() {
    this.applicationUpdated = true;
  }
}
