import { AppFileModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { PensionApplicationDocumentsFormModel } from "src/app/model/start-a-pension-form.model";

export interface IStartAPensionDocumentsFormComponentState {
    formData: PensionApplicationDocumentsFormModel;
    form: FormGroupState<StartAPensionDocumentsForm>;
    uploadedDocument: UploadedDocumentModel;
    deletedDocument: DeletedDocumentModel;
    fileRequestFailed: {
        controlId: string;
        file: AppFileModel;
    };
    replacedDocument: ReplacedDocumentModel;
}

export class UploadedDocumentModel {
    uploadedDocumentId: number;
    fileName: string;
    documentTypeId: number;
    documentIndex: number;
    controlId: any;
}

export class DeletedDocumentModel {
    uploadedDocumentId: number;
    overviewIndex: number;
    uploadIndex: number;
    controlId: any;
}

export class ReplacedDocumentModel {
    payload: AppFileModel;
    fileName: string;
    documentTypeId: number;
    documentIndex: number;
    controlId: any;
}

export class StartAPensionDocumentDetails {
    files: AppFileModel[] = [];
    documentTypeId: number;
    isDocumentRequired: boolean;
}

export class StartAPensionDocumentsForm {
    greenIdVerified: boolean = false;
    supportingDocuments: StartAPensionDocumentDetails[] = [];
}