import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';
import { IInsuranceUpdateHistoryComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const insuranceUpdateHistory = createSelector(memberPortalState, (state) => state.insuranceUpdateHistoryComponentState);

export const insuranceUpdateHistory_Model = createSelector(
    insuranceUpdateHistory,
    (state: IInsuranceUpdateHistoryComponentState) => state.model
);

export const insuranceUpdateHistory_Filter = createSelector(
    insuranceUpdateHistory,
    (state: IInsuranceUpdateHistoryComponentState) => state.filter
);

export const insuranceUpdateHistory_IsLoading = createSelector(
    insuranceUpdateHistory,
    (state: IInsuranceUpdateHistoryComponentState) => state.isLoading
);