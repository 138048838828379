
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { greaterThan, lessThanOrEqualTo, required } from 'ngrx-forms/validation';
import { ResetComponentStateAction, ResponseAction, SavedFormDataAction, SetTaxOptionsAction } from './actions';
import { AccessYourSuperCeasedPaidEmploymentForm, IAccessYourSuperCeasedPaidEmploymentComponentState } from './state';

export const state: IAccessYourSuperCeasedPaidEmploymentComponentState = {
  model: null,
  form: createFormGroupState('AccessYourSuperCeasedPaidEmploymentForm', new AccessYourSuperCeasedPaidEmploymentForm()),
  taxOptions: null,
  savedFormData: null,
};

export const validateCeasedPaidEmploymentForm = updateGroup<AccessYourSuperCeasedPaidEmploymentForm>({
  paymentType: validate(required),
  partialAmountSpecified: (state, parentState) => {
    if (parentState.value.paymentType === 1) {

      const valueValidator = (value: number) => {
        const errors = {};

        const totalBalance = parentState.value.investmentOptionDrawdown.reduce((a, b) => {
          return a + b.amount;
        }, 0);

        const formattedNumber = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(totalBalance);

        if (value > totalBalance) {
          errors['customError'] = `Your benefit amount must be lower than ${formattedNumber}.`
        }

        return errors;
      }
      //TODO: Uncomment this validation in PD-5188 and remove validation below once Acurity fixes account components
      // state = validate(state, required, greaterThan(0), lessThanOrEqualTo(parentState.value.restrictedNonPreservedAmount))
      state = validate(state, required, greaterThan(0), valueValidator)
    }
    return state;
  },
  isBeforeTax: (state, parentState) => {
    if (parentState.value.paymentType === 1) {
      state = validate(state, required)
    }
    return state;
  },
  partialWithdrawalSelection: (state, parentState) => {
    if (parentState.value.paymentType === 1) {
      state = validate(state, required)
    }
    return state;
  },
})

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(ResetComponentStateAction, (state, { }) => {
    return {
      ...state,
      model: null,
      form: createFormGroupState('AccessYourSuperCeasedPaidEmploymentForm', new AccessYourSuperCeasedPaidEmploymentForm()),
      savedFormData: null
    }
  }),
  on(SetTaxOptionsAction, (state, { payload }) => {
    return {
      ...state,
      taxOptions: payload
    }
  }),
  on(SavedFormDataAction, (state, { }) => {
    return {
      ...state,
      savedFormData: new Date()
    }
  })
);

const editCeasedPaidEmploymentReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateCeasedPaidEmploymentForm
)

export function accessYourSuperCeasedPaidEmploymentComponentReducer(state: any | undefined, action: Action) {
  return editCeasedPaidEmploymentReducerFormState(state, action);
}
