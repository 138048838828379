<div id="access-your-super-form" *ngIf="currentStep$|async; let currentStep">

  <ng-container *ngIf="applicationTypes$|async; let applicationTypesResponse">
    <div *ngIf="currentStep === -1">
      <p>{{applicationTypesResponse.errorMessage}}</p>
    </div>

    <div *ngIf="currentStep === 1">
      <h2 class="mb-3">Confirm your personal details</h2>
      <div class="alert alert-info" *ngIf="validationErrorList.length >0">
        <ul class="mb-0">
          <li *ngFor="let data of validationErrorList" [innerHTML]="data"></li>
        </ul>
      </div>

      <app-member-view [hideMemberNumber]="true" [hidePostalAddress]="true" [tfnTooltip]="tfnTooltipText"
        [hideTfn]="false" [hideDob]="false" [hideOccupation]="true" [greenIdValidation]="true"
        [hideCommunicationPreference]="true"></app-member-view>

      <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
        class="d-flex flex-wrap">
        <div [ngClass]="{'mt-1': screenWidth<768}">
          <app-button class="flex-fill" title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
            color="{{'BTN.access-your-super.cancel.color' | translate}}" (onClick)="exitForm()"></app-button>
        </div>
        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
          class="d-flex">
          <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
            class="flex-fill {{'BTN.access-your-super.update-details.class'|translate}}" title="Update details"
            icon="{{'BTN.access-your-super.update-details.icon' | translate}}"
            color="{{'BTN.access-your-super.update-details.color' | translate}}" (onClick)="updatePersonalDetails()">
          </app-button>
          <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill" title="Next"
            icon="{{'BTN.access-your-super.continue.icon' | translate}}" [isDisabled]="validationErrorList.length >0"
            color="{{'BTN.access-your-super.continue.color' | translate}}" (onClick)="goNextStep(2)"></app-button>
        </div>
      </div>
    </div>

    <div *ngIf="currentStep === 2">
      <app-access-your-super-type-selection [submitting]="submitting"
        (onApplicationCreated)="onApplicationCreated($event)"
        [applicationTypes]="applicationTypesResponse.applicationTypes">
      </app-access-your-super-type-selection>
    </div>
  </ng-container>

  <ng-container *ngIf="applicationModel$|async; let model">

    <div *ngIf="!model.conditionData.directToAto && showStepper"
      class="d-flex flex-row w-100 pb-1 mb-4 stepper-container">
      <app-access-your-super-stepper [stepTitles]="stepperData" [onSelectionChangeFn]="onStepperSelectionChange">
      </app-access-your-super-stepper>
    </div>

    <div *ngIf="currentStep === 3">

      <div *ngIf="model.conditionData.directToAto">
        <h2 class="mb-3">Please visit the ATO page directly</h2>
        <div [innerHtml]="model.conditionData.redirectToAtoWording"></div>
        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
          class="d-flex flex-wrap mt-4">
          <app-button [ngClass]="{'mt-1 flex-fill': screenWidth<768}" title="Exit"
            icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
            color="{{'BTN.access-your-super.cancel.color' | translate}}" (onClick)="exitForm()"></app-button>
          <app-button [ngClass]="{'flex-fill': screenWidth<768}" title="Go to ATO page"
            icon="{{'BTN.access-your-super.continue.icon' | translate}}"
            color="{{'BTN.access-your-super.continue.color' | translate}}" (onClick)="openAtoPage()"></app-button>
        </div>
      </div>

      <div *ngIf="!model.conditionData.directToAto">
        <h2 class="mb-3">Available components</h2>
        <div class="ml-3">
          <available-account-balance [data]="model.accountBalances"></available-account-balance>
          <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <app-button [ngClass]="{'mt-1 flex-fill': screenWidth<768}" title="Exit"
              icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
              color="{{'BTN.access-your-super.cancel.color' | translate}}" (onClick)="exitForm()"
              [isDisabled]="submitting"></app-button>
            <app-button [ngClass]="{'flex-fill': screenWidth<768}" title="Next"
              icon="{{'BTN.access-your-super.continue.icon' | translate}}"
              color="{{'BTN.access-your-super.continue.color' | translate}}" (onClick)="sendNextStepRequest()"
              [isDisabled]="submitting" loading>
            </app-button>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="currentStep === 4">
      <app-access-your-super-last-employer [submitting]="submitting" [data]="model.employerDetails"
        [nextStep]="nextStep" [applicationId]="applicationId$ | async" [account]="applicationAccount$ | async"
        (onLastEmployerDetailsSaved)="onLastEmployerDetailsSaved($event)" [canGoBack]="canGoBack"
        [wording]="model.customWording">
      </app-access-your-super-last-employer>
    </div>

    <div *ngIf="currentStep === 5">
      <ng-container [ngSwitch]="model.conditionData.applicationTypeId">
        <app-access-your-super-retirement [submitting]="submitting" *ngSwitchCase="1" [nextStep]="nextStep"
          [backStep]="previousStep" [applicationId]="applicationId$ | async" [account]="applicationAccount$ | async"
          [typeId]="model.conditionData.applicationTypeId" [canGoBack]="canGoBack"></app-access-your-super-retirement>
        <app-access-your-super-ceased-work-sixty [submitting]="submitting" *ngSwitchCase="2" [nextStep]="nextStep"
          [backStep]="previousStep" [applicationId]="applicationId$ | async" [account]="applicationAccount$ | async"
          [typeId]="model.conditionData.applicationTypeId" [canGoBack]="canGoBack">
        </app-access-your-super-ceased-work-sixty>
        <app-access-your-super-permanent-retirement-preservation [submitting]="submitting" *ngSwitchCase="3"
          [nextStep]="nextStep" [backStep]="previousStep" [applicationId]="applicationId$ | async"
          [account]="applicationAccount$ | async" [typeId]="model.conditionData.applicationTypeId"
          [canGoBack]="canGoBack">
        </app-access-your-super-permanent-retirement-preservation>
        <app-access-your-super-ceased-paid-employment [submitting]="submitting" *ngSwitchCase="4" [nextStep]="nextStep"
          [backStep]="previousStep" [applicationId]="applicationId$ | async" [account]="applicationAccount$ | async"
          [data]="model.accountBalances" [typeId]="model.conditionData.applicationTypeId" [canGoBack]="canGoBack">
        </app-access-your-super-ceased-paid-employment>
        <app-access-your-super-pension-commutations [submitting]="submitting" *ngSwitchCase="5" [nextStep]="nextStep"
          [backStep]="previousStep" [applicationId]="applicationId$ | async" [accountData]="accountDropdown$ | async"
          [data]="model.accountBalances" [typeId]="model.conditionData.applicationTypeId" [canGoBack]="canGoBack">
        </app-access-your-super-pension-commutations>
        <app-access-your-super-terminal-medical-condition [submitting]="submitting" *ngSwitchCase="8"
          [nextStep]="nextStep" [backStep]="previousStep" [applicationId]="applicationId$ | async"
          [account]="applicationAccount$ | async" [typeId]="model.conditionData.applicationTypeId"
          [canGoBack]="canGoBack">
        </app-access-your-super-terminal-medical-condition>
        <app-access-your-super-severe-financial-hardship [submitting]="submitting" *ngSwitchCase="10"
          [nextStep]="nextStep" [backStep]="previousStep" [applicationId]="applicationId$ | async"
          [typeId]="model.conditionData.applicationTypeId">
        </app-access-your-super-severe-financial-hardship>
        <app-access-your-super-compassionate-grounds [submitting]="submitting" *ngSwitchCase="11" [nextStep]="nextStep"
          [backStep]="previousStep" [applicationId]="applicationId$ | async" [account]="applicationAccount$ | async"
          [typeId]="model.conditionData.applicationTypeId" [canGoBack]="canGoBack">
        </app-access-your-super-compassionate-grounds>
        <app-access-your-super-unrestricted-non-preserved [submitting]="submitting" *ngSwitchCase="13"
          [nextStep]="nextStep" [backStep]="previousStep" [applicationId]="applicationId$ | async"
          [account]="applicationAccount$ | async" [data]="model.accountBalances"
          [typeId]="model.conditionData.applicationTypeId" [canGoBack]="canGoBack">
        </app-access-your-super-unrestricted-non-preserved>
        <app-access-your-super-kiwi-saver [submitting]="submitting" *ngSwitchCase="17" [nextStep]="nextStep"
          [backStep]="previousStep" [applicationId]="applicationId$ | async" [account]="applicationAccount$ | async"
          [typeId]="model.conditionData.applicationTypeId" [canGoBack]="canGoBack"></app-access-your-super-kiwi-saver>
      </ng-container>
    </div>

    <div *ngIf="currentStep === 6">
      <app-access-your-super-bank-details [submitting]="submitting" [data]="model.bankDetails" [nextStep]="nextStep"
        [backStep]="previousStep" [applicationId]="applicationId$ | async" [account]="applicationAccount$ | async"
        [wording]="model.customWording"
        (onBankDetailsSaved)="onBankDetailsSaved($event)"></app-access-your-super-bank-details>
    </div>

    <div *ngIf="currentStep === 7">
      <app-access-your-super-documents [submitting]="submitting" [data]="model.applicationDocuments"
        [nextStep]="nextStep" [backStep]="previousStep" [applicationId]="applicationId$ | async"
        [account]="applicationAccount$ | async" [conditionData]="model.conditionData"
        (onDocumentsSaved)="onDocumentsSaved($event)" (documentsInvalid)="documentsInvalid($event)"
        [wording]="model.customWording" [draftApplication]="true"
        pageTitle="Documents"></app-access-your-super-documents>
    </div>

    <div *ngIf="currentStep === 8">
      <access-your-super-review [submitting]="submitting" [data]="model" [backStep]="previousStep"
        [applicationId]="applicationId$ | async" [account]="applicationAccount$ | async"
        (onSubmissionSuccesful)="onSubmissionSuccesful($event)">
      </access-your-super-review>
    </div>

  </ng-container>
</div>