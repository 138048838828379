<ng-container *ngIf="!applicationUpdated && (applicationData$|async)">
    <h2>Review your submitted application</h2>
    <div class="review-container">
        <app-start-a-pension-review-display [data]="applicationData$|async"></app-start-a-pension-review-display>
    </div>

    <ng-container *ngIf="applicationData$|async; let applicationData">
        <app-start-a-pension-documents [submitting]="isSubmitting$|async"
            [application]="applicationData.pensionApplicationBaseDetails.pensionApplicationId"
            [account]="(selectedAccount$ | async).accountId" [data]="applicationData.pensionApplicationDocumentsDetails"
            [draftApplication]="false" componentTitle="Add additional documentation"></app-start-a-pension-documents>
    </ng-container>

    <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
        class="d-flex flex-wrap mt-4">
        <div [ngClass]="{'mt-1': screenWidth<768}">
            <app-button class="flex-fill" title="Exit" icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                [submitting]="isSubmitting$|async" color="{{'BTN.start-a-pension.cancel.color' | translate}}"
                (onClick)="leavePage()"></app-button>
        </div>
        <div>
            <app-button class="flex-fill" title="Update application"
                icon="{{'BTN.start-a-pension.continue.icon' | translate}}" [submitting]="isSubmitting$|async"
                color="{{'BTN.start-a-pension.continue.color' | translate}}" (onClick)="submit()"></app-button>
        </div>
    </div>
</ng-container>

<div *ngIf="applicationUpdated">
    <h2 class="mb-4">Your pension application request has been successfully updated</h2>
    <p class="mb-0 ml-3">Additional documents have been added to your pension application request. You will be notified
        accordingly after processing is completed or if we need any further information. </p>
    <div class="d-flex justify-content-end">
        <app-button class="mt-3 ml-1" title="Finish" icon="{{'BTN.start-a-pension.start.icon' | translate}}"
            color="{{'BTN.start-a-pension.start.color' | translate}}" routerLink="/start-a-pension">>
        </app-button>
    </div>
</div>