import { PensionPaymentScheduleModel } from 'src/app/model/pension-payment-schedule.model';
import { FormGroupState } from 'ngrx-forms';
import { KeyValueModel } from '@ifaa-components/ui-components';

export interface IPensionChangePaymentScheduleState {
  form: FormGroupState<PensionChangePaymentScheduleForm>;
  data: PensionPaymentScheduleModel;
  isAcctPension: boolean;
  filteredPaymentTypes: KeyValueModel[];
  filteredFrequencies: KeyValueModel[];
  formSubmitted: boolean;
}

export class PensionChangePaymentScheduleForm {
  paymentFrequency: number = 1;
  paymentType: number = 1;
  paymentAmount: number = 0;
  freqMin: number = 0;
  freqMax: number = 0;
}
