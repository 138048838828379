import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { StartAPensionService } from 'src/app/services/start-a-pension.service';


@Injectable()
export class StartAPensionFormComponentEffects {
    constructor(private actions$: Actions,
        private service: StartAPensionService) {
    }

    getInitialFormData$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.GetInitialFormDataRequestAction),
        switchMap((action: any) => this.service.getInitialFormData(action.accountId).pipe(
            map((data: any) => {
                return ActionsList.GetInitialFormDataResponseAction({ data: data.data })
            }),
            catchError(_err => {
                return EMPTY;
            })
        )),
    ))

    getApplicationData$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.ApplicationDataRequestAction),
        switchMap((action: any) => this.service.getApplicationData(action.accountId, action.applicationId).pipe(
            map((data: any) => {
                return ActionsList.ApplicationDataResponseAction({ applicationData: data.data })
            }),
            catchError(_err => {
                return EMPTY;
            })
        )),
    ))

    incrementCurrentStep$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.IncrementCurrentStepWithApiRequestAction),
        switchMap((action: any) => this.service.incrementCurrentStep(action.accountId, action.applicationId).pipe(
            map((_data: any) => {
                return ActionsList.IncrementCurrentStepWithApiResponseAction()
            }),
            catchError(_err => {
                return EMPTY;
            })
        )),
    ))
}
