export var FeatureToggleName = {
    member: {
        account: {
            accessYourSuper: {
                view: "Member_Account_AccessYourSuper:View",
                kiwiSaverEmployerConfirmation: {
                    view: "Member_Account_AccessYourSuper_KiwiSaverEmployerConfirmation:View"
                },
                terminalIllnesssSummaryField: {
                    view: "Member_Account_AccessYourSuper_TerminalIllnesssSummaryField:View"
                },
                benefitAmountTooltip: {
                    view: "Member_Account_AccessYourSuper_BenefitAmountTooltip:View"
                }
            },
            accountBalWithGraph: {
                view: "Member_Account_AccountBalWithGraph:View"
            },
            accountFees: {
                view: "Member_Account_ViewAccountFees:View"
            },
            spouseBPAYContribution: {
                view: "Member_Account_SpouseBPAYContribution:View"
            },
            accountBalWithoutGraph: {
                view: "Member_Account_AccountBalWithoutGraph:View"
            },
            accountSummary: {
                view: "Member_Account_AccountSummary:View"
            },
            advisors: {
                view: "Member_Account_Advisors:View"
            },
            beneficiaries: {
                view: "Member_Account_Beneficiaries:View",
                update: "Member_Account_Beneficiaries:Update"
            },
            centrelinkSchedule: {
                view: "Member_Account_CentrelinkSchedule:View"
            },
            changePensionSchedule: {
                view: "Member_Account_ChangePensionSchedule:View"
            },
            contributionCapLimits: {
                view: "Member_Account_ContributionCapLimits:View"
            },
            currentEmployer: {
                view: "Member_Account_CurrentEmployer:View"
            },
            definedBenefitAccountSummary: {
                view: "Member_Account_DefinedBenefitAccountSummary:View"
            },
            editBankDetails: {
                view: "Member_Account_EditBankDetails:View"
            },
            employerBalances: {
                view: "Member_Account_EmployerBalances:View"
            },
            feesAndCosts: {
                view: "Member_Account_FeesAndCost:View"
            },
            insuranceChangeRequest: {
                view: "Member_Account_InsuranceChangeRequest:View"
            },
            insuranceDetails: {
                view: "Member_Account_InsuranceDetails:View"
            },
            insuranceQuestionnaire: {
                view: "Member_Account_InsuranceQuestionnaire:View",
                update: "Member_Account_InsuranceQuestionnaire:Update"
            },
            intentToClaimTaxDeduction: {
                view: "Member_Account_IntentToClaimTaxDeduction:View"
            },
            JetcoClaim: {
                view: "Member_Account_JetcoClaim:View"
            },
            JetcoTraining: {
                view: "Member_Account_JetcoTraining:View"
            },
            lodgeCirtClaim: {
                view: "Member_Account_LodgeCirtClaim:View"
            },
            makeAContribution: {
                view: "Member_Account_MakeAContribution:View"
            },
            manageInvestments: {
                view: "Member_Account_ManageInvestments:View",
                update: "Member_Account_ManageInvestments:Update",
                showFees: {
                    view: "Member_Account_ManageInvestments_ShowFees:View"
                }
            },
            otherDocuments: {
                view: "Member_Account_OtherDocuments:View"
            },
            pensionDetails: {
                view: "Member_Account_PensionDetails:View"
            },
            personalContribution: {
                view: "Member_Account_PersonalContribution:View"
            },
            recentEmployerDetails: {
                view: "Member_Account_RecentEmployerDetails:View",
                recentEmployerDetailDates: {
                    view: "Member_Account_RecentEmployerDetail_Dates:View"
                },
            },
            rollIn: {
                view: "Member_Account_RollIn:View",
                update: "Member_Account_RollIn:Update"
            },
            startAPension: {
                view: 'Member_Account_StartAPension:View'
            },
            superMatch: {
                view: "Member_Account_SuperMatch:View"
            },
            takeMeWithYou: {
                view: "Member_Account_TakeMeWithYou:View"
            },
            transactions: {
                view: "Member_Account_Transactions:View"
            },
            underlyingInvestmentView: {
                view: "Member_Account_UnderlyingInvestmentView:View",
                tableDollarColumn: {
                    view: "Member_Account_UnderlyingInvestmentDollarColumnView:View",
                }
            },
            underlyingInvestmentSummary: {
                view: "Member_Account_UnderlyingInvestmentSummary:View",
            },
            viewStatements: {
                view: "Member_Account_ViewStatements:View"
            },
        },
        changePassword: {
            view: "Member_ChangePassword:View"
        },
        knowledgeAcademy: {
            view: "Member_KnowledgeAcademy:View"
        },
        maintenance: {
            view: "Member_Maintenance:View"
        },
        memberProfileSummary: {
            view: "Member_MemberProfileSummary:View"
        },
        personalDetails: {
            view: "Member_PersonalDetails:View",
            update: "Member_PersonalDetails:Update",
            beneficiaries: {
                view: "Member_PersonalDetails_Beneficiaries:View"
            },
            communicationPreference: {
                view: "Member_PersonalDetails_CommunicationPreference:View",
                update: "Member_PersonalDetails_CommunicationPreference:Update"
            },
            dob: {
                update: "Member_PersonalDetails_Dob:Update"
            },
            email: {
                update: "Member_PersonalDetails_Email:Update"
            },
            legalName: {
                view: 'Member_PersonalDetails_LegalNameToggle:View'
            },
            occupation: {
                view: "Member_PersonalDetails_Occupation:View",
                update: "Member_PersonalDetails_Occupation:Update"
            },
            tfn: {
                view: "Member_PersonalDetails_Tfn:View",
                update: "Member_PersonalDetails_Tfn:Update"
            },
            addressTraditionalPlaceName: {
                update: "Member_PersonalDetails_AddressTraditionalPlaceName:Update"
            },
        },
        superCalculator: {
            view: "Member_SuperCalculator:View"
        },
        twilioChat: {
            view: "Member_TwilioChat:View"
        },
        unitPriceListing: {
            view: "Member_UnitPriceListing:View"
        },
    },
}
