<div *ngIf="model">
    <h2 class="mb-3">{{title}}</h2>
    <div *ngIf="form$|async; let form">

        <div class="px-3 pb-2">
            <div class="mb-4">
                <div>
                    <h3 class="font-weight-bold mb-1">Proof of identification</h3>
                    <p *ngIf="!form.value.greenIdVerified" [innerHtml]="formData.customWording.identityInfo"></p>
                </div>
                <app-greenid [accountid]="accountId" (onVerified)="onVerified($event)"
                    *ngIf="!(elevatedAccess$ | async)"></app-greenid>
            </div>

            <form [ngrxFormState]="form"
                *ngIf="applicationInDraft || (!applicationInDraft && form.value.greenIdVerified)">
                <div *ngFor="let item of form?.controls.supportingDocuments.controls; let i = index; trackBy: trackByFn"
                    class="mb-3">
                    <div class="mt-2 mb-2">
                        <h3 class="font-weight-bold mb-1">{{model[i].documentName}}
                            <span *ngIf="applicationInDraft">({{model[i].isDocumentRequired ? 'Required' :
                                'Optional'}}{{model[i].documentAmountRequired > 1 ? ', ' +
                                model[i].documentAmountRequired :null}})</span>
                        </h3>
                        <div [innerHtml]="model[i].documentDescription"></div>
                        <div class="d-flex flex-row flex-wrap"
                            [ngClass]="{'': screenWidth>767, 'justify-content-center': screenWidth<768}">
                            <div *ngFor="let file of item.controls.files.controls; let j = index; trackBy: trackByFn"
                                class="mr-2 mt-2">
                                <app-entry-file [extension]="extensions" [file]="file" [canDelete]="!isSubmitting"
                                    [canUploadSameFile]="true" [dynamicUploading]="true"
                                    (onValueChange)="uploadDocument($event, model[i].documentTypeId, i, j, file.value.filename, file.id)"
                                    (onRemoveFile)="removeFile($event, i, j)"></app-entry-file>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="applicationInDraft"
            [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                    [isDisabled]="isSubmitting" color="{{'BTN.start-a-pension.cancel.color' | translate}}"
                    (onClick)="exit()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.start-a-pension.back.class' | translate}}" title="Back"
                    icon="{{'BTN.start-a-pension.back.icon' | translate}}" [isDisabled]="isSubmitting"
                    color="{{'BTN.start-a-pension.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                    color="{{'BTN.start-a-pension.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || isSubmitting" loading (onClick)="goNextStep()"></app-button>
            </div>
        </div>
    </div>
</div>