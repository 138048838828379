import { environment } from 'src/environments/environment';
import { IsFetchingAction, IsLoadingAction, IsSendingAction, SetSiteError } from './../store/common/common.actions';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from '../../../node_modules/rxjs/operators';
import { tap } from 'rxjs/operators';
import { finalize } from "rxjs/operators";
import { MsalService } from '@azure/msal-angular';
import { CoreApiHelper } from '../helper/coreApiHelper';

@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  private coreApiHelper = new CoreApiHelper;

  isLoading = false;
  isFetching = false;
  isSending = false;

  constructor(private store: Store<any>,
    public toastr: ToastrService,
    private authService: MsalService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  showError(errors) {
    const toastr = this.toastr;
    errors.forEach(function (element) {
      toastr.error(element);
    });
  }

  removeRequest(req: HttpRequest<any>) {
    this.requests = this.requests.filter(x => x.method === req.method).filter(x => x.url !== req.url);
    var hasValue = this.requests.length > 0;

    // we want the page to get a loading indicator when fetching resources
    if (req.method === "GET" && this.isFetching != hasValue) {
      this.isFetching = hasValue;
      setTimeout(() => {
        this.store.dispatch(IsFetchingAction({ payload: this.isFetching }));
      }, 500);
    }

    // we want buttons to show loading indicators is sending data to API
    if (req.method !== "GET" && this.isSending != hasValue) {
      this.isSending = hasValue;
      setTimeout(() => {
        this.store.dispatch(IsSendingAction({ payload: this.isSending }));
      }, 0);
    }

    if (this.isLoading != hasValue) {
      this.isLoading = hasValue;
      setTimeout(() => {
        this.store.dispatch(IsLoadingAction({ payload: this.isLoading }));
      }, 0);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.endsWith('.json') && request.url.startsWith(environment.apiUrl)) {
      var exist = this.requests.filter(x => {
        return x.url == request.url
      });
      if (exist.length == 0) {
        this.requests.push(request);

        // we want the page to get a loading indicator when fetching resources
        if (request.method === "GET" && !this.isFetching) {
          this.isFetching = true;
          setTimeout(() => {
            this.store.dispatch(IsFetchingAction({ payload: true }));
          }, 0);
        }

        // we want buttons to show loading indicators is sending data to API
        if (request.method !== "GET" && !this.isSending) {
          this.isSending = true;
          setTimeout(() => {
            this.store.dispatch(IsSendingAction({ payload: true }));
          }, 0);
        }

        if (!this.isLoading) {
          this.isLoading = true;
          setTimeout(() => {
            this.store.dispatch(IsLoadingAction({ payload: true }));
          }, 0);

        }
      }
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
      }),

      finalize(() => {
        this.removeRequest(request);
      }),
      catchError((error, caught) => {
        this.removeRequest(request);

        if (error.error && error.error.message) {
          this.showError([error.error.message]);
        }

        var result = this.coreApiHelper.checkUrl(error.url);

        if (result)
          this.store.dispatch(SetSiteError({ error: true }))

        return throwError(error);
      }) as any);
  }
}
