<div>
    <h2 class="mb-3">Payment instructions</h2>
    <div *ngIf="form$|async; let form">
        <form [ngrxFormState]="form" class="ml-3 mr-3">
            <div class="mb-3"
                *ngIf="form.value.financialInstitutionName && form.value.financialInstitutionName.length>0">
                <app-readonly title="Name of financial institution" [value]="form.value.financialInstitutionName"
                    class="w-50 flex-fill"></app-readonly>
            </div>
            <div class="d-flex flex-column pl-1 pr-3">
                <div class="title d-flex flex-row align-items-center">Names(s) on account:
                    <mat-icon class="ml-2 tooltip-readonly" [matTooltip]="nameFieldTooltip">help_outline</mat-icon>
                </div>
                <app-entry title="" [control]="form.controls.bankAccountName"></app-entry>
            </div>
            <div class="d-flex flex-row flex-wrap">
                <div class="pl-1 col-12 col-md-6">
                    <div class="title d-flex flex-row align-items-center">BSB:</div>
                    <app-entry title="" [control]="form.controls.bankAccountBsb"></app-entry>
                </div>
                <div class="pl-1 col-12 col-md-6">
                    <div class="title d-flex flex-row align-items-center">Account Number:</div>
                    <app-entry title="" [control]="form.controls.bankAccountNumber"></app-entry>
                </div>
            </div>
        </form>

        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                    [isDisabled]="isSubmitting" color="{{'BTN.access-your-super.cancel.color' | translate}}"
                    (onClick)="exitForm()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.access-your-super.back.class' | translate}}" title="Back"
                    icon="{{'BTN.access-your-super.back.icon' | translate}}" [isDisabled]="isSubmitting"
                    color="{{'BTN.access-your-super.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                    color="{{'BTN.access-your-super.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || isSubmitting" loading (onClick)="goNextStep()"></app-button>
            </div>
        </div>
    </div>
</div>