import { FormGroupState } from "ngrx-forms";
import { StartAPensionFormModel } from "src/app/model/start-a-pension-form.model";

export interface IStartAPensionReviewComponentState {
    form: FormGroupState<StartAPensionReviewForm>;
    applicationSubmitted: boolean;
}

export class StartAPensionReviewForm {
    disclaimer: boolean = false;
}