import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Helper } from "@ifaa-components/ui-components";
import { select, Store } from "@ngrx/store";
import { combineLatest } from "rxjs";
import { distinctUntilChanged, map, pairwise } from "rxjs/operators";
import { AccessYourSuperApplicationType, AccessYourSuperApplicationTypesListModel } from "src/app/model/access-your-super-form.models";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import { SetCurrentStepAction } from "../access-your-super-form/actions";
import { memberAccountDropdown_SelectedAccount } from "../member-account-dropdown/selectors";
import {
    GetApplicationDataAction, ResetComponentStateAction, ResetSecondDropdownValueAction, SelectApplicationTypeAction, SetFirstDropdownValuesAction,
    SetSecondDropdownValuesAction
} from "./actions";
import { accessYourSuperTypeSelection_Form, accessYourSuperTypeSelection_FirstDropdownValues, accessYourSuperTypeSelection_SecondDropdownValues, accessYourSuperTypeSelection_ApplicationId, accessYourSuperTypeSelection_ApplicationModel } from "./selector";

@Component({
    selector: 'app-access-your-super-type-selection',
    templateUrl: './access-your-super-type-selection.component.html',
    styleUrls: ['./access-your-super-type-selection.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class AccessYourSuperTypeSelectionComponent extends ComponentBase implements OnInit, OnDestroy {

    @Output() onApplicationCreated: EventEmitter<any> = new EventEmitter();
    @Input() set applicationTypes(value: AccessYourSuperApplicationTypesListModel) {
        this.model = value;
    }
    @Input() set submitting(value: boolean) {
        this.isSubmitting = value;
    }

    selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
    form$ = this.store.pipe(select(accessYourSuperTypeSelection_Form));
    firstDropdownValues$ = this.store.pipe(select(accessYourSuperTypeSelection_FirstDropdownValues));
    secondDropdownValues$ = this.store.pipe(select(accessYourSuperTypeSelection_SecondDropdownValues));

    applicationId$ = this.store.pipe(select(accessYourSuperTypeSelection_ApplicationId));
    applicationModel$ = this.store.pipe(select(accessYourSuperTypeSelection_ApplicationModel));


    model: AccessYourSuperApplicationTypesListModel = {};
    selectedType: AccessYourSuperApplicationType = null;
    isSubmitting: boolean = false;
    dialogText = "Please confirm that the condition of release selected is the one you would like to proceed with. You will not be able to change the condition of release after this step."

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();

        const categoryArray = [];
        Object.keys(this.model).forEach(category => {
            var newEntry = { key: category, value: category };
            categoryArray.push(newEntry);
        })

        this.dispatch(this.store, SetFirstDropdownValuesAction({ payload: categoryArray }));

        this.sub = this.form$
            .pipe(
                distinctUntilChanged((x, y) => {
                    if (x.value.firstDropdown === null) {
                        return false
                    }
                    if (x.value.firstDropdown !== y.value.firstDropdown ||
                        x.value.secondDropdown !== y.value.secondDropdown) {
                        return false;
                    }
                    return true;
                }),
                pairwise(),
                map(([previousValue, newValue]) => ({ previousValue, newValue }))
            )
            .subscribe(x => {
                // Fills the second dropdown when first dropdown value changes
                if (x.previousValue.value.firstDropdown !== x.newValue.value.firstDropdown) {
                    let typeArray = [];

                    this.model[x.newValue.value.firstDropdown]?.forEach(type => {
                        var newEntry = { key: type.conditionName, value: type.conditionName };
                        typeArray.push(newEntry);
                    })

                    this.dispatch(this.store, SetSecondDropdownValuesAction({ payload: typeArray }));
                    this.dispatch(this.store, ResetSecondDropdownValueAction())
                }

                // Sets the selected type when second dropdown is updated
                if (x.previousValue.value.secondDropdown !== x.newValue.value.secondDropdown) {
                    var categoryList = this.model[x.newValue.value.firstDropdown];
                    var selectedType = categoryList?.find(o => o.conditionName === x.newValue.value.secondDropdown);
                    this.selectedType = selectedType;
                }
            }
            );
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction());
    }

    async goNextStep(event: string) {
        if (event === 'ok') {
            var helper = new Helper();
            var accountId = await helper.getValue(this.selectedAccount$)

            this.sub = combineLatest([this.applicationId$, this.applicationModel$])
                .pipe(
                    distinctUntilChanged((x, y) => {
                        if (x[0] !== y[0] || x[1] !== y[1])
                            return false;
                        return true;
                    }),
                    map(([applicationId, applicationModel]) => ({ applicationId, applicationModel }))
                )
                .subscribe(x => {
                    // Submit api request to create new application
                    if (!x.applicationId) {
                        this.dispatch(this.store, SelectApplicationTypeAction({ accountId: accountId?.accountId, applicationType: this.selectedType.applicationTypeId }));
                    }

                    // Submit api request to get data for application
                    if (x.applicationId && !x.applicationModel) {
                        this.dispatch(this.store, GetApplicationDataAction({ accountId: accountId?.accountId, applicationType: this.selectedType.applicationTypeId }));
                    }

                    // Go next step once api requests are complete
                    if (x.applicationId && x.applicationModel) {
                        this.onApplicationCreated?.emit({
                            id: x.applicationId,
                            model: x.applicationModel
                        })

                        var nextStep = 3;
                        if (!x.applicationModel.conditionData.directToAto) {
                            nextStep = x.applicationModel.conditionData.requiredSections[0]
                        }
                        this.dispatch(this.store, SetCurrentStepAction({ nextStep: nextStep }))
                    }
                })
        }
    }

    exitForm() {
        this.router.navigate(['/access-your-super']);
    }
}
