import { FormGroupState } from 'ngrx-forms';
import { StartAPensionApplicationSummariesModel } from 'src/app/model/start-a-pension-application-summaries.model';

export interface IStartAPensionStartComponentState {
  model: StartAPensionApplicationSummariesModel;
  filter: FormGroupState<StartAPensionFilterModel>;
  processStarted: boolean;
  updatingSubmitted: boolean;
}

export class StartAPensionFilterModel {
  activeOptionButton: string = "Draft";
}