import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { ResetComponentStateAction, SetEligibilityFormDataAction, SubmitFormAction } from './actions';
import { StartAPensionInitialFormModel } from 'src/app/model/start-a-pension-form.model';
import { startAPensionEligibilityForm_Conditions, startAPensionEligibilityForm_Form, startAPensionEligibilityForm_ShowConditionOfReleaseNote, startAPensionEligibilityForm_TypesOfAccounts } from './selectors';
import { environment } from 'src/environments/environment';
import { Helper } from '@ifaa-components/ui-components';

@Component({
    selector: 'app-start-a-pension-eligibility',
    templateUrl: './start-a-pension-eligibility-form.component.html',
    styleUrls: ['./start-a-pension-eligibility-form.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class StartAPensionEligibilityFormComponent extends ComponentBase implements OnInit, OnDestroy {
    @Input() set data(value: StartAPensionInitialFormModel) {
        this.formData = value;
        this.store.dispatch(SetEligibilityFormDataAction({ data: value }))
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set submitting(value: boolean) {
        this.isSubmitting = value;
    }

    form$ = this.store.pipe(select(startAPensionEligibilityForm_Form));
    typesOfAccounts$ = this.store.pipe(select(startAPensionEligibilityForm_TypesOfAccounts));
    conditions$ = this.store.pipe(select(startAPensionEligibilityForm_Conditions));
    showNote$ = this.store.pipe(select(startAPensionEligibilityForm_ShowConditionOfReleaseNote));

    showCitizenQuestion: boolean = environment.startAPension.eligibilitySection.showCitizenQuestion;
    formData: StartAPensionInitialFormModel = null;
    accountId: number = null;
    helper = new Helper();
    isSubmitting: boolean = false;

    constructor(
        public store: Store<AppState>,
        private router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction())
    }

    exitForm() {
        this.router.navigate(['/start-a-pension'])
        this.dispatch(this.store, ResetComponentStateAction())
    }

    async createApplication() {
        var data = await this.helper.getValue(this.form$);
        this.store.dispatch(SubmitFormAction({
            accountId: this.accountId, conditionId: data.value.sapConditionOfRelease, submission: {
                otherReasonText: data.value.otherReasonText && data.value.otherReasonText.length > 0 ? data.value.otherReasonText : null
            }
        }))
    }
}
