import { insuranceDetails_Model } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { Router } from '@angular/router';
import { insuranceUpdate_Model } from '../insurance-update/selectors';
import { SetInsuranceDetailsAction } from '../insurance-update/actions';
import { Helper } from '@ifaa-components/ui-components';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';

@Component({
  selector: 'app-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['./insurance-details.component.scss']
})
export class InsuranceDetailsComponent extends ComponentBase implements OnInit, OnDestroy {

  model$ = this.store.pipe(select(insuranceDetails_Model));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  insuranceUpdateModel$ = this.store.pipe(select(insuranceUpdate_Model));

  insuranceUpdateToggle = FeatureToggleName.member.account.insuranceChangeRequest.view;

  helper: Helper = new Helper();

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    public router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    });

    this.sub = this.insuranceUpdateModel$.subscribe(x => {
      if (x) {
        this.router.navigate(['/insurance/update'])
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  async updateOrCancelInsurance() {
    var model = await this.helper.getValue(this.model$);
    this.store.dispatch(SetInsuranceDetailsAction({ insurance: model }))
  }

  viewHistory() {
    this.router.navigate(['/insurance/change-history'])
  }
}
