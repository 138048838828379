import { AddressModel, KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { PensionApplicationBeneficiaryFormModel } from "src/app/model/start-a-pension-form.model";

export interface IStartAPensionBeneficiaryFormComponentState {
    formData: PensionApplicationBeneficiaryFormModel;
    form: FormGroupState<StartAPensionBeneficiaryForm>;
    relationshipTypes: KeyValueModel[];
    genderTypes: KeyValueModel[];
}

export class StartAPensionBeneficiaryForm {
    beneficiaryAdded: boolean = false;
    name: string = "";
    dob: string = "";
    relationshipId: number = 1;
    genderId: number = 1;
    isAddressSame: boolean = true;
    address: AddressModel = {
        lineOne: '',
        lineTwo: '',
        suburb: '',
        postCode: '',
        state: '',
        country: 'Australia',
        isRequired: true
    } as AddressModel;
    memberPostalAddress: AddressModel = new AddressModel();
}