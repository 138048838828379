import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from 'src/app/modules/shared/store/shared.reducers';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { IStartAPensionBankDetailsFormComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionBankDetailsFormState = createSelector(memberPortalState, (state) => state.startAPensionBankDetailsFormComponentState);

export const startAPensionBankDetailsForm_Form = createSelector(
    getStartAPensionBankDetailsFormState,
    (state: IStartAPensionBankDetailsFormComponentState) => state.form
);