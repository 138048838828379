import { insuranceQuestionnaire_ElegibilityDate, insuranceQuestionnaire_ShowQuestionnaire, insuranceQuestionnaire_Form } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';


@Component({
  selector: 'app-insurance-questionnaire',
  templateUrl: './insurance-questionnaire.component.html',
  styleUrls: ['./insurance-questionnaire.component.scss']
})
export class InsuranceQuestionnaireComponent extends ComponentBase implements OnInit, OnDestroy {

  showQuestionnaire$ = this.store.pipe(select(insuranceQuestionnaire_ShowQuestionnaire));
  tenantConfig$ = this.store.pipe(select(commomState_SystemConfig_Config));
  elegibilityDates$ = this.store.pipe(select(insuranceQuestionnaire_ElegibilityDate));
  form$ = this.store.pipe(select(insuranceQuestionnaire_Form));

  constructor(public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onStartClick() {
    this.router.navigate(['start-questionnaire']);
  }

}
