import { IInsuranceUpdateComponentState, InitialInsuranceSelectionForm, InsuranceUpdateReduceForm, InsuranceUpdateCancelForm, SelectionForm, InsuranceReduceForm, CoverTypeUpdateForm, InsuranceCancelForm } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
    createFormGroupState,
    onNgrxForms, onNgrxFormsAction, setValue, SetValueAction,
    updateGroup,
    validate,
    wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { ResetStateAction, SetInsuranceDetailsAction, SetInsuranceDetailsToCancelAction, SetInsuranceDetailsToReduceAction, SubmissionErrorAction, SubmissionSuccessAction, SubmitInsuranceToCancelAction, SubmitInsuranceToReduceAction } from './actions';
import { requiredTrue } from 'ngrx-forms/validation';

const initialFormName = "InitialInsuranceUpdateForm";
const reduceFormName = "InsuranceUpdateReduceForm";
const cancelFormName = "InsuranceUpdateCancelForm";

export const initialState: IInsuranceUpdateComponentState = {
    initialForm: createFormGroupState(initialFormName, new InitialInsuranceSelectionForm()),
    reduceForm: createFormGroupState(reduceFormName, new InsuranceUpdateReduceForm()),
    cancelForm: createFormGroupState(cancelFormName, new InsuranceUpdateCancelForm()),
    model: null,
    submitted: false,
    insuranceToReduce: [],
    insuranceToCancel: []
};

export const validateInsuranceReduceForm = updateGroup<InsuranceUpdateReduceForm>({
    checkbox: validate(requiredTrue)
})

export const validateInsuranceCancelForm = updateGroup<InsuranceUpdateCancelForm>({
    checkbox: validate(requiredTrue)
})

const reducer = createReducer(initialState,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        return state;
    }),
    on(ResetStateAction, (_state) => initialState),
    on(SetInsuranceDetailsAction, (state, { insurance }) => {
        return {
            ...state,
            model: insurance,
            initialForm: setValue(Object.assign(new InitialInsuranceSelectionForm(),
                {
                    ...state.initialForm.value,
                    insuranceItems: insurance.insurances.map((insurance) => ({
                        insuranceId: insurance.insuranceId,
                        description: insurance.insuranceCoverTypeDescription,
                        amount: insurance.amount,
                        checkbox: false
                    } as SelectionForm))
                }
            ))(state.initialForm)
        }
    }),
    on(SetInsuranceDetailsToCancelAction, (state, { insurances }) => {
        return {
            ...state,
            insuranceToCancel: insurances,
            cancelForm: setValue(Object.assign(new InsuranceUpdateCancelForm(),
                {
                    checkbox: false,
                    insurance: insurances.map((insurance) => ({
                        insuranceId: insurance.insuranceId,
                        description: insurance.insuranceCoverTypeDescription,
                        amount: insurance.amount,
                        code: insurance.insuranceCoverTypeCode,
                        covers: insurance.coverTypeDetails.map(coverTypeDetail => ({
                            insuranceCoverTypeDetailId: coverTypeDetail.insuranceCoverTypeDetailId,
                            coverType: coverTypeDetail.name,
                            checkbox: true
                        }))
                    })) ?? []
                }
            ))(state.cancelForm)
        }
    }),
    on(SetInsuranceDetailsToReduceAction, (state, { insurances }) => {
        return {
            ...state,
            insuranceToReduce: insurances,
            reduceForm: setValue(Object.assign(new InsuranceUpdateReduceForm(),
                {
                    checkbox: false,
                    insurance: insurances.map((insurance) => ({
                        insuranceId: insurance.insuranceId,
                        description: insurance.insuranceCoverTypeDescription,
                        amount: insurance.amount,
                        code: insurance.insuranceCoverTypeCode,
                        covers: insurance.coverTypeDetails.map(coverTypeDetail => ({
                            insuranceCoverTypeDetailId: coverTypeDetail.insuranceCoverTypeDetailId,
                            coverType: coverTypeDetail.name,
                            newAmount: 0
                        }))
                    } as InsuranceReduceForm)) ?? []
                }
            ))(state.reduceForm)
        }
    }),
    on(SubmissionSuccessAction, (state) => {
        return {
            ...state,
            submitted: true
        }
    }),
);

const editInsuranceUpdateFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.reduceForm,
    validateInsuranceReduceForm
)

const editInsuranceUpdateSubmissionFormState = wrapReducerWithFormStateUpdate(
    editInsuranceUpdateFormState,
    s => s.cancelForm,
    validateInsuranceCancelForm
)

export function insuranceUpdateFormComponentReducer(state: any | undefined, action: Action) {
    return editInsuranceUpdateSubmissionFormState(state, action);
}
