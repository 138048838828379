<div id="insurance-update" *ngIf="(model$ | async); let data">

    <ng-container *ngIf="!(submitted$|async)">

        <!-- initial form -->
        <ng-container *ngIf="!(insuranceToReduce$|async).length > 0 && !(insuranceToCancel$|async).length > 0">
            <ng-container *ngIf="initialForm$|async; let initialForm">

                <form [ngrxFormState]="initialForm" class="ml-3 mr-3">
                    <ng-container *ngFor="let item of initialForm.controls.insuranceItems.controls">
                        <app-checkbox
                            title="{{item.value.description}} (Cover Amount - {{item.value.amount | currency}})"
                            [control]="item.controls.checkbox"></app-checkbox>
                    </ng-container>
                </form>

                <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                    class="d-flex flex-wrap mt-4">
                    <div [ngClass]="{'mt-1': screenWidth<768}">
                        <app-button class="flex-fill" title="Exit"
                            icon="{{'BTN.insurance-update.cancel.icon' | translate}}"
                            color="{{'BTN.insurance-update.cancel.color' | translate}}"
                            (onClick)="exitForm()"></app-button>
                    </div>
                    <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                        class="d-flex">
                        <app-button title="Reduce cover" icon="{{'BTN.insurance-update.reduce-cover.icon' | translate}}"
                            [ngClass]="{'mt-1': screenWidth<768, 'mr-1': screenWidth > 767}"
                            color="{{'BTN.insurance-update.reduce-cover.color' | translate}}"
                            [isDisabled]="insuranceNotSelected(initialForm.controls.insuranceItems.value)"
                            (onClick)="reduceCover(initialForm.controls.insuranceItems.value)"></app-button>
                        <app-button title="Cancel cover" icon="{{'BTN.insurance-update.cancel-cover.icon' | translate}}"
                            color="{{'BTN.insurance-update.cancel-cover.color' | translate}}"
                            [isDisabled]="insuranceNotSelected(initialForm.controls.insuranceItems.value)"
                            (onClick)="cancelCover(initialForm.controls.insuranceItems.value)"></app-button>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <!-- insurance to reduce form -->
        <ng-container *ngIf="(insuranceToReduce$|async).length > 0">
            <div *ngIf="(insuranceToReduce$|async); let insuranceToReduce">
                <ng-container *ngIf="reduceForm$|async; let reduceForm">

                    <form [ngrxFormState]="reduceForm" class="ml-3 mr-3">
                        <p>You have selected the following insurance covers to be reduced:</p>

                        <div class="insurance-control mt-3"
                            *ngFor="let insurance of reduceForm.controls.insurance.controls; let i = index; trackBy: trackByFn">
                            <h3>{{insurance.value.description}} (Cover Amount - {{insurance.value.amount |currency}})
                            </h3>
                            <div class="d-flex amount-control"
                                *ngFor="let cover of insurance.controls.covers.controls; let i = index; trackBy: trackByFn">
                                <p class="cover-type">{{cover.value.coverType}}
                                </p>
                                <app-entry-number class="flex-fill" title="New cover amount"
                                    [control]="cover.controls.newAmount" icon="attach_money" [decimalplaces]="0"
                                    [showincremental]="false">
                                </app-entry-number>
                            </div>
                        </div>

                        <p class="mt-4">Please confirm - </p>
                        <ul>
                            <li>I understand that reduction of cover will be effective from the date my instruction is
                                received.
                            </li>
                            <li>If I hold Standard Cover and have elected to reduce my cover amount (sum insured), my
                                remaining cover will be changed to Fixed Cover.</li>
                            <li>I understand that my premiums will be adjusted in accordance with the requested change.
                            </li>
                            <li>I understand that if I reduce my insurance cover(s), any applications for additional
                                cover
                                in the future will be subject to insurer approval and will need a complete health
                                statement.
                            </li>
                            <li>Before making an election to reduce my cover, I have considered my financial security
                                and insurance needs carefully.</li>
                        </ul>
                        <app-checkbox title="I have read and understood the above disclaimer."
                            [control]="reduceForm.controls.checkbox"></app-checkbox>
                    </form>
                    <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                        class="d-flex flex-wrap mt-4">
                        <div [ngClass]="{'mt-1': screenWidth<768}">
                            <app-button class="flex-fill" title="Exit"
                                icon="{{'BTN.insurance-update.cancel.icon' | translate}}"
                                color="{{'BTN.insurance-update.cancel.color' | translate}}"
                                [isDisabled]="(submitting$|async)" (onClick)="exitForm()"></app-button>
                        </div>
                        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                            class="d-flex">
                            <app-button title="Back" icon="{{'BTN.insurance-update.back.icon' | translate}}"
                                [ngClass]="{'mt-1': screenWidth<768, 'mr-1': screenWidth > 767}"
                                color="{{'BTN.insurance-update.back.color' | translate}}"
                                [isDisabled]="(submitting$|async)" (onClick)="cancelReducingInsurance()"></app-button>
                            <app-button title="Submit" icon="{{'BTN.insurance-update.continue.icon' | translate}}"
                                color="{{'BTN.insurance-update.continue.color' | translate}}"
                                [isDisabled]="reduceForm.isInvalid || (submitting$|async)" loading
                                (onClick)="submitInsuranceToReduce(reduceForm.value.insurance)"></app-button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <!-- insurance to cancel form -->
        <ng-container *ngIf="(insuranceToCancel$|async).length > 0">
            <div *ngIf="(insuranceToCancel$|async); let insuranceToCancel">
                <ng-container *ngIf="cancelForm$|async; let cancelForm">

                    <form [ngrxFormState]="cancelForm" class="ml-3 mr-3">
                        <p>Please confirm that you would like the following insurance covers to be cancelled:</p>
                        <div class="insurance-control"
                            *ngFor="let insurance of cancelForm.controls.insurance.controls; let i = index; trackBy: trackByFn">
                            <h3 class="cover-type d-flex flex-row align-items-center">{{insurance.value.description}}
                                (Cover
                                Amount - {{insurance.value.amount |
                                currency}})
                                <mat-icon class="ml-2 mb-1 tooltip-readonly" *ngIf="insurance.value.code === 'B'"
                                    matTooltip="If you would like to cancel your TPD
                                    cover only and retain your Death Cover, please uncheck Death Cover here">help_outline</mat-icon>
                            </h3>
                            <ng-container *ngIf="insurance.value.code === 'B'">
                                <div class="d-flex amount-control"
                                    *ngFor="let cover of insurance.controls.covers.controls; let i = index; trackBy: trackByFn">
                                    <app-checkbox [title]="cover.value.coverType"
                                        [control]="cover.controls.checkbox"></app-checkbox>
                                </div>
                            </ng-container>
                        </div>
                        <p class="mt-4">Please confirm - </p>
                        <ul>
                            <li>I understand that cancellation of cover will be effective from the date my instruction
                                is
                                received.
                            </li>
                            <li>I understand that if I elect to cancel my standard cover within 30 days of my cover
                                commencing, any
                                premiums paid for that cover will be refunded to my account.</li>
                            <li>I understand that if I cancel my insurance cover(s), any applications for cover in the
                                future will
                                be subject to insurer approval and will need a complete health statement.</li>
                            <li>Before making an election to cancel my cover, I have considered my financial security
                                and insurance needs carefully.</li>
                        </ul>
                        <app-checkbox title="I have read and understood the above disclaimer."
                            [control]="cancelForm.controls.checkbox"></app-checkbox>
                    </form>
                    <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                        class="d-flex flex-wrap mt-4">
                        <div [ngClass]="{'mt-1': screenWidth<768}">
                            <app-button class="flex-fill" title="Exit"
                                icon="{{'BTN.insurance-update.cancel.icon' | translate}}"
                                color="{{'BTN.insurance-update.cancel.color' | translate}}"
                                [isDisabled]="(submitting$|async)" (onClick)="exitForm()"></app-button>
                        </div>
                        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                            class="d-flex">
                            <app-button title="Back" icon="{{'BTN.insurance-update.back.icon' | translate}}"
                                [ngClass]="{'mt-1': screenWidth<768, 'mr-1': screenWidth > 767}"
                                color="{{'BTN.insurance-update.back.color' | translate}}"
                                [isDisabled]="(submitting$|async)" (onClick)="cancelCancellingInsurance()"></app-button>
                            <app-button title="Submit" icon="{{'BTN.insurance-update.continue.icon' | translate}}"
                                color="{{'BTN.insurance-update.continue.color' | translate}}"
                                [isDisabled]="cancelForm.isInvalid || (submitting$|async)" loading
                                (onClick)="submitInsuranceToCancel(cancelForm.value.insurance)"></app-button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

    </ng-container>

    <div *ngIf="(submitted$|async)">
        <h2 class="mb-4">Your insurance change request has been successfully submitted.</h2>
        <p class="mb-0 ml-3">Your request will be reviewed, and you will receive written confirmation when your
            insurance cover is {{(insuranceToReduce$|async).length > 0 ? 'reduced' : 'ceased'}}. Requested changes to
            your insurance cover may not be reflected in the Member portal straight away.</p>
        <div class="d-flex justify-content-end">
            <app-button class="mt-3 ml-1" title="Finish" icon="{{'BTN.insurance-update.continue.icon' | translate}}"
                color="{{'BTN.insurance-update.continue.color' | translate}}" routerLink="/insurance">>
            </app-button>
        </div>
    </div>
</div>