import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { PensionApplicationAccountDetailsFormModel, PensionInvestmentOptionModel } from "src/app/model/start-a-pension-form.model";

export interface IStartAPensionAccountDetailsFormComponentState {
    formData: PensionApplicationAccountDetailsFormModel;
    form: FormGroupState<StartAPensionAccountDetailsForm>;
    transferTypes: KeyValueModel[];
    investmentOptions: PensionInvestmentOptionModel[];
}

export class StartAPensionAccountDetailsForm {
    transferType: number = 1;
    partialAmount: number = 0;
    wording: { [key: string]: string };
    accountBalance: number = 0;
    options: PensionInvestmentOptionModel[];
}