<div class="mb-4">
    <h3 class="mb-0">Pension account details</h3>
    <div *ngIf="formData.pensionApplicationAccountDetails" class="d-flex flex-wrap">
        <app-readonly [title]="formData.pensionApplicationAccountDetails.customWording.accountInfo"
            [value]="formData.pensionApplicationAccountDetails.accumulationAccountInfo"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly [title]="formData.pensionApplicationAccountDetails.customWording.transferAmount"
            [value]="formData.pensionApplicationAccountDetails.transferAmountTypes[formData.pensionApplicationAccountDetails.savedData.transferTypeId-1].value"
            class="w-50 p-1 flex-fill"></app-readonly>
        <ng-container *ngIf="formData.pensionApplicationAccountDetails.savedData.transferTypeId===3">
            <app-readonly title="Partial balance amount"
                [value]="formData.pensionApplicationAccountDetails.savedData.transferAmount | currency"
                class="w-50 p-1 flex-fill"></app-readonly>
        </ng-container>
    </div>
</div>

<div class="mb-4">
    <h3 class="mb-0">Current investment options allocation</h3>
    <div class="d-flex flex-wrap">
        <ng-container *ngFor="let option of formData.pensionApplicationAccountDetails.savedData.currentOptions">
            <app-readonly [title]="option.investmentOptionName" [value]="option.percentage + '%'"
                class="w-50 p-1 flex-fill"></app-readonly>
        </ng-container>
    </div>
</div>

<div class="mb-4">
    <h3 class="mb-0">Pension income payment details</h3>
    <div *ngIf="formData.pensionApplicationAccountDetails" class="d-flex flex-wrap">
        <app-readonly [title]="formData.pensionApplicationPaymentDetails.customWording.paymentFrequencyQuestion"
            [value]="formData.pensionApplicationPaymentDetails.paymentFrequencyTypes[formData.pensionApplicationPaymentDetails.savedData.frequencyId-1].value"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly [title]="formData.pensionApplicationPaymentDetails.customWording.commencementOfPaymentQuestion"
            [value]="!formData.pensionApplicationPaymentDetails.savedData.paymentCommencementDate ? 'First available payment date after receipt of your investment': (formData.pensionApplicationPaymentDetails.savedData.paymentCommencementDate | date:'MMMM yyyy')"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly [title]="formData.pensionApplicationPaymentDetails.customWording.paymentTypeQuestion"
            [value]="formData.pensionApplicationPaymentDetails.paymentTypeTypes[formData.pensionApplicationPaymentDetails.savedData.pensionPaymentTypeId-1].value"
            class="w-50 p-1 flex-fill"></app-readonly>
        <ng-container *ngIf="formData.pensionApplicationPaymentDetails.savedData.pensionPaymentTypeId === 1">
            <app-readonly [title]="formData.pensionApplicationPaymentDetails.customWording.paymentAmountQuestion"
                [value]="formData.pensionApplicationPaymentDetails.savedData.paymentAmount | currency"
                class="w-50 p-1 flex-fill"></app-readonly>
        </ng-container>
    </div>
</div>


<div class="mb-4">
    <h3 class="mb-0">Future pension payment options allocation</h3>
    <div class="d-flex flex-wrap">
        <ng-container *ngFor="let option of formData.pensionApplicationPaymentDetails.savedData.futureOptions">
            <app-readonly [title]="option.investmentOptionName" [value]="option.percentage + '%'"
                class="w-50 p-1 flex-fill"></app-readonly>
        </ng-container>
    </div>
</div>

<div class="mb-4">
    <h3 class="mb-0">Bank details</h3>
    <div *ngIf="formData.pensionApplicationBankDetails" class="d-flex flex-wrap">
        <app-readonly title="Financial institution name"
            [value]="formData.pensionApplicationBankDetails.financialInstitutionName"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly title="Account name" [value]="formData.pensionApplicationBankDetails.bankAccountName"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly title="Account BSB" [value]="formData.pensionApplicationBankDetails.bankAccountBsb"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly title="Account number" [value]="formData.pensionApplicationBankDetails.bankAccountNumber"
            class="w-50 p-1 flex-fill"></app-readonly>
    </div>
</div>

<div class="mb-4">
    <h3 class="mb-0">Reversionary Beneficiary</h3>
    <div class="d-flex flex-wrap">
        <div *ngIf="!formData.pensionApplicationBeneficiaryDetails.savedData"
            class="d-flex align-items-center value-container mt-1 mb-1">
            <div class="d-flex flex-row align-items-center">
                <app-readonly title="Beneficiary" value="No beneficiary added"
                    class="w-50 p-1 flex-fill"></app-readonly>
            </div>
        </div>
    </div>
    <div *ngIf="formData.pensionApplicationBeneficiaryDetails.savedData" class="d-flex flex-wrap">
        <app-readonly [title]="formData.pensionApplicationBeneficiaryDetails.customWording.beneficiaryName"
            [value]="formData.pensionApplicationBeneficiaryDetails.savedData.beneficiaryName"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly [title]="formData.pensionApplicationBeneficiaryDetails.customWording.beneficiaryDob"
            [value]="(formData.pensionApplicationBeneficiaryDetails.savedData.beneficiaryDob | date: 'dd/MM/yyyy')"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly [title]="formData.pensionApplicationBeneficiaryDetails.customWording.beneficiaryRelationship"
            [value]="formData.pensionApplicationBeneficiaryDetails.relationshipTypes[formData.pensionApplicationBeneficiaryDetails.savedData.beneficiaryRelationshipId-1].value"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly [title]="formData.pensionApplicationBeneficiaryDetails.customWording.beneficiaryGender"
            [value]="formData.pensionApplicationBeneficiaryDetails.genderTypes[formData.pensionApplicationBeneficiaryDetails.savedData.genderId-1].value"
            class="w-50 p-1 flex-fill"></app-readonly>
        <app-readonly [title]="formData.pensionApplicationBeneficiaryDetails.customWording.beneficiaryAddress"
            [value]="formData.pensionApplicationBeneficiaryDetails.savedData.beneficiaryAddress.fullAddress"
            class="w-100 p-1 flex-fill"></app-readonly>
    </div>
</div>

<div class="mb-4">
    <h3 class="mb-0">Documents</h3>
    <div *ngIf="formData.pensionApplicationDocumentsDetails.documents" class="d-flex flex-wrap">
        <ng-container *ngFor="let overview of formData.pensionApplicationDocumentsDetails.documents">
            <div class="w-50 p-1 flex-fill">
                <div id="app-readonly-container" class="info r">
                    <div class="title d-flex flex-row align-items-center">
                        {{overview.documentName}} ({{overview.isDocumentRequired ? 'Required' :
                        'Optional'}}{{overview.documentAmountRequired > 1 ? ', ' + overview.documentAmountRequired
                        :null}})
                        <mat-icon class="ml-2 mr-3">upload_file</mat-icon>
                    </div>
                    <div class="d-flex align-items-center value-container mt-1 mb-1">
                        <div class="d-flex flex-row align-items-center">
                            <p class="align-self-center mb-0 app-copyable">{{checkIfDocumentsExist(overview) ?
                                'Supporting document(s) uploaded' : 'Document(s) not uploaded'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>