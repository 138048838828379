import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { IStartAPensionStartComponentState, StartAPensionFilterModel } from './state';
import { ProcessStartedAction, ResetComponentStateAction, ResponseAction, SetApplicationToCancelledAction, UpdateSubmittedApplicationRequestAction, } from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const startAPensionStartFilter = 'startAPensionStartFilter';

export const initialState: IStartAPensionStartComponentState = {
  model: null,
  filter: createFormGroupState(startAPensionStartFilter, new StartAPensionFilterModel()),
  processStarted: false,
  updatingSubmitted: false,
};

const helper = new Helper();

const reducer = createReducer(initialState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(SetApplicationToCancelledAction, (state, { applicationId }) => {
    const summaryCopy = helper.clone(state.model)

    const cancelledApp = summaryCopy.draftApplications.find(x => x.pensionApplicationId === applicationId);
    cancelledApp.cancelledOn = new Date();
    summaryCopy.cancelledApplications.push(cancelledApp);
    summaryCopy.draftApplications = summaryCopy.draftApplications.filter(x => x.pensionApplicationId !== applicationId);

    return {
      ...state,
      model: summaryCopy
    }
  }),
  on(ResetComponentStateAction, (_state) => initialState),
  on(ProcessStartedAction, (state) => {
    return {
      ...state,
      processStarted: true
    }
  }),
  on(UpdateSubmittedApplicationRequestAction, (state) => {
    return {
      ...state,
      updatingSubmitted: true
    }
  })
);

export function startAPensionStartComponentReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
