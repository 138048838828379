import { KeyValueModel } from "@ifaa-components/ui-components";
import { createAction, props } from "@ngrx/store";
import { PensionApplicationEligibilitySubmissionModel, StartAPensionInitialFormModel } from "src/app/model/start-a-pension-form.model";

export const SetEligibilityFormDataAction = createAction('[Start a pension eligibility form component] set initial form data', props<{ data: StartAPensionInitialFormModel }>())
export const ResetComponentStateAction = createAction('[Start a pension eligibility form component] reset component state');
export const SetTypesOfAccountsAction = createAction('[Start a pension eligibility form component] set types of accounts', props<{ types: KeyValueModel[] }>());
export const SetConditionsAction = createAction('[Start a pension eligibility form component] set conditions of release', props<{ conditions: KeyValueModel[] }>());

export const SubmitFormAction = createAction('[Start a pension eligibility form component] submit form', props<{ accountId: number, conditionId: number, submission: PensionApplicationEligibilitySubmissionModel }>());
export const SubmitFormErrorAction = createAction('[Start a pension eligibility form component] submit form error');