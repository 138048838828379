import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Helper } from "@ifaa-components/ui-components";
import { select, Store } from "@ngrx/store";
import { SetValueAction } from "ngrx-forms";
import { distinct, pairwise } from "rxjs/operators";
import { AccessYourSuperPaymentTypeEnum } from "src/app/enums/access-your-super-payment-type.enum";
import { AccessYourSuperPaymentDetailSubmissionModel } from "src/app/model/access-your-super-custom-sections.model";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import { SetCurrentStepAction } from "../../access-your-super-form/actions";
import { RequestAction, ResetComponentStateAction, SaveFormDataAction } from "./actions";
import { accessYourSuperCompassionateGrounds_Form, accessYourSuperCompassionateGrounds_Model, accessYourSuperCompassionateGrounds_SavedFormData } from "./selectors";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-access-your-super-compassionate-grounds',
    templateUrl: './access-your-super-compassionate-grounds.component.html',
    styleUrls: ['./access-your-super-compassionate-grounds.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class AccessYourSuperCompassionateGroundsComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set nextStep(value: number) {
        this.step = value;
    }
    @Input() set applicationId(value: number) {
        this.id = value;
    }
    @Input() set backStep(value: number) {
        this.previousStep = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set typeId(value: number) {
        this.applicationTypeId = value;
    }
    @Input() set canGoBack(value: boolean) {
        this.showBackButton = value;
    }
    @Input() set submitting(value: boolean) {
        this.isSubmitting = value;
    }

    model$ = this.store.pipe(select(accessYourSuperCompassionateGrounds_Model));
    form$ = this.store.pipe(select(accessYourSuperCompassionateGrounds_Form));
    savedFormData$ = this.store.pipe(select(accessYourSuperCompassionateGrounds_SavedFormData));

    helper = new Helper();
    step: number = null;
    previousStep: number = null
    id: number = null;
    applicationTypeId: number = null;
    paymentDetailId: number = null;
    accountId: number = null;
    isSubmitting: boolean = false;
    showBackButton: boolean = false;

    constructor(
        public store: Store<AppState>,
        private router: Router,
        private toastr: ToastrService) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();

        this.sub = this.model$.pipe(distinct())
            .subscribe(async x => {
                if (!x) {
                    this.dispatch(this.store, RequestAction({ accountId: this.accountId, applicationId: this.id, applicationTypeId: this.applicationTypeId }))
                }

                if (x) {
                    var form = await this.helper.getValue(this.form$);

                    var investmentOptions = Object.assign([], x.currentInvestmentMix);
                    this.store.dispatch(new SetValueAction(form.controls.investmentOptionDrawdown.id, investmentOptions));

                    // update form values if we are updating an existing application
                    if (x.submissionData) {
                        this.paymentDetailId = x.submissionData.accessYourSuperPaymentDetailId;

                        x.submissionData.otherCondition?.isAtoApproved ?
                            this.store.dispatch(new SetValueAction(form.controls.isAtoApproved.id, x.submissionData.otherCondition?.isAtoApproved)) :
                            null;

                        x.submissionData.benefitAmount ?
                            this.store.dispatch(new SetValueAction(form.controls.partialAmountSpecified.id, x.submissionData.benefitAmount)) :
                            null;
                    }
                }
            })

        this.sub = this.form$.pipe(pairwise()).subscribe(async x => {
            if (x[0].value.isAtoApproved && !x[1].value.isAtoApproved) {
                var model = await this.helper.getValue(this.model$);
                this.toastr.error(model.formLabels.atoNotApprovedWarning)
            }
        })


        // Go next step when form has been successfully saved
        this.sub = this.savedFormData$.subscribe(x => {
            if (x) {
                this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.step }))
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction());
    }

    exitForm() {
        this.router.navigate(['/access-your-super']);
    }

    async goNextStep() {
        var helper = new Helper();
        var form = await helper.getValue(this.form$);

        var paymentTypeId = AccessYourSuperPaymentTypeEnum.PartialAmount;

        var payload = {
            accessYourSuperPaymentDetailId: this.paymentDetailId,
            accessYourSuperApplicationId: this.id,
            paymentTypeId: paymentTypeId,
            benefitAmount: paymentTypeId === AccessYourSuperPaymentTypeEnum.PartialAmount ? form.value.partialAmountSpecified : null,
            otherCondition: {
                isAtoApproved: form.value.isAtoApproved
            }
        } as AccessYourSuperPaymentDetailSubmissionModel;

        this.dispatch(this.store, SaveFormDataAction({ accountId: this.accountId, payload: payload }));
    }

    goBack() {
        this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.previousStep }))
    }
}
