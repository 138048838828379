import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import {
    PensionApplicationAccountDetailsSubmissionModel, PensionApplicationBankDetailsSubmissionModel, PensionApplicationBeneficiarySubmissionModel,
    PensionApplicationEligibilitySubmissionModel, PensionApplicationPaymentDetailsSubmissionModel
} from '../model/start-a-pension-form.model';
import { AppFileModel } from '@ifaa-components/ui-components';

@Injectable()
export class StartAPensionService extends BaseService {

    constructor(private http: HttpClient,
        public toastr: ToastrService) {
        super(toastr);
    }

    getApplicationList(accountId: number): Observable<any> {
        return this.http.get(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/get-applications`, { headers: this.headers() });
    }

    cancelApplication(accountId: number, applicationId: number): Observable<any> {
        return this.http.put(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/cancel-application`, { headers: this.headers() });
    }

    getInitialFormData(accountId: number): Observable<any> {
        return this.http.get(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/get-initial-form`, { headers: this.headers() });
    }

    createBaseApplication(accountId: number, conditionId: number, submission: PensionApplicationEligibilitySubmissionModel): Observable<any> {
        return this.http.post(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/condition/${conditionId}/create-application`, submission, { headers: this.headers() });
    }

    getApplicationData(accountId: number, applicationId: number, draft: boolean = true): Observable<any> {
        return this.http.get(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/get-application-data?draft=${draft}`, { headers: this.headers() });
    }

    incrementCurrentStep(accountId: number, applicationId: number): Observable<any> {
        return this.http.put(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/update-current-step`, { headers: this.headers() });
    }

    saveAccountDetails(accountId: number, applicationId: number, submission: PensionApplicationAccountDetailsSubmissionModel): Observable<any> {
        return this.http.post(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/save-account-details`, submission, { headers: this.headers() });
    }

    savePaymentDetails(accountId: number, applicationId: number, submission: PensionApplicationPaymentDetailsSubmissionModel): Observable<any> {
        return this.http.post(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/save-payment-details`, submission, { headers: this.headers() });
    }

    searchBankName(bsb: any): Observable<any> {
        return this.http.get<string>(environment.apiUrl + `/member-portal/Bank/getFinancialInstitutionName/${bsb}`,
            { headers: this.headers() });
    }

    saveBankDetails(accountId: number, applicationId: number, submission: PensionApplicationBankDetailsSubmissionModel): Observable<any> {
        return this.http.post(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/save-bank-details`, submission, { headers: this.headers() });
    }

    saveBeneficiary(accountId: number, applicationId: number, submission: PensionApplicationBeneficiarySubmissionModel): Observable<any> {
        return this.http.post(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/save-beneficiary-details`, submission, { headers: this.headers() });
    }

    saveDocument(accountId: any, applicationId: any, documentTypeId: any, payload: AppFileModel, draft: boolean = true): Observable<any> {
        return this.http.put<AppFileModel>(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/documentTypeId/${documentTypeId}/save-document?draft=${draft}`, payload,
            { headers: this.headers() });
    }

    deleteDocument(accountId: any, applicationId: any, uploadedDocumentId: any, draft: boolean = true): Observable<any> {
        return this.http.delete(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/uploadedDocumentId/${uploadedDocumentId}/delete-document?draft=${draft}`,
            { headers: this.headers() });
    }

    completeDocuments(accountId: any, applicationId: any): Observable<any> {
        return this.http.put(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/complete-documents`, null,
            { headers: this.headers() });
    }

    submitApplication(accountId: number, applicationId: number): Observable<any> {
        return this.http.put(environment.apiUrl + `/member-portal/StartAPension/account/${accountId}/application/${applicationId}/submit`, { headers: this.headers() });
    }
}
