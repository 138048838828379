import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { FormGroupState, } from "ngrx-forms";
import { AccessYourSuperDrawdownModel } from "src/app/model/access-your-super-custom-sections.model";
import { PensionInvestmentOptionModel } from "src/app/model/start-a-pension-form.model";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";

@Component({
    selector: 'app-start-a-pension-investment-options',
    templateUrl: './start-a-pension-investment-options.component.html',
    styleUrls: ['./start-a-pension-investment-options.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class StartAPensionInvestmentOptionsComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set form(value: FormGroupState<PensionInvestmentOptionModel>) {
        this.formControl = value;
    }
    @Input() set totalCount(value: number) {
        this.total = value;
    }
    @Input() set isCurrent(value: boolean) {
        this.isCurrentOptions = value;
    }

    formControl: FormGroupState<PensionInvestmentOptionModel> = null;
    total: number = null;
    isCurrentOptions: boolean = true;

    constructor(
        public store: Store<AppState>) {
        super();
    }
    ngOnDestroy(): void {
        super.ngOnDestroyBase();
    }

    async ngOnInit() {
        super.ngOnInitBase();
    }

    trackByFn(index, item) {
        return index;
    }
}
