import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IStartAPensionReviewComponentState } from "./state";
import { MemberPortalSharedState } from "src/app/modules/shared/store/shared.states";
import { featureKey } from "src/app/modules/shared/store/shared.reducers";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionReviewState = createSelector(memberPortalState, (state) => state.startAPensionReviewComponentState);

export const startAPensionReview_Form = createSelector(
    getStartAPensionReviewState,
    (state: IStartAPensionReviewComponentState) => state.form
);

export const startAPensionReview_ApplicationSubmitted = createSelector(
    getStartAPensionReviewState,
    (state: IStartAPensionReviewComponentState) => state.applicationSubmitted
);