import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';
import { IInsuranceUpdateComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const insuranceUpdate = createSelector(memberPortalState, (state) => state.insuranceUpdateFormComponentState);

export const insuranceUpdate_Model = createSelector(
    insuranceUpdate,
    (state: IInsuranceUpdateComponentState) => state.model
);

export const insuranceUpdate_InitialForm = createSelector(
    insuranceUpdate,
    (state: IInsuranceUpdateComponentState) => state.initialForm
);

export const insuranceUpdate_ReduceForm = createSelector(
    insuranceUpdate,
    (state: IInsuranceUpdateComponentState) => state.reduceForm
);

export const insuranceUpdate_ReduceFormKeyProps = createSelector(
    insuranceUpdate,
    (state: IInsuranceUpdateComponentState) => {
        return state.reduceForm.controls?.insurance?.controls?.map(control => {
            return {
                originalTotal: control.value.amount,
                code: control.value.code,
                coverTypes: control.controls.covers.controls.map(innerControl => {
                    return {
                        newAmountId: innerControl.controls.newAmount.id,
                        coverType: innerControl.value.coverType,
                        newAmount: innerControl.value.newAmount
                    }
                })
            }
        })
    }
);

export const insuranceUpdate_CancelFormKeyProps = createSelector(
    insuranceUpdate,
    (state: IInsuranceUpdateComponentState) => {
        return state.cancelForm.controls?.insurance?.controls?.map(control => {
            return {
                originalTotal: control.value.amount,
                code: control.value.code,
                coverTypes: control.controls.covers.controls.map(innerControl => {
                    return {
                        checkboxId: innerControl.controls.checkbox.id,
                        coverType: innerControl.value.coverType,
                        toggled: innerControl.value.checkbox
                    }
                })
            }
        })
    }
);

export const insuranceUpdate_CancelForm = createSelector(
    insuranceUpdate,
    (state: IInsuranceUpdateComponentState) => state.cancelForm
);

export const insuranceUpdate_Submitted = createSelector(
    insuranceUpdate,
    (state: IInsuranceUpdateComponentState) => state.submitted
);

export const insuranceUpdate_InsuranceToReduce = createSelector(
    insuranceUpdate,
    (state: IInsuranceUpdateComponentState) => state.insuranceToReduce
);

export const insuranceUpdate_InsuranceToCancel = createSelector(
    insuranceUpdate,
    (state: IInsuranceUpdateComponentState) => state.insuranceToCancel
);
