import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { PensionApplicationPaymentDetailsFormModel, PensionInvestmentOptionModel } from "src/app/model/start-a-pension-form.model";

export interface IStartAPensionPaymentDetailsFormComponentState {
    formData: PensionApplicationPaymentDetailsFormModel;
    form: FormGroupState<StartAPensionPaymentDetailsForm>;
    frequencyTypes: KeyValueModel[];
    commencementOfPaymentsOption: KeyValueModel[];
    paymentTypes: KeyValueModel[];
    investmentOptions: PensionInvestmentOptionModel[];
}

export class StartAPensionPaymentDetailsForm {
    frequencyType: number = 1;
    commencementOfDateOption: number = 1;
    commencementOfDate: string = "";
    paymentType: number = 1;
    paymentAmount: number = 0;
    accountBalance: number = 0;
    annualMax: number = 0;
    wording: { [key: string]: string };
    options: PensionInvestmentOptionModel[];
    isTtr: boolean = false;
}