import { Action, createReducer, on } from "@ngrx/store";
import { createFormGroupState, onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { requiredTrue } from "ngrx-forms/validation";
import { AccessYourSuperReviewForm, IAccessYourSuperReviewComponentState } from "./state";
import { ApplicationSubmittedAction, ResetComponentStateAction, SaveCustomDataAction } from "./actions";

export const state: IAccessYourSuperReviewComponentState = {
    form: createFormGroupState('AccessYourSuperReviewForm', new AccessYourSuperReviewForm()),
    applicationSubmitted: null,
    customData: null,
}

export const validateReviewForm = updateGroup<AccessYourSuperReviewForm>({
    disclaimer: validate(requiredTrue),
})

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        return state;
    }),
    on(ResetComponentStateAction, (_state) => {
        return {
            form: createFormGroupState('AccessYourSuperReviewForm', new AccessYourSuperReviewForm()),
            applicationSubmitted: null,
            customData: null,
        }
    }),
    on(SaveCustomDataAction, (state, { payload }) => {
        return {
            ...state,
            customData: payload
        }
    }),
    on(ApplicationSubmittedAction, (state) => {
        return {
            ...state,
            applicationSubmitted: true,
        }
    }),
);

const editReviewReducerFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateReviewForm
)

export function accessYourSuperReviewComponentReducer(state: any | undefined, action: Action) {
    return editReviewReducerFormState(state, action);
}
