<div id="start-a-pension-eligibility">
    <h2 class="mb-3">Eligibility to start a pension</h2>
    <div *ngIf="form$|async; let form">
        <form [ngrxFormState]="form" class="ml-3 mr-3">

            <div *ngIf="showCitizenQuestion" class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">{{formData.formWording.citizenQuestion}}
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio list="yesNo" [control]="form.controls.sapAusCitizen"></app-entry-radio>
                    </div>
                </div>
            </div>

            <div class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">{{formData.formWording.typeOfAccount}}
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio [customList]="typesOfAccounts$"
                            [control]="form.controls.sapTypeOfAccount"></app-entry-radio>
                    </div>
                </div>
            </div>

            <div *ngIf="form.value.sapTypeOfAccount === 1" class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">To commence a retirement pension, please
                        select the applicable 'Conditions of release' below -
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio [customList]="conditions$"
                            [control]="form.controls.sapConditionOfRelease"></app-entry-radio>
                    </div>
                </div>
            </div>

            <div *ngIf="form.value.sapTypeOfAccount === 2 && formData.hasReachedPreservationAge"
                class="question d-flex flex-row description mt-4 pl-2">
                {{formData.hasReachedPreservationAge ? formData.formWording.ttrTooltip :
                null}}
            </div>

            <div *ngIf="form.value.sapConditionOfRelease === 5" class="question description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">Provide reason:
                    </div>
                    <div class="custom-control custom-radio answer resizing">
                        <app-entry-textarea [customList]="conditions$"
                            [control]="form.controls.otherReasonText"></app-entry-textarea>
                    </div>
                </div>
            </div>

            <div *ngIf="showNote$|async" class="question d-flex flex-row description mt-4 pl-2">Note:
                {{form.value.sapConditionOfRelease === 1 ?
                formData.formWording.retiredTooltip :
                form.value.sapConditionOfRelease === 5 ? formData.formWording.terminallyIllOrOther: null}}
            </div>

        </form>
        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                    color="{{'BTN.start-a-pension.cancel.color' | translate}}" [isDisabled]="isSubmitting"
                    (onClick)="exitForm()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                    color="{{'BTN.start-a-pension.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || isSubmitting" loading (onClick)="createApplication()"></app-button>
            </div>
        </div>
    </div>
</div>