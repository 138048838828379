import { MemberPortalSharedState } from '../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { INoticeOfIntentTaxDeductionFormComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const noticeOfIntentTaxDeductionForm = createSelector(memberPortalState, (state) => state.noticeOfIntentTaxDeductionFormComponentState);

export const noticeOfIntentTaxDeductionForm_Form = createSelector(
  noticeOfIntentTaxDeductionForm,
  (state: INoticeOfIntentTaxDeductionFormComponentState) => state.form
);

export const noticeOfIntentTaxDeductionForm_TaxDeductionClaimTypes = createSelector(
  noticeOfIntentTaxDeductionForm,
  (state: INoticeOfIntentTaxDeductionFormComponentState) => state.taxDeductionClaimTypes
);

export const noticeOfIntentTaxDeductionForm_FinancialYearDropdownOptions = createSelector(
  noticeOfIntentTaxDeductionForm,
  (state: INoticeOfIntentTaxDeductionFormComponentState) => state.financialYearDropdownOptions
);

export const noticeOfIntentTaxDeductionForm_SelectedFinancialYear = createSelector(
  noticeOfIntentTaxDeductionForm,
  (state: INoticeOfIntentTaxDeductionFormComponentState) => {
    return {
      financialYear: state.form.value.financialYear,
      controlId: state.form.controls.personalContribution.id,
      claimType: state.form.value.claimType
    };
  }
);

export const noticeOfIntentTaxDeductionForm_FinancialYearContributionsAmountId = createSelector(
  noticeOfIntentTaxDeductionForm,
  (state: INoticeOfIntentTaxDeductionFormComponentState) => state.form.controls.financialYearContributions.id
);

export const noticeOfIntentTaxDeductionForm_Submitted = createSelector(
  noticeOfIntentTaxDeductionForm,
  (state: INoticeOfIntentTaxDeductionFormComponentState) => state.submitted
);

export const noticeOfIntentTaxDeductionForm_HasWithdrawalPending = createSelector(
  noticeOfIntentTaxDeductionForm,
  (state: INoticeOfIntentTaxDeductionFormComponentState) => state.form.value.hasWithdrawalPending
);

export const noticeOfIntentTaxDeductionForm_Disclaimer = createSelector(
  noticeOfIntentTaxDeductionForm,
  (state: INoticeOfIntentTaxDeductionFormComponentState) => state.disclaimer
);