<div id="insurance-questionnaire-start-container" *ngIf="messages$|async">
  <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
    <div class="top-progress option-1">
      <ul class="progressbar d-flex ">
        <li class=" flex-fill" *ngFor="let item of [].constructor((totalSteps$ | async)); let i = index"
          [ngClass]="{'active': (currentStep$|async) >= i+1}"></li>
      </ul>

      <div class="progress progress-bar-striped progress-bar-animated mb-4 option-2">
        <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
          style="width: {{progressBar((currentStep$ | async), (totalSteps$ | async))}}%">
          {{progressBar((currentStep$ | async), (totalSteps$ | async))}}%
        </div>
      </div>
    </div>
    <div class="option-2">
      <div class="ng-progressbar">
        <mat-progress-bar mode="determinate" value="{{progressBar((currentStep$ | async), (totalSteps$ | async))}}">
        </mat-progress-bar>
      </div>
    </div>

    <div class="tab step-1" *ngIf="(currentStep$ | async) == 1 && !(submitted$ | async)" appanimatecss>
      <h2>Your personal details</h2>

      <div class="mt-4 mb-2 title description">Your personal details</div>

      <div class="d-flex flex-column mb-4 answer ml-3 description">
        <div class="d-flex flex-row mb-2">
          <div style="width: 200px">
            Your member number:
          </div>
          <div class="flex-1">
            {{(loggedIn$ | async)?.memberNumber}}
          </div>
        </div>

        <div class="d-flex flex-row">
          <div style="width: 200px">
            Your age next birthday:
          </div>
          <div class="flex-1">
            {{ageNextBirthday$ | async}}
          </div>
        </div>
      </div>
      <div class="question d-flex flex-row description">
        <div>
          <div class="title" [innerHtml]="'insuranceQuestion_SexOnBirthCertificate'|message|async"></div>

          <div class="custom-control custom-radio answer gender">
            <app-entry-radio list="gender" [control]="form.controls.gender"></app-entry-radio>
          </div>

          <div class="title" [innerHtml]="'insuranceQuestion_LegallyRecognisedAsDifGender'|message|async"></div>

          <div class="custom-control custom-radio answer gender">
            <app-entry-radio list="yesNo" [control]="form.controls.isLegallyRecognisedAsGender"></app-entry-radio>
          </div>

          <div class="title" [innerHtml]="'insuranceQuestion_WhatGenderRecognisedAsNow'|message|async"></div>

          <div class="custom-control custom-radio answer gender">
            <app-entry-radio list="gender" [control]="form.controls.legallyRecognisedGender"></app-entry-radio>
          </div>

          <div class="alert alert-warning mt-3">
            Insurance providers require sex as a factor for determining insurance premiums. If you are now legally
            recognised as a gender that is different to the gender you were assigned at birth, you may apply for
            Standard insurance cover with Verve Super as your current gender, however you must be able to provide
            documented proof that your gender has been legally recognised on the Insurer’s request. If your application
            for insurance is successful, you will be insured under the conditions and rates of your current gender.
          </div>
        </div>
      </div>
    </div>
    <div class="tab step-2" *ngIf="(currentStep$ | async) == 2 && !(submitted$ | async)" appanimatecss>
      <h2>Insurance Questionnaire</h2>
      <div class="question d-flex flex-row description">
        <div>
          <div class="title" [innerHtml]="'insuranceQuestion_AreYouAnAusCitizenOrPermResident'|message|async"></div>
          <div class="custom-control custom-radio answer">
            <app-entry-radio list="australianCitizenOrAustralianPermanentResident"
              [control]="form.controls.isCitizenOrPermanent"></app-entry-radio>
          </div>
          <div class="alert alert-warning mt-3" *ngIf="('yourInsuranceCover_AustralianOrPRMessage' | message | async)"
            [innerHTML]="('yourInsuranceCover_AustralianOrPRMessage' | message | async)">
          </div>
        </div>
      </div>
    </div>
    <div class="tab step-3" *ngIf="(currentStep$ | async) == 3 && !(submitted$ | async)" appanimatecss>
      <h2>About your employment</h2>
      <div class="question d-flex flex-row description">

        <div>
          <div class="title" [innerHtml]="'insuranceQuestion_AreYouCurrentlyInPaidEmployment'|message|async"></div>
          <div class="custom-control custom-radio answer">
            <app-entry-radio list="yesNo" [control]="form.controls.isPaidEmployment"></app-entry-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="tab step-4" *ngIf="(currentStep$ | async) == 4 && !(submitted$ | async)" appanimatecss>
      <h2>About your employment</h2>
      <div class="question d-flex flex-row description">
        <div>
          <div class="title" [innerHtml]="'insuranceQuestion_WhatIsCurrentEmploymentArrangement'|message|async"></div>
          <div class="custom-control custom-radio answer">
            <app-entry-radio list="employmentArrangement" [control]="form.controls.employmentArrangement">
            </app-entry-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="tab step-5" *ngIf="(currentStep$ | async) == 5 && !(submitted$ | async)" appanimatecss>
      <h2>About your employment</h2>
      <div class="description">
        <div class="title" [innerHtml]="'insuranceQuestion_WorkingInHazardousOccupation'|message|async"></div>
        <p class="mt-4">Hazardous occupation means an employee who performs skilled or semi-skilled manual labour, or
          who is exposed to high-risk accidents or health hazards, and includes (not a definitive list):</p>
        <ul class="answer">
          <li>Specialist occupations including, but not limited to, models, actors, pilots and airline crew,
            firefighters, police, fitness instructors and professional sportspeople; or</li>
          <li>Occupations with additional hazards including, but not limited to miners, seafarers, long haul drivers
            (>500 km radius), labourers, those working at heights (>10 metres), abalone divers, asbestos workers,
            bouncers, farmers, seasonal workers and heavy machinery operations; or</li>
          <li>Highly repetitive occupations including, but not limited to, process workers and factory hands.</li>
        </ul>
        <div *ngIf="('yourInsuranceCover_HazardousOccupationListNote' | message | async)"
          [innerHTML]="('yourInsuranceCover_HazardousOccupationListNote' | message | async)"></div>
      </div>
      <div class="question d-flex flex-row description">
        <div>
          <div class="title" [innerHtml]="'insuranceQuestion_WorkingInHazardousOccupation'|message|async"></div>
          <div class="custom-control custom-radio answer">
            <app-entry-radio list="yesNo" [control]="form.controls.isHazardousOccupation">
            </app-entry-radio>
          </div>
        </div>
      </div>
      <div class="question d-flex flex-row description">
        <div *ngIf="('yourInsuranceCover_HazardousOccupationNote' | message | async)"
          [innerHTML]="('yourInsuranceCover_HazardousOccupationNote' | message | async)"></div>
      </div>
    </div>
    <div class="tab step-6" *ngIf="(currentStep$ | async) == 6 && !(submitted$ | async)" appanimatecss>
      <h2>Easy, standard insurance questionnaire</h2>
      <h3>About your employment</h3>
      <div class="mt-3 description" *ngIf="('yourInsuranceCover_OccupationNote' | message | async)"
        [innerHTML]="('yourInsuranceCover_OccupationNote' | message | async)"></div>
      <div class="question d-flex flex-row description">
        <div>
          <div class="title" [innerHtml]="'insuranceQuestion_DescribeYourEmployment'|message|async"></div>
          <div class="custom-control custom-radio answer">
            <app-entry-radio list="occupationDescription" [control]="form.controls.occupationDescription">
            </app-entry-radio>
          </div>
        </div>
      </div>


      <!-- Your occupation determines your premium and, in some cases, your eligibility for insurance cover. If there is
        doubt about what category your occupation falls into, please call {{(tenantConfig$ | async).name}} on <a
          href="tel:{{(tenantConfig$ | async).phoneNumber}}">{{(tenantConfig$ | async).phoneNumber}}</a> or email us on
        <a href="mailto:{{(tenantConfig$ | async).email}}">{{(tenantConfig$ | async).email}}</a>
        . If your occupation classification changes, it is
        essential that you contact {{(tenantConfig$ | async).name}} immediately. -->
    </div>
    <div class="tab step-7" *ngIf="(currentStep$ | async) == 7 && !(submitted$ | async)" appanimatecss>
      <h2>Insurance Questionnaire</h2>
      <div class="question d-flex flex-row description">
        <div>
          <div class="title" [innerHtml]="'insuranceQuestion_DoStatementsApply'|message|async"></div>
          <ul class="answer">
            <li>You have been declined insurance cover or had any special conditions or restrictions (loadings or
              exclusions) placed on any Death, TPD, or Income Protection (IP) Insurance with any superannuation fund or
              insurer.
            </li>
            <li>You have lodged/been paid, or are eligible to lodge or be paid a workers’ compensation, TPD, Terminal
              Illness, or IP benefit with any superannuation fund or insurer.</li>
            <li>You have been diagnosed with a terminal illness that reduces your life expectancy to less than 24
              months.
            </li>
            <li>As at the date of this application, you have been off work for more than 10 days in the last 12 months
              for the same medical condition.</li>
            <li>You have lodged/been paid or are eligible to lodge or be paid a benefit for permanent incapacity or a
              terminal medical condition from a superannuation fund after satisfying a condition of release in
              accordance with the Superannuation
              Industry (Supervision) Regulations. </li>
          </ul>

          <div class="custom-control custom-radio answer">
            <app-entry-radio list="statementsApplyToYou" [control]="form.controls.statementsApplyToYou">
            </app-entry-radio>
          </div>

          <div class="mt-3 description" *ngIf="('yourInsuranceCover_StatementsApplyNote' | message | async)"
            [innerHTML]="('yourInsuranceCover_StatementsApplyNote' | message | async)"></div>

        </div>
      </div>
    </div>
    <div class="tab step-8" *ngIf="(currentStep$ | async) == 8 && !(submitted$ | async)" appanimatecss>
      <h2>Consider what is right for you</h2>
      <div class="question d-flex flex-row description">

        <div>
          <p class="">
            Insurance through superannuation can be a tax effective way to obtain insurance. However, premiums incurred
            from unnecessary, unwanted or unsuitable insurance cover can inappropriately erode member balances and, as a
            result, the savings available for members at retirement.
          </p>
          <p>
            It’s important to consider if you have multiple superannuation accounts and may therefore be paying
            duplicate sets of insurance premiums. For information on how to keep track of your super, you can visit the
            <a target="_blank" [href]="'VIEWS.Insurance.ATO_WEBSITE' | translate">ATO website</a>.
          </p>
          <p>
            Prior to making any decision in relation to insurance through {{(tenantConfig$ | async).name}},
            you should determine whether the cover is right for you. Among other things, you should:
          </p>
          <ul class="answer">
            <li>check if you have any other insurance cover; and</li>
            <li>check if the terms of cover, including the premiums, level of cover and any applicable restrictions or
              exclusions, are appropriate for your needs and circumstances; and consider speaking to a licensed or
              authorised financial adviser.</li>
          </ul>
          <p>
            <strong>
              By submitting this application for underwritten cover, you acknowledge that you elect for the Trustee to
              take out and maintain insurance cover in relation to your {{(tenantConfig$ | async).name}} account
              (subject to you meeting the policy terms, including premium requirements),
              even if your {{(tenantConfig$ | async).name}} account balance is less than $6,000 and/or you are under the
              age of 25 and/or your account becomes inactive, and that you can cease the insurance by submitting a
              request to <a href="mailto:{{(tenantConfig$ | async).email}}">{{(tenantConfig$ | async).email}}</a>.
            </strong>
          </p>
          <div class="custom-control custom-checkbox answer">

            <app-checkbox title="Yes, I acknowledge the above" [control]="form.controls.youAcknowledge">
            </app-checkbox>
          </div>
        </div>
      </div>

    </div>
    <div class="tab ste-confirm" *ngIf="(submitted$ | async) && !(confirmedDate$ | async)" appanimatecss>
      <h2>Easy, standard insurance questionnaire</h2>
      <div class="description">
        <div>
          <p class="alert alert-info">
            Congratulations, you’re eligible to apply for Standard insurance cover with {{(tenantConfig$ |
            async).name}}.
          </p>
          <p class="description">
            Your insurance premium has been calculated below based upon the answers you provided. You may choose to opt
            out
            of TPD (Total and Permanent Disablement) cover. This will change your premium.
          </p>

          <ul class="answer">
            <li>You cannot hold TPD cover without Death cover.</li>
            <li>TPD cover is not available to members upon reaching age 55.</li>
            <li>TPD cover is not available if you are not a Permanent Employee or Contractor working at least 20 hours
              per week.</li>
          </ul>
        </div>
      </div>
      <div class="mt-3 description" *ngIf="('yourInsuranceCover_CongratulationsNote' | message | async)"
        [innerHTML]="('yourInsuranceCover_CongratulationsNote' | message | async)"></div>

      <!-- <p class="description" >
        This insurance cover will be subject to a New Events Cover restriction for the first 2 years. You will only be
        covered or claims arising form a sickness which first became apparent or an injury which first occurred on or
        after the date the cover commenced. Please read the Insurance Guide
        <link> for more information about the New Events Cover restriction.
      </p> -->
      <p class="title description mt-2 font-weight-bold">Your Standard insurance cover quote</p>
      <div class="d-flex flex-column mb-4 answer description">
        <div class="d-flex flex-row mb-2">
          <div style="width: 150px">
            Death cover
          </div>
          <div class="flex-1">
            {{(submitted$ | async).value.deathCover}}
          </div>
        </div>
        <div class="d-flex flex-row mb-2 align-items-center">
          <div style="width: 150px">
            TPD cover
          </div>
          <div class="flex-1 d-flex flex-row align-items-center">
            <div class="mr-3">{{(submitted$ | async).value.tpdCover}}</div>
            <app-checkbox *ngIf="(submitted$ | async).value.tpdCover != 'n/a'"
              title="I would like to opt out of TPD cover." [control]="(submitted$ | async).controls.optOutTdpCover">
            </app-checkbox>

          </div>
        </div>
        <div class="d-flex flex-row">
          <div style="width: 150px">
            Cost of cover
          </div>
          <div class="flex-1">
            <span *ngIf="!(submitted$ | async).value.optOutTdpCover">{{(submitted$ |
              async).value.costOfCoverWithTpd}}</span>
            <span *ngIf="(submitted$ | async).value.optOutTdpCover">{{(submitted$ |
              async).value.costOfCoverWithoutTpd}}</span>
            <span>&nbsp;per month</span>
          </div>
        </div>
      </div>

      <div class="d-flex align-item-center mb-2 description">&nbsp;
        <div *ngIf="!hasPanelOpened" class="ml-4 d-flex align-item-center alert alert-warning application">
          <mat-icon>report</mat-icon> must read
        </div>
      </div>
      <mat-accordion>
        <mat-expansion-panel (opened)="onDutyToTakeReasonableClicked(true)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              About this application
            </mat-panel-title>
            <mat-panel-description class="d-flex align-items-center">

            </mat-panel-description>

          </mat-expansion-panel-header>

          <p>The life insurance policy being applied for with this application is a consumer insurance contract within
            the meaning of the Insurance Contracts Act 1984 (Cth).
            When you apply for life insurance, we conduct a process called underwriting.
            It’s how we decide whether we can cover you, and if so, on what terms and at what cost.</p>

          <p>We will ask questions we need to know the answers to.
            These will be about your personal circumstances, such as your health and medical history, occupation,
            income, lifestyle, pastimes, and current and past insurance.
            The information you give us in response to our questions is vital to our decision.</p>

          <p class="title">The duty to take reasonable care</p>

          <p>When applying for insurance, there is a legal duty to take reasonable care not to make a misrepresentation
            to the insurer before the contract of insurance is entered into. </p>

          <p>A misrepresentation is a false answer, an answer that is only partially true, or an answer which does not
            fairly reflect the truth.</p>

          <p>This duty applies to a new contract of insurance and also applies when extending or making changes to
            existing insurance, and reinstating insurance.</p>

          <p class="title">If you do not meet your duty</p>
          <p>If you do not meet your legal duty, this can have serious impacts on your insurance. There are different
            remedies that may be available to us. These are set out in the Insurance Contracts Act 1984 (Cth). These are
            intended to put us in the position we would have been in if the duty had been met.
            Your cover could be avoided (treated as if it never existed), or its terms may be varied. This may also
            result in a claim being declined or a benefit being reduced.
          </p>

          <p>Please note that there may be circumstances where we later investigate whether the information given to us
            was true. For example, we may do this when a claim is made.</p>

          <p>Before we exercise any of these remedies, we will explain our reasons and what you can do if you disagree.
          </p>

          <p class="title">Guidance for answering our questions</p>
          <p>You are responsible for the information provided to us. When answering our questions, please:</p>
          <ul>
            <li>Think carefully about each question before you answer. If you are unsure of the meaning of any question,
              please ask us before you respond.</li>
            <li>Answer every question.</li>
            <li>Answer truthfully, accurately and completely. If you are unsure about whether you should include
              information, please include it.</li>
            <li>Review your application carefully before it is submitted. If someone else helped prepare your
              application (for example, your adviser), please check every answer (and if necessary, make any
              corrections) before the application is submitted.</li>
          </ul>

          <p class="title">Changes before your cover starts</p>
          <p>Before your cover starts, we may ask about any changes that mean you would now answer our questions
            differently. As any changes might require further assessment or investigation, it could save time if you let
            us know about any changes when they happen.</p>

          <p class="title">If you need help</p>
          <p>It’s important that you understand this information and the questions we ask. Ask us or a person you trust,
            such as your adviser for help if you have difficulty understanding the process of buying insurance or
            answering our questions.</p>

          <p>If you’re having difficulty due to a disability, understanding English or for any other reason, we're here
            to help. If you want, you can have a support person you trust with you.</p>

          <p class="title">Notifying the insurer</p>
          <p>If, after the cover starts, you think you may not have met your duty, please contact us immediately and
            we’ll let you know whether it has any impact on the cover.</p>

        </mat-expansion-panel>
      </mat-accordion>
      <!-- <p class="description">
        By submitting my application for Death & TPD insurance cover through {{(tenantConfig$ | async).name}}, I confirm
        all the details I
        have provided are true and correct and I have read and accept the <a
          [href]="(tenantConfig$ | async).insuranceGuideUrl">Insurance Guide</a> including the Duty of
        Disclosure and <a [href]="(tenantConfig$ | async).privacyPolicyUrl">Privacy Policy</a> of the insurance provider
        AIA Australia.
      </p> -->
      <div class="description">
        <div class="flex-1 d-flex flex-column mt-3">
          <app-checkbox title="I have read and accept the above Duty to Take Reasonable Care."
            [control]="form.controls.takeReasonableCare">
          </app-checkbox>

        </div>

        <div class="flex-1 d-flex flex-column">
          <app-checkbox title="{{'yourInsuranceCover_ConfirmAllDetailsProvidedNote' | message | async}}"
            [control]="form.controls.confirmReadAndAcceptInsuranceGuide">
          </app-checkbox>

        </div>
      </div>

    </div>
    <div class="tab ste-confirmed" *ngIf="(submitted$ | async) && (confirmedDate$ | async)" appanimatecss>
      <h2>Your application has been submitted</h2>
      <p>Thank you! Your application has been submitted with reference number {{(submitted$ |
        async).value.referenceNumber}}.</p>

      <p>Please do not cancel any existing insurance cover you hold until you receive a separate confirmation from us
        that your application has been accepted by the Insurer.</p>

      <p>Your Standard Cover will commence on the later of the date your application is accepted by the Insurer and when
        your first super contribution (rollover, employer SG contribution or personal contribution) has been received by
        the Fund.</p>

      <p>For detailed information about the policy, please read our <a
          [href]="(tenantConfig$ | async).insuranceGuideUrl">Insurance Guide</a> or <a
          href="mailto:{{(tenantConfig$ | async).email}}">contact us</a>. </p>
    </div>
    <div class="tab-error citizen-not-elegible" *ngIf="(currentStep$ | async) == -1" appanimatecss>
      <h2>We’re unable to offer you insurance cover</h2>
      <div class="description">
        <p>Unfortunately, if you are not an Australian citizen or Australian permanent resident, you are not eligible
          for insurance cover through {{(tenantConfig$ | async).name}}.</p>
        <p>Please read our <a target="blank" [href]="(tenantConfig$ | async)?.insuranceGuideUrl">Insurance Guide</a> to
          find out more.</p>
      </div>
    </div>
    <div class="tab-error personal-statement-not-elegible" *ngIf="(currentStep$ | async) == -2" appanimatecss>
      <h2>We’re unable to offer you insurance cover</h2>
      <div class="description">
        <div [innerHTML]="('requestInsurance_UnableToOfferInsuranceCover' | message | async)"></div>
      </div>
    </div>
    <div class="components-actions" *ngIf="!(submitted$ | async)">
      <ng-container *ngIf="(currentStep$ | async) == (totalSteps$ | async)">
        <app-button *featuretoggle="submitQuestionnaireFeatureToggle" loading class="" title="Submit" icon="east"
          (onClick)="onSubmitClick(form.value)" class="" [isDisabled]="form.isInvalid || (isSubmitting$|async)" loading>
        </app-button>
      </ng-container>

      <app-button loading class="" title="Next" icon="east" (onClick)="onNextClick()" class=" "
        *ngIf="(currentStep$ | async) > 0 && (currentStep$ | async) < (totalSteps$ | async)"
        [isDisabled]="form.isInvalid">
      </app-button>

      <app-button color="light" loading title="Previous" icon="west" class="back mr-2" (onClick)="onPreviousClick()"
        *ngIf="(currentStep$ | async) != 1 && (currentStep$ | async) > 0" [isDisabled]="(isSubmitting$|async)">
      </app-button>

      <app-button title="Close" icon="arrow_right" (onClick)="onReturnClick()" *ngIf="(currentStep$ | async) < 0">
      </app-button>
    </div>
    <div class="components-actions" *ngIf="(submitted$ | async); let data">

      <app-button loading class="" title="Submit" icon="east" (onClick)="onConfirmClick(data.value)"
        *ngIf="!(confirmedDate$ | async) && !(elevatedAccess$ | async)"
        [isDisabled]="!hasPanelOpened || !form.value.takeReasonableCare || !form.value.confirmReadAndAcceptInsuranceGuide || (isSubmitting$|async)"
        loading>
      </app-button>

      <app-button color="light" loading title="Previous" icon="west" class="back mr-2" (onClick)="onPreviousClick()"
        *ngIf="!(confirmedDate$ | async) && !(elevatedAccess$ | async)" [isDisabled]="(isSubmitting$|async)">
      </app-button>

      <app-button loading class="" title="Return to Dashboard" icon="arrow_right" (onClick)="onReturnClick()"
        *ngIf="(confirmedDate$ | async)" [isDisabled]="form.isInvalid">
      </app-button>
    </div>

  </form>
</div>