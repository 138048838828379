import { Action, createReducer, on } from "@ngrx/store";
import { createFormGroupState, onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { requiredTrue } from "ngrx-forms/validation";
import { StartAPensionReviewForm, IStartAPensionReviewComponentState } from "./state";
import { ApplicationSubmittedAction, ResetComponentStateAction } from "./actions";

export const initialState: IStartAPensionReviewComponentState = {
    form: createFormGroupState('StartAPensionReviewForm', new StartAPensionReviewForm()),
    applicationSubmitted: null,
}

export const validateReviewForm = updateGroup<StartAPensionReviewForm>({
    disclaimer: validate(requiredTrue),
})

const reducer = createReducer(initialState,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, action) => {
        return state;
    }),
    on(ResetComponentStateAction, (_state) => initialState),
    on(ApplicationSubmittedAction, (state) => {
        return {
            ...state,
            applicationSubmitted: true,
        }
    }),
);

const editReviewReducerFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateReviewForm
)

export function StartAPensionReviewComponentReducer(state: any | undefined, action: Action) {
    return editReviewReducerFormState(state, action);
}