<div id="kiwi-saver-form" *ngIf="model$ | async; let model" appanimatecss="animate__fadeIn">
    <h2>Transfer to KiwiSaver</h2>
    <div *ngIf="combinedFormData$ | async; let  combinedForm;">
        <form appanimatecss="animate__fadeIn" *ngIf="(kiwiSaverStep$ | async) === 1"
            [ngrxFormState]="combinedForm.formOne" class="ml-3">
            <div class="question d-flex flex-row description mt-2">
                <div class="pr-5" [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                    <div class="title mb-1">{{model.formLabels.currentEmployerName}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry [control]=" combinedForm.formOne.controls.currentEmployerName"></app-entry>
                    </div>
                </div>
            </div>
            <div class="question d-flex flex-row flex-wrap description mt-2 ">
                <div>
                    <div class="title">{{model.formLabels.lastAusEmployerConfirmation}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio list="yesNo"
                            [control]=" combinedForm.formOne.controls.isCurrentEmployerLastAusEmployer"></app-entry-radio>
                    </div>
                </div>
            </div>
            <div *ngIf="!combinedForm.formOne.value.isCurrentEmployerLastAusEmployer"
                class="question d-flex flex-row description mt-4">
                <div class="pr-5" [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                    <div class="title mb-1">{{model.formLabels.lastAusEmployer}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry [control]=" combinedForm.formOne.controls.lastAusEmployerName"></app-entry>
                    </div>
                </div>
            </div>
            <div class="question d-flex flex-row description pr-5"
                [ngClass]="{'mt-2':!combinedForm.formOne.value.isCurrentEmployerLastAusEmployer, 'mt-4':combinedForm.formOne.value.isCurrentEmployerLastAusEmployer}">
                <div>
                    <div class="title">{{model.formLabels.lastAusEmploymentDate}}</div>
                    <div class="custom-control answer">
                        <app-entry-date [maxdate]="maxDate"
                            [date]="combinedForm.formOne.controls.lastAusEmploymentDate"></app-entry-date>
                    </div>
                </div>
            </div>
            <ng-container *featuretoggle="employerConfirmationFeatureToggle">
                <div *ngIf="model.isEmployerConfirmationRequired"
                    [ngClass]="{'mt-2':!combinedForm.formOne.value.isCurrentEmployerLastAusEmployer, 'mt-4':combinedForm.formOne.value.isCurrentEmployerLastAusEmployer}"
                    class="question d-flex flex-row flex-wrap description">
                    <div class="pr-5 w-100">
                        <div class="title mb-1">{{model.formLabels.employerConfirmationInput}}</div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-radio list="yesNo"
                                [control]=" combinedForm.formOne.controls.isEmployerConfirmationRequested"></app-entry-radio>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
        <form appanimatecss="animate__fadeIn" *ngIf="(kiwiSaverStep$ | async) === 2"
            [ngrxFormState]="combinedForm.formTwo">
            <div class="question d-flex flex-column description mt-2 pr-5 pl-2">
                <div class="title mb-1">{{model.formLabels.lastAusAddress}}</div>
                <div class="custom-control custom-radio answer increase-z-index">
                    <app-entry-address-accurity [allowOverseasAddress]="false" country="AU" [showTitleRow]="false"
                        [address]="combinedForm.formTwo.controls.lastAusResidentialAddress"
                        [isSearchMode]="model.submissionData.lastAusResidentialAddress ? false : true" class="w-100">
                    </app-entry-address-accurity>
                </div>
            </div>
            <div class="question d-flex flex-column description pt-2 pr-5 pl-2">
                <div class="title mb-1">{{model.formLabels.nzAddress}}</div>
                <div class="custom-control custom-radio answer">
                    <app-entry-address-accurity [allowOverseasAddress]="true" [isSearchMode]="false" country="NZ"
                        [showOverseasToggle]="false" [showTitleRow]="false"
                        [address]="combinedForm.formTwo.controls.nzResidentialAddress" class="w-100">
                    </app-entry-address-accurity>
                </div>
            </div>
        </form>
        <form appanimatecss="animate__fadeIn" *ngIf="(kiwiSaverStep$ | async) === 3"
            [ngrxFormState]="combinedForm.formThree">
            <div class="question d-flex flex-row flex-wrap description mt-2 pl-2">
                <div class="pr-5" [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                    <div class="title mb-1">{{model.formLabels.irdNumber}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry [control]="combinedForm.formThree.controls.nzIrdNumber"></app-entry>
                    </div>
                </div>
                <div class="pr-5" [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                    <div class="title mb-1">{{model.formLabels.kiwisaverSchemeName}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry [control]="combinedForm.formThree.controls.kiwiSaverSchemeName"></app-entry>
                    </div>
                </div>
            </div>
            <div class="question d-flex flex-row flex-wrap description mt-2 pl-2">
                <div class="pr-5" [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                    <div class="title mb-1">{{model.formLabels.registrationNumber}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry
                            [control]="combinedForm.formThree.controls.kiwiSaverSchemeRegistrationNumber"></app-entry>
                    </div>
                </div>
                <div class="pr-5" [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                    <div class="title mb-1">{{model.formLabels.schemeAccountNumber}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry [control]="combinedForm.formThree.controls.kiwiSaverSchemeAccountNumber"></app-entry>
                    </div>
                </div>
            </div>
            <div class="question d-flex flex-column description pt-2 pr-5 pl-2">
                <div class="title mb-1">{{model.formLabels.schemeAddress}}</div>
                <div class="custom-control custom-radio answer increase-z-index">
                    <app-entry-address-accurity [allowOverseasAddress]="true" [isSearchMode]="false" country="NZ"
                        [showOverseasToggle]="false" [showTitleRow]="false"
                        [address]="combinedForm.formThree.controls.schemePostalAddress" class="w-100">
                    </app-entry-address-accurity>
                </div>
            </div>
        </form>
        <div class="w-75 flex-row m-auto" [ngClass]="{'d-flex ': screenWidth>991, 'd-none': screenWidth<992}">
            <app-access-your-super-stepper #stepper [stepTitles]="stepperData"
                [onSelectionChangeFn]="onStepperSelectionChange"></app-access-your-super-stepper>
        </div>

        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                    [isDisabled]="isSubmitting" color="{{'BTN.access-your-super.cancel.color' | translate}}"
                    (onClick)="exitForm()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.access-your-super.back.class' | translate}}" title="Back"
                    icon="{{'BTN.access-your-super.back.icon' | translate}}" [isDisabled]="isSubmitting"
                    color="{{'BTN.access-your-super.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                    color="{{'BTN.access-your-super.continue.color' | translate}}" [isDisabled]="(combinedForm.formOne.isInvalid && (kiwiSaverStep$ | async) === 1) ||
                    (combinedForm.formTwo.isInvalid && (kiwiSaverStep$ | async) === 2) ||
                    (combinedForm.formThree.isInvalid && (kiwiSaverStep$ | async) === 3) || isSubmitting" loading
                    (onClick)="goNextStep()"></app-button>
            </div>
        </div>
    </div>
</div>