import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { StartAPensionInitialFormModel } from "src/app/model/start-a-pension-form.model";

export interface IStartAPensionEligibilityFormComponentState {
    formData: StartAPensionInitialFormModel;
    form: FormGroupState<StartAPensionEligibilityForm>;
    typeOfAccounts: KeyValueModel[];
    conditionsOfRelease: KeyValueModel[];
}

export class StartAPensionEligibilityForm {
    sapAusCitizen: boolean = true;
    sapTypeOfAccount: number = null;
    sapConditionOfRelease: number = null;
    otherReasonText: string = '';
    hasReachedPreservationAge: boolean = false;
    wording: { [key: string]: string }
}