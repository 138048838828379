import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { StartAPensionService } from 'src/app/services/start-a-pension.service';
import { SetApplicationSavedBankDetailsAction } from '../actions';

@Injectable()
export class StartAPensionBankDetailsFormComponentEffects {
    constructor(private actions$: Actions,
        private service: StartAPensionService) {
    }

    searchBankName$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.SearchBankNameAction),
        switchMap((action: any) => this.service.searchBankName(action.bsb).pipe(
            map((data: any) => {
                return ActionsList.SetBankNameAction({ payload: data.data });
            }),
            catchError(err => {
                return EMPTY;
            })
        )),
    ));


    saveBankDetails$ = createEffect(() => this.actions$.pipe(
        ofType(ActionsList.SubmitFormAction),
        switchMap((action: any) => this.service.saveBankDetails(action.accountId, action.applicationId, action.submission).pipe(
            map((_data: any) => {
                return SetApplicationSavedBankDetailsAction({ bankDetails: action.submission })
            }),
            catchError(_err => {
                return of(ActionsList.SubmitFormErrorAction());
            })
        )),
    ))
}
