import { createAction, props } from "@ngrx/store";
import { PensionApplicationBankDetailsFormModel, PensionApplicationBankDetailsSubmissionModel, } from "src/app/model/start-a-pension-form.model";

export const SetBankDetailsFormDataAction = createAction('[Start a pension bank details form component] set initial form data', props<{ data: PensionApplicationBankDetailsFormModel }>())
export const ResetComponentStateAction = createAction('[Start a pension bank details form component] reset component state');

export const SubmitFormAction = createAction('[Start a pension bank details form component] submit form', props<{ accountId: number, applicationId: number, submission: PensionApplicationBankDetailsSubmissionModel }>());
export const SubmitFormErrorAction = createAction('[Start a pension bank details form component] submit form error');

export const SearchBankNameAction = createAction('[Start a pension bank details form component] search bank name', props<{ bsb: string }>());
export const SetBankNameAction = createAction('[Start a pension bank details form component] set bank name', props<{ payload: string }>());