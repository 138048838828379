import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Helper, KeyValueModel } from "@ifaa-components/ui-components";
import { select, Store } from "@ngrx/store";
import { SetErrorsAction, SetValueAction } from "ngrx-forms";
import { distinct, distinctUntilChanged, pairwise } from "rxjs/operators";
import { AccessYourSuperPaymentTypeEnum } from "src/app/enums/access-your-super-payment-type.enum";
import { AccessYourSuperPaymentDetailSubmissionModel } from "src/app/model/access-your-super-custom-sections.model";
import { AvailableAccountBalanceModel } from "src/app/model/available-account-balance.model";
import { AppState } from "src/app/store/app.states";
import { ComponentBase } from "src/app/views/component-base";
import { SetCurrentStepAction } from "../../access-your-super-form/actions";
import { RequestAction } from "../access-your-super-ceased-paid-employment/actions";
import { ResetComponentStateAction, SaveFormDataAction, SetTaxOptionsAction } from "./actions";
import { accessYourSuperCeasedPaidEmployment_CurrentTotalPercent, accessYourSuperCeasedPaidEmployment_Form, accessYourSuperCeasedPaidEmployment_Model, accessYourSuperCeasedPaidEmployment_SavedFormData, accessYourSuperCeasedPaidEmployment_TaxOptions } from "./selectors";
import { InvestmentItemModel } from "src/app/model/investment-item.model";
import { commonState_SystemConfig_AccountFeatureToggles } from "src/app/store/common/common.selectors";
import { FeatureToggleName } from "src/app/model/feature-toggle-name.model";
import { FeatureToggleHelper } from "src/app/helper/featureToggleHelper";
import { memberAccountDropdown_SelectedAccount } from "../../member-account-dropdown/selectors";
import { combineLatest } from "rxjs";

@Component({
    selector: 'app-access-your-super-ceased-paid-employment',
    templateUrl: './access-your-super-ceased-paid-employment.component.html',
    styleUrls: ['./access-your-super-ceased-paid-employment.component.scss'],
    host: {
        class: 'w-100'
    }
})

export class AccessYourSuperCeasedPaidEmploymentComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set nextStep(value: number) {
        this.step = value;
    }
    @Input() set applicationId(value: number) {
        this.id = value;
    }
    @Input() set backStep(value: number) {
        this.previousStep = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }
    @Input() set data(value: AvailableAccountBalanceModel) {
        this.accountBalance = value;
    }
    @Input() set typeId(value: number) {
        this.applicationTypeId = value;
    }
    @Input() set canGoBack(value: boolean) {
        this.showBackButton = value;
    }
    @Input() set submitting(value: boolean) {
        this.isSubmitting = value;
    }

    model$ = this.store.pipe(select(accessYourSuperCeasedPaidEmployment_Model));
    form$ = this.store.pipe(select(accessYourSuperCeasedPaidEmployment_Form));
    taxOptions$ = this.store.pipe(select(accessYourSuperCeasedPaidEmployment_TaxOptions));
    savedFormData$ = this.store.pipe(select(accessYourSuperCeasedPaidEmployment_SavedFormData));
    currentTotalPercent$ = this.store.pipe(select(accessYourSuperCeasedPaidEmployment_CurrentTotalPercent));
    featureToggles$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));
    selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

    step: number = null;
    previousStep: number = null
    id: number = null;
    applicationTypeId: number = null;
    paymentDetailId: number = null;
    accountId: number = null;
    accountBalance: AvailableAccountBalanceModel = null;
    showBenefitAmountTooltip: boolean = false;
    featureToggleHelper: FeatureToggleHelper = new FeatureToggleHelper();
    isSubmitting: boolean = false;
    showBackButton: boolean = false;

    constructor(
        public store: Store<AppState>,
        private router: Router,) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();

        this.sub = combineLatest([this.selectedAccount$, this.featureToggles$]).subscribe(x => {
            if (x[0] && x[1]) {
                this.showBenefitAmountTooltip = this.featureToggleHelper.checkToggle(FeatureToggleName.member.account.accessYourSuper.benefitAmountTooltip.view,
                    x[1], x[0], true);
            }
        })

        this.sub = this.model$.pipe(distinct())
            .subscribe(async x => {
                if (!x) {
                    this.dispatch(this.store, RequestAction({ accountId: this.accountId, applicationId: this.id, applicationTypeId: this.applicationTypeId }))
                }

                if (x) {
                    var helper = new Helper();
                    var form = await helper.getValue(this.form$);


                    var taxOptions = [] as KeyValueModel[];
                    taxOptions.push({ key: true, value: x.formLabels.beforeTax });
                    taxOptions.push({ key: false, value: x.formLabels.afterTax });
                    this.dispatch(this.store, SetTaxOptionsAction({ payload: taxOptions }))

                    var investmentOptions = Object.assign([], x.currentInvestmentMix);

                    this.store.dispatch(new SetValueAction(form.controls.restrictedNonPreservedAmount.id, this.accountBalance.restrictedNonPreserved));

                    // update form values if we are updating an existing application
                    if (x.submissionData) {
                        this.paymentDetailId = x.submissionData.accessYourSuperPaymentDetailId;

                        if (x.submissionData.paymentTypeId !== AccessYourSuperPaymentTypeEnum.PartialAmount) {
                            this.store.dispatch(new SetValueAction(form.controls.paymentType.id, 0));
                        }
                        else {
                            this.store.dispatch(new SetValueAction(form.controls.paymentType.id, 1));
                        }

                        x.submissionData.isBeforeTax === true || x.submissionData.isBeforeTax === false ?
                            this.store.dispatch(new SetValueAction(form.controls.isBeforeTax.id, x.submissionData.isBeforeTax)) :
                            null;

                        x.submissionData.benefitAmount ?
                            this.store.dispatch(new SetValueAction(form.controls.partialAmountSpecified.id, x.submissionData.benefitAmount)) :
                            null;

                        x.submissionData.partialWithdrawalInvestmentSelection === true || x.submissionData.partialWithdrawalInvestmentSelection === false ?
                            this.store.dispatch(new SetValueAction(form.controls.partialWithdrawalSelection.id, x.submissionData.partialWithdrawalInvestmentSelection)) :
                            null;

                        // update the investmentOptions in form state if the member saved a custom drawdown
                        if (x.submissionData.partialWithdrawalInvestmentSelection) {
                            // if we have any specified investment options saved that are not in the current investment mix, we need to discard all of those that were saved
                            var drawdownMatchesCurrentInvestmentMix = true;

                            x.submissionData.investmentDrawdown.forEach(drawDown => {
                                var investmentOptionIndex = investmentOptions?.findIndex(o => o.optionId === drawDown.optionId)

                                // if we dont get an investmentOption, set the flag to false. Otherwise update the investmentOptions array with the saved percentage
                                if (investmentOptionIndex === -1)
                                    drawdownMatchesCurrentInvestmentMix = false;
                                else {
                                    investmentOptions[investmentOptionIndex] = { ...investmentOptions[investmentOptionIndex], percentage: drawDown.percentage }
                                }
                            })

                            // if our current investment mix matches the saved options, update the form value with the updated investmentOptions array
                            // if it doesnt match, update the form value with the members current investment mix so they will have to reselect an investment drawdown
                            if (drawdownMatchesCurrentInvestmentMix) {
                                this.store.dispatch(new SetValueAction(form.controls.investmentOptionDrawdown.id, investmentOptions));
                            }
                            else {
                                this.store.dispatch(new SetValueAction(form.controls.investmentOptionDrawdown.id, x.currentInvestmentMix));
                            }
                        }
                        // if they didn't specify a custom drawdown, set the options with their investment mix
                        else {
                            this.store.dispatch(new SetValueAction(form.controls.investmentOptionDrawdown.id, investmentOptions));
                        }
                    }
                    else {
                        this.store.dispatch(new SetValueAction(form.controls.investmentOptionDrawdown.id, investmentOptions));
                    }
                }
            })

        this.sub = this.form$.pipe(pairwise(), distinctUntilChanged((x, y) => {
            // check if text box value is NaN to avoid infinite loop..
            if (Number.isNaN(y[1].value.partialAmountSpecified))
                return true;

            if (x[1].value.paymentType !== y[1].value.paymentType ||
                x[1].value.investmentOptionDrawdown !== y[1].value.investmentOptionDrawdown ||
                x[1].value.partialAmountSpecified !== y[1].value.partialAmountSpecified ||
                x[1].value.partialWithdrawalSelection !== y[1].value.partialWithdrawalSelection)
                return false;

            return true;
        })).subscribe(form => {
            if (form) {
                // If user has swapped from partial amount back to full amount, reset all form validations
                if (form[0].value.paymentType === 1 && form[1].value.paymentType === 0) {
                    this.store.dispatch(new SetErrorsAction(form[1].controls.partialAmountSpecified.id, {}));
                    this.store.dispatch(new SetErrorsAction(form[1].controls.isBeforeTax.id, {}));
                }

                // Reset validation if user swaps from custom selection to auto selection of investment dropdown
                if (form[0].value.partialWithdrawalSelection !== form[1].value.partialWithdrawalSelection) {
                    this.store.dispatch(new SetErrorsAction(form[1].controls.investmentOptionDrawdown.id, {}));
                }

                if (form[1].value.paymentType === 1 && form[1].value.partialWithdrawalSelection) {
                    var drawdownTotal = 0;
                    var calculatedAmountHigherThanBalance = false;

                    form[1].value.investmentOptionDrawdown.forEach((investmentOption, index) => {
                        drawdownTotal += investmentOption.percentage;
                        if (!calculatedAmountHigherThanBalance) {
                            calculatedAmountHigherThanBalance = investmentOption.amount < (form[1].value.partialAmountSpecified * (investmentOption.percentage / 100))
                        }
                    })

                    // If drawdownTotal is 100, remove error. If it is not 100, set validation error
                    if (drawdownTotal === 100 && !calculatedAmountHigherThanBalance) {
                        this.store.dispatch(new SetErrorsAction(form[1].controls.investmentOptionDrawdown.id, {}));
                    } else {
                        var errors = {};

                        if (drawdownTotal !== 100) {
                            errors['percentageTotal'] = 'Total must be 100.'
                        }

                        if (calculatedAmountHigherThanBalance) {
                            errors['calculatedTotal'] = "A calculated amount cannot be higher than the investment accounts balance."
                        }
                        this.store.dispatch(new SetErrorsAction(form[1].controls.investmentOptionDrawdown.id, errors));
                    }
                }
            }
        })


        // Go next step when form has been successfully saved
        this.sub = this.savedFormData$.subscribe(x => {
            if (x) {
                this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.step }))
            }
        })
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
        this.dispatch(this.store, ResetComponentStateAction());
    }

    exitForm() {
        this.router.navigate(['/access-your-super']);
    }

    async goNextStep() {
        var helper = new Helper();
        var form = await helper.getValue(this.form$);

        var paymentTypeId = form.value.paymentType === 0 ? AccessYourSuperPaymentTypeEnum.FullAmountRestrictedNonPreserved : AccessYourSuperPaymentTypeEnum.PartialAmount;

        var investmentDrawdown = form.value.investmentOptionDrawdown.map(investmentOption => ({
            optionId: investmentOption.optionId,
            option: investmentOption.option,
            amount: investmentOption.amount,
            percentage: investmentOption.percentage
        } as InvestmentItemModel));

        var payload = {
            accessYourSuperPaymentDetailId: this.paymentDetailId,
            accessYourSuperApplicationId: this.id,
            paymentTypeId: paymentTypeId,
            benefitAmount: paymentTypeId === AccessYourSuperPaymentTypeEnum.PartialAmount ? form.value.partialAmountSpecified : null,
            isBeforeTax: paymentTypeId === AccessYourSuperPaymentTypeEnum.PartialAmount ? form.value.isBeforeTax : null,
            partialWithdrawalInvestmentSelection: paymentTypeId === AccessYourSuperPaymentTypeEnum.PartialAmount ? form.value.partialWithdrawalSelection : null,
            investmentDrawdown: paymentTypeId === AccessYourSuperPaymentTypeEnum.PartialAmount && form.value.partialWithdrawalSelection ? investmentDrawdown : null,
        } as AccessYourSuperPaymentDetailSubmissionModel;

        this.dispatch(this.store, SaveFormDataAction({ accountId: this.accountId, payload: payload }));
    }

    goBack() {
        this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.previousStep }))
    }
}
