import { FormGroupState } from 'ngrx-forms';
import { InsuranceChangeRequestHistoryModel } from './../../../../model/insurance-details.model';

export interface IInsuranceUpdateHistoryComponentState {
    model?: InsuranceChangeRequestHistoryModel[];
    filter: FormGroupState<InsuranceUpdateHistoryFilter>;
    isLoading: boolean;
}

export class InsuranceUpdateHistoryFilter {
    currentPage: number = 0;
}
