<div id="insurance-details-container" *ngIf="(model$ | async); let data">
  <div *ngIf="data.hasInsurance">
    <table class="table {{'VIEWS.Global.table-default-style' | translate}}">
      <thead>
        <tr>
          <th scope="col">Cover Type</th>
          <th scope="col" [innerHTML]="'VIEWS.Insurance.PREMIUM_AMOUNT_LABEL' | translate"></th>
          <th scope="col">Cover Amount</th>
          <th scope="col">Waiting Period</th>
          <th scope="col" *ngIf="'VIEWS.Insurance.SHOW_LAPSE_COLUMN' | translate">Lapsed</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data.insurances">
          <th>{{item.insuranceCoverTypeDescription}}</th>
          <th>{{item.premium | currency | dash}} <mat-icon class="ml-2 help-icon help-position"
              *ngIf="item.insuranceCoverStatusCode == 'IP'"
              matTooltip="{{item.insuranceCoverToolTipText}}">help_outline</mat-icon> </th>
          <th>{{item.amount | currency | dash}} <mat-icon class="ml-2 help-icon help-position"
              *ngIf="item.insuranceCoverTypeCode == 'I'"
              matTooltip="The cover amount stated here is the Annual Cover Amount.">help_outline</mat-icon> </th>
          <th>{{item.waitingPeriod}}</th>
          <th *ngIf="'VIEWS.Insurance.SHOW_LAPSE_COLUMN' | translate">{{item.lapseDate | date:'dd/MM/yyyy'}}</th>
        </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-end action-row" *featuretoggle="insuranceUpdateToggle">
      <app-button title="View change requests" icon="{{'BTN.insurance.update.icon' | translate}}"
        color="{{'BTN.insurance.update.color' | translate}}" (onClick)="viewHistory()"></app-button>
      <app-button title="Reduce or cancel your cover" icon="{{'BTN.insurance.update.icon' | translate}}"
        color="{{'BTN.insurance.update.color' | translate}}" (onClick)="updateOrCancelInsurance()"></app-button>
    </div>




  </div>
  <div *ngIf="!data.hasInsurance">
    <div *ngIf="(selectedAccount$ | async); let account">
      <div fundComponentLoader="NoInsurance" [fund]="account.fundCode" [data]="data"></div>
    </div>
    <div class="d-flex justify-content-end action-row" *featuretoggle="insuranceUpdateToggle">
      <app-button title="View change requests" icon="{{'BTN.insurance.update.icon' | translate}}"
        color="{{'BTN.insurance.update.color' | translate}}" (onClick)="viewHistory()"></app-button>
    </div>
  </div>
</div>