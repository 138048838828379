import { createReducer, on, Action } from '@ngrx/store';
import {
    createFormGroupState,
    onNgrxForms, onNgrxFormsAction, setValue, SetValueAction,
    updateGroup,
    validate,
    wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { IStartAPensionBankDetailsFormComponentState, StartAPensionBankDetailsForm } from './state';
import { ResetComponentStateAction, SetBankDetailsFormDataAction, SetBankNameAction } from './actions';
import { required } from 'ngrx-forms/validation';

const formName = 'StartAPensionBankDetailsForm';

export const state: IStartAPensionBankDetailsFormComponentState = {
    formData: null,
    form: createFormGroupState(formName, new StartAPensionBankDetailsForm()),
};

export const validateStartAPensionBankDetailsForm = updateGroup<StartAPensionBankDetailsForm>({
    financialInstitutionName: validate(required),
    bankAccountBsb: (state, parentState) => {
        const invalidBsb = () => {
            const errors = {};
            if (!parentState.value.financialInstitutionName || parentState.value.financialInstitutionName === "") {
                errors['invalidBsb'] = 'The BSB entered did not match anything in our system.'
            }
            return errors;
        }
        state = validate(state, required, invalidBsb)
        return state;
    },
    bankAccountName: validate(required),
    bankAccountNumber: (state, parentState) => {
        const validateAccountNumber = () => {
            const errors = {};
            const regex = /^(?!0+$)\d{6,10}$/;
            let isValidAccountNumber = regex.test(parentState.value.bankAccountNumber);
            if (!isValidAccountNumber) {
                errors['customError'] = 'Your bank account number must be between 6 - 10 digits.'
                return errors;
            }
        }
        state = validate(state, required, validateAccountNumber)
        return state;
    }
})

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, _action) => {
        return state;
    }),
    on(SetBankDetailsFormDataAction, (state, { data }) => {
        return {
            ...state,
            formData: data,
            form: setValue(Object.assign(new StartAPensionBankDetailsForm(),
                {
                    ...state.form.value,
                    financialInstitutionName: data.financialInstitutionName,
                    bankAccountBsb: data.bankAccountBsb,
                    bankAccountName: data.bankAccountName,
                    bankAccountNumber: data.bankAccountNumber
                }
            ))(state.form),
        }
    }),
    on(ResetComponentStateAction, (_state) => state),
    on(SetBankNameAction, (state, { payload }) => {
        return {
            ...state,
            form: setValue(Object.assign(new StartAPensionBankDetailsForm(),
                {
                    ...state.form.value,
                    financialInstitutionName: payload
                }
            ))(state.form)
        }
    }),
);

const editStartAPensionBankDetailsFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateStartAPensionBankDetailsForm
)

export function startAPensionBankDetailsFormComponentReducer(state: any | undefined, action: Action) {
    return editStartAPensionBankDetailsFormState(state, action);
}
