import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ComponentBase } from "src/app/views/component-base";
import { AppState } from "src/app/store/app.states";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { Helper } from "@ifaa-components/ui-components";
import { commomState_ElevatedAccess } from "src/app/store/common/common.selectors";
import { StartAPensionFormModel } from "src/app/model/start-a-pension-form.model";
import { startAPensionReview_ApplicationSubmitted, startAPensionReview_Form } from "./selector";
import { ResetComponentStateAction, SubmitApplicationAction } from "./actions";
import { ResetParentFormStateAction, SetCurrentStepAction } from "../actions";

@Component({
  selector: 'app-start-a-pension-review',
  templateUrl: './start-a-pension-review.component.html',
  styleUrls: ['./start-a-pension-review.component.scss']
})

export class StartAPensionReviewComponent extends ComponentBase implements OnInit, OnDestroy {
  helper = new Helper();

  @Output() onSubmissionSuccesful: EventEmitter<any> = new EventEmitter();

  @Input() set data(value: StartAPensionFormModel) {
    this.formData = value;
  }
  @Input() set account(value: number) {
    this.accountId = value;
  }
  @Input() set application(value: number) {
    this.applicationId = value;
  }
  @Input() set submitting(value: boolean) {
    this.isSubmitting = value;
  }

  form$ = this.store.pipe(select(startAPensionReview_Form));
  applicationSubmitted$ = this.store.pipe(select(startAPensionReview_ApplicationSubmitted));
  elevatedAccess$ = this.store.pipe(select(commomState_ElevatedAccess));
  elevatedAccess = false;

  formData: StartAPensionFormModel = null;
  applicationId: number = null;
  accountId: number = null;
  applicationSubmitted: boolean = null;
  isSubmitting: boolean = false;

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  async ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.elevatedAccess$.subscribe(x => {
      this.elevatedAccess = x;
    })

    this.sub = this.applicationSubmitted$.subscribe(x => {
      if (x) {
        this.applicationSubmitted = x;
        this.onSubmissionSuccesful.emit(x);
      }
    })

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
    this.store.dispatch(ResetComponentStateAction())
  }

  exit() {
    this.store.dispatch(ResetParentFormStateAction());
    this.store.dispatch(ResetComponentStateAction())
    this.router.navigate(['/start-a-pension'])
  }

  goBack() {
    this.store.dispatch(SetCurrentStepAction({ step: 6 }))
  }

  async submitApplication() {
    if (this.elevatedAccess) return;
    this.dispatch(this.store, SubmitApplicationAction({ accountId: this.accountId, applicationId: this.applicationId }))
  }
}
