<div id="start-a-pension-bank-details">
    <h2 class="mb-3">Bank account details</h2>
    <div *ngIf="form$|async; let form">
        <form [ngrxFormState]="form" class="ml-3 mr-3">
            <p>{{formData.customWording.bankDetailsInfo}}</p>

            <div class="mb-3"
                *ngIf="form.value.financialInstitutionName && form.value.financialInstitutionName.length>0">
                <app-readonly title="Name of financial institution" [value]="form.value.financialInstitutionName"
                    class="w-50 flex-fill"></app-readonly>
            </div>
            <div class="question d-flex flex-column pl-1 pr-3 description">
                <div class="title d-flex flex-row align-items-center">Names(s) on account:
                    <mat-icon class="ml-2 tooltip-readonly"
                        [matTooltip]="formData.customWording.bankAccountNameTooltip">help_outline</mat-icon>
                </div>
                <app-entry title="" [control]="form.controls.bankAccountName"></app-entry>
            </div>
            <div class="question d-flex flex-row flex-wrap description">
                <div class="pl-1 col-12 col-md-6">
                    <div class="title d-flex flex-row align-items-center">BSB:</div>
                    <app-entry title="" [control]="form.controls.bankAccountBsb"></app-entry>
                </div>
                <div class="pl-1 col-12 col-md-6">
                    <div class="title d-flex flex-row align-items-center">Account Number:</div>
                    <app-entry title="" [control]="form.controls.bankAccountNumber"></app-entry>
                </div>
            </div>

        </form>
        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                    [isDisabled]="isSubmitting" color="{{'BTN.start-a-pension.cancel.color' | translate}}"
                    (onClick)="exit()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.start-a-pension.back.class' | translate}}" title="Back"
                    icon="{{'BTN.start-a-pension.back.icon' | translate}}" [isDisabled]="isSubmitting"
                    color="{{'BTN.start-a-pension.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                    color="{{'BTN.start-a-pension.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || isSubmitting" loading
                    (onClick)="saveBankDetails(form.value)"></app-button>
            </div>
        </div>
    </div>
</div>