import { createReducer, on, Action } from '@ngrx/store';
import {
    onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { IStartAPensionFormComponentState } from './state';
import { ApplicationDataResponseAction, GetInitialFormDataResponseAction, IncrementCurrentStepWithApiResponseAction, ResetParentFormStateAction, SetApplicationDocumentsAction, SetApplicationIdAction, SetApplicationSavedAccountDetailsAction, SetApplicationSavedBankDetailsAction, SetApplicationSavedBeneficiaryAction, SetApplicationSavedDocumentsAction, SetApplicationSavedPaymentDetailsAction, SetCurrentStepAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';


export const state: IStartAPensionFormComponentState = {
    currentStep: null,
    applicationData: null,
    applicationId: null,
    initialFormData: null,
};

const helper: Helper = new Helper();

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, _action) => {
        return state;
    }),
    on(ResetParentFormStateAction, (_state) => state),
    on(SetCurrentStepAction, (state, { step }) => {
        return {
            ...state,
            currentStep: step
        }
    }),
    on(GetInitialFormDataResponseAction, (state, { data }) => {
        return {
            ...state,
            initialFormData: data
        }
    }),
    on(SetApplicationIdAction, (state, { applicationId }) => {
        return {
            ...state,
            applicationId
        }
    }),
    on(ApplicationDataResponseAction, (state, { applicationData }) => {
        return {
            ...state,
            applicationData,
            currentStep: applicationData.pensionApplicationBaseDetails.currentStep,
            applicationId: applicationData.pensionApplicationBaseDetails.pensionApplicationId
        }
    }),
    on(IncrementCurrentStepWithApiResponseAction, (state) => {
        const step = state.currentStep;
        return {
            ...state,
            currentStep: step + 1,
            applicationData: {
                ...state.applicationData,
                pensionApplicationBaseDetails: {
                    ...state.applicationData.pensionApplicationBaseDetails,
                    currentStep: step + 1
                }
            }
        }
    }),
    on(SetApplicationSavedAccountDetailsAction, (state, { accountDetails }) => {
        return {
            ...state,
            currentStep: 3,
            applicationData: {
                ...state.applicationData,
                pensionApplicationBaseDetails: {
                    ...state.applicationData.pensionApplicationBaseDetails,
                    currentStep: 3
                },
                pensionApplicationAccountDetails: {
                    ...state.applicationData.pensionApplicationAccountDetails,
                    savedData: {
                        transferTypeId: accountDetails.transferType,
                        transferAmount: accountDetails.transferAmount,
                        currentOptions: accountDetails.currentOptions
                    }
                },
                pensionApplicationPaymentDetails: {
                    ...state.applicationData.pensionApplicationPaymentDetails,
                    savedData: {
                        ...state.applicationData.pensionApplicationPaymentDetails.savedData,
                        futureOptions: []
                    }
                }
            }
        }
    }),
    on(SetApplicationSavedPaymentDetailsAction, (state, { paymentDetails }) => {
        return {
            ...state,
            currentStep: 4,
            applicationData: {
                ...state.applicationData,
                pensionApplicationBaseDetails: {
                    ...state.applicationData.pensionApplicationBaseDetails,
                    currentStep: 4
                },
                pensionApplicationPaymentDetails: {
                    ...state.applicationData.pensionApplicationPaymentDetails,
                    savedData: {
                        frequencyId: paymentDetails.frequencyId,
                        pensionPaymentTypeId: paymentDetails.paymentTypeId,
                        paymentAmount: paymentDetails.paymentAmount,
                        paymentCommencementDate: paymentDetails.commencementOfPayments,
                        futureOptions: paymentDetails.futureOptions
                    }
                }
            }
        }
    }),
    on(SetApplicationSavedBankDetailsAction, (state, { bankDetails }) => {
        return {
            ...state,
            currentStep: 5,
            applicationData: {
                ...state.applicationData,
                pensionApplicationBankDetails: {
                    ...state.applicationData.pensionApplicationBankDetails,
                    bankAccountNumber: bankDetails.bankAccountNumber,
                    bankAccountBsb: bankDetails.bankAccountBsb,
                    bankAccountName: bankDetails.bankAccountName,
                    financialInstitutionName: bankDetails.financialInstituteName
                }
            }
        }
    }),
    on(SetApplicationSavedBeneficiaryAction, (state, { beneficiary }) => {
        const clone = helper.clone(beneficiary);

        const beneficiaryDetails = clone.beneficiaryAdded ? {
            beneficiaryName: clone.name,
            beneficiaryDob: clone.dob,
            beneficiaryRelationshipId: clone.relationshipId,
            genderId: clone.genderId,
            beneficiaryAddress: clone.address,
            isAddressSame: clone.isAddressSame
        } : null;

        if (beneficiaryDetails && !beneficiaryDetails.beneficiaryAddress.fullAddress) {
            beneficiaryDetails.beneficiaryAddress.fullAddress = `${beneficiaryDetails.beneficiaryAddress.lineOne}, ${beneficiaryDetails.beneficiaryAddress.suburb} ${beneficiaryDetails.beneficiaryAddress.state} ${beneficiaryDetails.beneficiaryAddress.postCode}, ${beneficiaryDetails.beneficiaryAddress.country}`;
        }

        return {
            ...state,
            currentStep: 6,
            applicationData: {
                ...state.applicationData,
                pensionApplicationBeneficiaryDetails: {
                    ...state.applicationData.pensionApplicationBeneficiaryDetails,
                    savedData: beneficiaryDetails
                }
            }
        }
    }),
    on(SetApplicationDocumentsAction, (state, { documents }) => {
        return {
            ...state,
            applicationData: {
                ...state.applicationData,
                pensionApplicationDocumentsDetails: {
                    ...state.applicationData.pensionApplicationDocumentsDetails,
                    documents: [...documents]
                }
            }
        }
    }),
    on(SetApplicationSavedDocumentsAction, (state) => {
        return {
            ...state,
            currentStep: 7
        }
    })
);

export function startAPensionFormComponentReducer(state: any | undefined, action: Action) {
    return reducer(state, action);
}
