<div id="dashboard-container">
    <div class="page-header">
        <app-toolbar-options icon="verified_user">
            <div class="d-flex flex-column" slot="start">
                <div class="page-title">{{'Insurance change requests' | textTransform}}</div>

            </div>
        </app-toolbar-options>
    </div>

    <div class="page-content" appanimatecss>
        <app-component-wrapper icon="admin_panel_settings" header="Insurance Change History">
            <div class="d-flex flex-column" slot="start">
                <app-insurance-update-history></app-insurance-update-history>
            </div>
        </app-component-wrapper>
    </div>
</div>