<div id="start-a-pension-beneficiary">
    <h2 class="mb-3">Reversionary Beneficiary</h2>
    <div *ngIf="form$|async; let form">
        <form [ngrxFormState]="form" class="ml-3 mr-3">
            <p [innerHtml]="formData.customWording.beneficiaryDisclaimer"></p>

            <div class="d-flex flex-row justify-content-end">
                <app-button *ngIf="!(beneficiaryAdded$|async)"
                    class="{{'BTN.start-a-pension.beneficiary.add.class' | translate}}" title="Add"
                    icon="{{'BTN.start-a-pension.beneficiary.add.icon' | translate}}"
                    color="{{'BTN.start-a-pension.beneficiary.add.color' | translate}}"
                    [isDisabled]="(submitting$|async)" (onClick)="addBeneficiary()"></app-button>
                <app-button *ngIf="(beneficiaryAdded$|async)"
                    class="{{'BTN.start-a-pension.beneficiary.delete.class' | translate}}" title="Delete"
                    icon="{{'BTN.start-a-pension.beneficiary.delete.icon' | translate}}"
                    color="{{'BTN.start-a-pension.beneficiary.delete.color' | translate}}"
                    [isDisabled]="(submitting$|async)" (onClick)="removeBeneficiary()"></app-button>
            </div>

            <div class="form-content" *ngIf="(beneficiaryAdded$|async)">

                <div class="question d-flex flex-row description mt-4 pl-2">
                    <div class="name-container">
                        <div class="title d-flex flex-row align-items-center">
                            {{formData.customWording.beneficiaryName}}:
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry title="" [control]="form.controls.name"></app-entry>
                        </div>
                    </div>
                </div>

                <div class="question d-flex flex-row description mt-4 pl-2">
                    <div class="flex-fill">
                        <div class="title d-flex flex-row align-items-center">
                            {{formData.customWording.beneficiaryDob}}:
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-date title="" [date]="form.controls.dob"></app-entry-date>
                        </div>
                    </div>
                </div>

                <div class="question d-flex flex-row description mt-4 pl-2">
                    <div>
                        <div class="title d-flex flex-row align-items-center">
                            {{formData.customWording.beneficiaryRelationship}}:
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-radio [customList]="relationshipTypes$"
                                [control]="form.controls.relationshipId"></app-entry-radio>
                        </div>
                    </div>
                </div>

                <div class="question d-flex flex-row description mt-4 pl-2">
                    <div>
                        <div class="title d-flex flex-row align-items-center">
                            {{formData.customWording.beneficiaryGender}}:
                            <mat-icon class="ml-2 tooltip-readonly"
                                [matTooltip]="formData.customWording.beneficiaryGenderTooltip">help_outline</mat-icon>
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-radio [customList]="genderTypes$"
                                [control]="form.controls.genderId"></app-entry-radio>
                        </div>
                    </div>
                </div>

                <div class="question d-flex flex-row description mt-4 pl-2">
                    <div>
                        <div class="title d-flex flex-row align-items-center">
                            {{formData.customWording.beneficiaryAddressIsSame}}
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-radio list="yesNo" [control]="form.controls.isAddressSame"></app-entry-radio>
                        </div>
                    </div>
                </div>

                <app-readonly *ngIf="form.value.isAddressSame" title="Postal address"
                    [value]="formData.memberPostalAddress.fullAddress"></app-readonly>


                <div *ngIf="!form.value.isAddressSame" class="question d-flex flex-row description mt-4 pl-2">
                    <div class="flex-fill">
                        <div class="title d-flex flex-row align-items-center">
                            {{formData.customWording.beneficiaryAddress}}:
                        </div>
                        <div class="address-container custom-control custom-radio answer">
                            <app-entry-address-accurity [isSearchMode]="form.value.isAddressSame"
                                [title]="formData.customWording.beneficiaryAddress" [allowOverseasAddress]="false"
                                country="AU" [showTitleRow]="false" [address]="form.controls.address"
                                class="w-100"></app-entry-address-accurity>
                        </div>
                    </div>
                </div>
            </div>

        </form>
        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                    [isDisabled]="(submitting$|async)" color="{{'BTN.start-a-pension.cancel.color' | translate}}"
                    (onClick)="exit()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.start-a-pension.back.class' | translate}}" title="Back"
                    icon="{{'BTN.start-a-pension.back.icon' | translate}}" [isDisabled]="(submitting$|async)"
                    color="{{'BTN.start-a-pension.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                    color="{{'BTN.start-a-pension.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || (submitting$|async)" loading
                    (onClick)="saveBeneficiary(form.value)"></app-button>
            </div>
        </div>
    </div>
</div>