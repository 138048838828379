import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';

/**
 * @title Stepper overview
 */
@Component({
    selector: 'app-start-a-pension-stepper',
    templateUrl: 'start-a-pension-progress-stepper.component.html',
    styleUrls: ['start-a-pension-progress-stepper.component.scss'],
    host: {
        class: "w-100"
    }
})
export class StartAPensionStepper extends ComponentBase implements AfterViewInit, OnInit, OnDestroy {

    @Input() set stepTitles(value: any[]) {
        this.titles = value;
    }

    @Input() set step(value: number) {
        this.currentStep = value;
    }

    @Input() onSelectionChangeFn: (titles: any[], event: any) => void;

    @ViewChild('stepper') private stepper: MatStepper;

    titles: any[];
    currentStep: number;
    nextValidStep: number = 0;

    constructor(public store: Store<AppState>, private cdRef: ChangeDetectorRef) {
        super();
    }

    ngAfterViewInit(): void {
        this.stepper.selectedIndex = this.currentStep - 1;
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        super.ngOnDestroyBase();
    }
    ngOnInit(): void {
        super.ngOnInitBase();
    }

    resetCompletion(index: number) {
        var step = this.stepper.steps.find((x, stepindex) => index === stepindex);
        step.completed = false;
    }

    setSelectedIndex(index: number) {
        const indexUpdate = index - 1;
        if (indexUpdate < 0)
            return;

        if (this.titles.length > 0) {
            this.titles.forEach((x, i) => {
                var step = this.stepper.steps.find((x, stepindex) => i === stepindex);
                if (i < indexUpdate) {
                    step.completed = true;
                }
                else {
                    step.completed = false;
                }
            })
            this.stepper.selectedIndex = indexUpdate;
        }

        if (this.nextValidStep < indexUpdate) {
            this.nextValidStep = indexUpdate;
            this.cdRef.detectChanges();
        }
    }

    preventClick(event: MouseEvent) {
        event.stopPropagation();
    }
}
