<div id="main-layout-container" class="d-flex flex-column min-h-100 futr-theme" screen-width>



  <nav class="top navbar navbar-expand-lg " [ngClass]="{'animate__animated animate__fadeInDown': !(animated$ | async)}">
    <div class="container">
      <div class="navbar-content d-flex w-100">
        <div class="navbar-brand logo flex-fill">
          <div class="logo svelte-19oerrd">
            <svg style="width:auto" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="svelte-ao381y">
              <path
                d="M60.3975 22.2537L62.3912 0L82.0829 1.77545L81.6534 6.82602L68.2804 5.60172L67.8817 9.94832L77.8501 10.836L77.3899 15.6724L67.4522 14.7541L66.7161 22.8047L60.3975 22.2537Z"
                fill="white"></path>
              <path
                d="M86.5609 30.1206C83.7082 28.53 81.5856 25.896 80.641 22.7741C80.4283 21.2437 80.5311 19.6861 80.9424 18.1965C81.3538 16.707 82.0653 15.3169 83.0335 14.1113L89.4439 4.62222L94.7198 8.17293L87.941 18.2132C87.3979 18.9468 87.0021 19.7783 86.7754 20.662C86.6228 21.3681 86.6228 22.0986 86.7754 22.8047C86.9286 23.4519 87.2218 24.0579 87.6344 24.5802C88.5793 25.6717 89.7961 26.4951 91.1617 26.9677C91.8175 27.1204 92.4997 27.1204 93.1555 26.9677C93.8547 26.8264 94.5143 26.534 95.0879 26.1107C95.8301 25.5693 96.4645 24.8945 96.9588 24.1209L103.737 14.1113L109.013 17.6622L102.572 27.1513C101.813 28.4907 100.797 29.6671 99.5808 30.6127C98.3648 31.5583 96.9734 32.2545 95.4866 32.6611C92.2928 33.0134 89.0879 32.1013 86.5609 30.1206Z"
                fill="white"></path>
              <path
                d="M98.001 40.6527L112.938 32.1123L109.074 25.378L113.552 22.8375L124.44 41.8159L119.962 44.3564L116.098 37.5917L101.13 46.1319L98.001 40.6527Z"
                fill="white"></path>
              <path
                d="M104.475 61.2185C103.973 57.9912 104.754 54.6958 106.653 52.0355C107.735 50.9251 109.033 50.048 110.468 49.4583C111.903 48.8687 113.444 48.579 114.995 48.6071L126.467 47.8419L126.866 54.1782L114.597 54.9741C113.686 55.0078 112.791 55.2157 111.959 55.5864C111.295 55.8629 110.706 56.293 110.241 56.8413C109.801 57.3616 109.496 57.9818 109.352 58.6474C109.242 59.3574 109.242 60.0799 109.352 60.79C109.397 61.5004 109.541 62.201 109.781 62.8714C110.009 63.5028 110.4 64.0627 110.916 64.4939C111.446 64.9702 112.074 65.3254 112.756 65.5347C113.646 65.7688 114.569 65.8514 115.486 65.7794L127.571 64.9835L128 71.3199L116.529 72.0545C115.008 72.2782 113.458 72.1987 111.968 71.8204C110.478 71.4421 109.078 70.7726 107.849 69.8506C105.634 67.5254 104.423 64.4259 104.475 61.2185Z"
                fill="white"></path>
              <path
                d="M97.6644 88.1574C98.3014 87.9736 98.9674 87.9113 99.6275 87.9737C100.272 87.9737 100.885 87.9738 101.529 88.2187L103.339 88.5247C103.887 88.6166 104.447 88.6166 104.995 88.5247C105.558 88.4248 106.086 88.1824 106.529 87.8207C107.11 87.3607 107.564 86.7601 107.847 86.0759L109.504 82.7395L102.204 79.0969L105.026 73.4339L125.055 83.3822L119.595 94.3407C118.575 96.8642 116.851 99.0423 114.626 100.616C113.821 101.084 112.905 101.33 111.973 101.33C111.041 101.33 110.126 101.084 109.32 100.616C108.497 100.233 107.781 99.6551 107.234 98.9322C106.784 98.2721 106.49 97.5183 106.375 96.7282C106.239 95.8765 106.239 95.0087 106.375 94.1571C106.57 93.1996 106.836 92.2583 107.173 91.341C106.731 92.281 106.093 93.1158 105.302 93.7897C104.705 94.251 104.014 94.575 103.277 94.7387C102.576 94.8453 101.863 94.8453 101.161 94.7387C100.425 94.7387 99.7196 94.555 98.9835 94.402L96.8055 94.0347C96.0825 93.9428 95.3509 93.9428 94.6279 94.0347L97.6644 88.1574ZM110.731 89.3512C109.504 91.8 109.749 93.4529 111.436 94.2794C113.123 95.1059 114.503 94.2795 115.73 91.8306L118.154 86.9942L113.154 84.5148L110.731 89.3512Z"
                fill="white"></path>
              <path
                d="M92.0505 95.3531L102.632 115.035L84.4132 124.77L82.0207 120.331L94.6269 113.566L92.848 110.199L83.0327 115.464L80.8551 111.454L90.67 106.189L88.8605 102.791L75.6103 109.893L73.2178 105.424L92.0505 95.3531Z"
                fill="white"></path>
              <path
                d="M52.147 102.82C53.3581 103.171 54.538 103.621 55.6744 104.167C57.5843 105.059 59.36 106.213 60.9499 107.595C61.5224 108.116 62.0555 108.679 62.5449 109.279L58.6495 112.983C57.6735 111.788 56.5141 110.754 55.2142 109.922C53.644 108.8 51.8897 107.961 50.0304 107.442C48.9618 107.053 47.8175 106.917 46.6873 107.044C46.359 107.058 46.0425 107.17 45.7797 107.367C45.5169 107.564 45.3202 107.835 45.2151 108.146C45.2151 108.605 45.215 109.064 45.9205 109.524C46.7881 110.114 47.7009 110.636 48.6503 111.085L52.423 112.921C53.378 113.401 54.3003 113.942 55.1835 114.543C56.0206 115.074 56.7662 115.735 57.3918 116.503C57.9687 117.189 58.3876 117.994 58.6188 118.86C58.8923 119.789 58.8923 120.777 58.6188 121.706C58.3492 122.645 57.8423 123.499 57.1466 124.186C56.42 124.922 55.5093 125.45 54.5088 125.716C53.2826 126.061 52.0083 126.206 50.7358 126.145C49.1018 126.054 47.485 125.767 45.9205 125.288C44.6665 124.934 43.4455 124.473 42.2707 123.91C41.314 123.464 40.4002 122.931 39.5408 122.319C38.2679 121.485 37.1385 120.452 36.1973 119.258L40.1541 115.829C40.9725 116.984 42.0156 117.963 43.2213 118.707C44.5821 119.634 46.0837 120.337 47.6686 120.788C50.4598 121.615 51.9935 121.461 52.3308 120.298C52.3308 119.778 52.3308 119.258 51.564 118.707C50.6968 118.056 49.7612 117.501 48.7731 117.054L45.3379 115.431C44.281 114.901 43.2568 114.308 42.2707 113.656C41.4079 113.124 40.6321 112.463 39.9703 111.697C39.3808 111.054 38.9593 110.276 38.7433 109.432C38.5172 108.569 38.5172 107.662 38.7433 106.799C39.0158 105.835 39.5213 104.952 40.2155 104.228C40.9786 103.472 41.916 102.915 42.9454 102.606C44.2474 102.219 45.6073 102.063 46.9632 102.146C48.7111 102.174 50.4503 102.4 52.147 102.82Z"
                fill="white"></path>
              <path
                d="M32.9151 90.3894C35.2663 92.6521 36.6509 95.7337 36.7798 98.9908C36.5852 100.525 36.0831 102.003 35.3031 103.339C34.5232 104.675 33.4815 105.841 32.2404 106.766L23.6214 114.326L19.4193 109.551L28.6209 101.562C29.3259 100.988 29.9099 100.281 30.3387 99.4805C30.6834 98.8487 30.8823 98.1481 30.9213 97.4297C30.9549 96.7537 30.8178 96.08 30.5226 95.4706C30.219 94.8181 29.8268 94.2103 29.357 93.6645C28.8827 93.1315 28.3353 92.6679 27.7313 92.2872C27.1711 91.9253 26.5265 91.7144 25.8604 91.675C25.1413 91.63 24.4209 91.7341 23.7442 91.981C22.8945 92.3276 22.115 92.8256 21.4438 93.4504L12.2421 101.44L8.04004 96.6644L16.6894 89.1036C17.756 87.9922 19.0367 87.1075 20.4545 86.5026C21.8724 85.8976 23.3984 85.5851 24.9404 85.5836C28.1284 86.0768 30.9934 87.8034 32.9151 90.3894Z"
                fill="white"></path>
              <path
                d="M25.5544 80.1711L3.74653 85.6502L0.679315 73.5898C0.261414 72.0833 0.0348011 70.5306 0.00461802 68.9678C-0.0344246 67.7947 0.174445 66.6266 0.617911 65.5395C0.997483 64.6136 1.63636 63.8165 2.45816 63.2437C3.38126 62.6032 4.42455 62.1553 5.52538 61.9273C6.59117 61.6232 7.70825 61.54 8.80751 61.6825C9.81778 61.8226 10.7772 62.2119 11.5988 62.8151C12.5299 63.563 13.3016 64.4893 13.8685 65.5395C14.625 66.938 15.1921 68.4304 15.5552 69.9778L17.0274 75.7632L23.898 74.0184L25.5544 80.1711ZM11.2918 71.2636C11.16 70.75 10.9858 70.2482 10.7706 69.7635C10.5654 69.3481 10.2845 68.9745 9.94236 68.6616C9.61086 68.3762 9.20806 68.1858 8.77681 68.1106C8.24137 67.9927 7.68653 67.9927 7.15109 68.1106C6.70899 68.1683 6.29064 68.3437 5.93986 68.6184C5.58907 68.8931 5.3187 69.257 5.15733 69.6718C4.91567 70.595 4.91567 71.5646 5.15733 72.4879L6.62953 78.3039L12.764 76.7734L11.2918 71.2636Z"
                fill="white"></path>
              <path
                d="M21.4751 60.7001L0.188477 53.8435L6.50712 34.2222L11.3532 35.7527L6.96727 49.3743L10.5864 50.5375L13.9909 39.9463L18.3465 41.3544L14.942 51.915L18.6225 53.1087L23.2233 38.8444L28.039 40.3749L21.4751 60.7001Z"
                fill="white"></path>
              <path
                d="M43.2823 27.8212C42.6425 27.678 42.0311 27.4296 41.4728 27.0864C40.9221 26.7473 40.3896 26.3795 39.8778 25.9845L38.4056 24.9132C37.963 24.5571 37.4513 24.2966 36.9027 24.1479C36.3593 24.0093 35.7896 24.0093 35.2463 24.1479C34.5227 24.2955 33.8478 24.6217 33.2832 25.0969L30.216 27.3007L35.062 33.882L29.94 37.647L16.6895 19.6177L26.5965 12.3935C28.6433 10.6002 31.1693 9.44084 33.8658 9.05713C35.2385 9.10357 36.5562 9.60841 37.6073 10.4909C38.6584 11.3733 39.3821 12.5822 39.6629 13.9241C39.7664 14.7282 39.6825 15.5453 39.4176 16.3117C39.131 17.1328 38.7055 17.8989 38.16 18.5769C37.5718 19.3394 36.9147 20.0465 36.1969 20.689C37.0221 20.0745 37.96 19.6274 38.9575 19.3728C39.7062 19.2194 40.4784 19.2194 41.2272 19.3728C41.9123 19.5558 42.5643 19.8449 43.1595 20.2297C43.773 20.6277 44.356 21.0564 44.9388 21.5155L46.7176 22.8317C47.3046 23.26 47.9572 23.5908 48.65 23.8113L43.2823 27.8212ZM32.2097 20.7807C34.4181 19.1584 34.97 17.7198 33.8658 16.0668C32.7616 14.4139 31.136 14.6894 28.9276 16.2811L24.5417 19.3421L27.8542 23.8113L32.2097 20.7807Z"
                fill="white"></path>
            </svg>
          </div>
        </div>

        <div class="{{screenWidth>=992?'form-inline float-right':'d-flex flex-row w-100 pl-3'}} ">
          <app-member-account-dropdown
            class="flex-fill {{screenWidth>992?'':'margin-auto'}} "></app-member-account-dropdown>
          <ng-container *ngIf="screenWidth>992">

            <a class="align-items-center btn btn-primary d-flex mr-2" *ngIf="screenWidth>500"
              href="https://www.futuresuper.com.au/support-and-faqs" target="_blank">FAQs
            </a>

            <a class="align-items-center btn btn-primary d-flex mr-2" *ngIf="screenWidth>500"
              href="https://www.futuresuper.com.au/contact-us" target="_blank">Contact us
            </a>

            <app-button class="flex-fill logout-btn" title="Logout" icon="logout" color="light"
              (onClick)="onSignoutClick()"></app-button>
          </ng-container>
          <ng-container *ngIf="screenWidth<=992">

            <button class="btn btn-primary mobile-top-main-btn " [matMenuTriggerFor]="menu">
              <mat-icon>more_horiz</mat-icon>
            </button>

            <!-- <app-button [matMenuTriggerFor]="menu" icon="more_horiz" class="mobile-top-main-btn">
            </app-button> -->

            <mat-menu #menu="matMenu" class="mobile-top-menu">
              <a mat-menu-item class="no-text-decoration d-flex flex-row"
                href="https://www.futuresuper.com.au/support-and-faqs" target="_blank">
                <div class="flex-fill">FAQs</div>
                <div class="d-flex align-items-center"><mat-icon class="">quiz</mat-icon></div>
              </a>

              <a mat-menu-item class="no-text-decoration d-flex flex-row"
                href="https://www.futuresuper.com.au/contact-us" target="_blank">
                <div class="flex-fill">Contact us</div>
                <div class="d-flex align-items-center"><mat-icon class="">email</mat-icon></div>
              </a>

              <button mat-menu-item class="d-flex flex-row" (click)="onSignoutClick()">
                <div class="flex-fill">Logout</div>
                <div class="d-flex align-items-center"><mat-icon class="">logout</mat-icon></div>
              </button>
            </mat-menu>
          </ng-container>
          <!-- <app-user-top-dropdown></app-user-top-dropdown> -->
        </div>
      </div>
    </div>

  </nav>
  <nav class="menu navbar navbar-expand-lg "
    [ngClass]="{'animate__animated animate__fadeInDown': !(animated$ | async)}">
    <div class="container p-0" *ngIf="screenWidth>=992 && selectedAccount" appanimatecss="animate__fadeInDown">
      <ng-container *ngTemplateOutlet="templateMenu">
      </ng-container>

    </div>
  </nav>

  <div class="main-content flex-fill d-flex flex-column w-100">

    <div class="w-100 h-100 overlay" *ngIf="isFetching$|async">
    </div>

    <div class="navbar-global-wrapper">
      <nav class="navbar navbar-inverse navbar-global navbar-fixed-top">
        <div class="container">
          <div class="navbar-header d-flex flex-row w-100">
          </div>
        </div>
      </nav>
    </div>

    <main class="app-content container d-flex flex-fill" (swiperight)="onSwipeToggle($event)">
      <app-scroll-up></app-scroll-up>

      <router-outlet name="master"></router-outlet>

      <div class="spinner-container" *ngIf="isFetching$ | async">
        <mat-progress-spinner class="app-spinner animate__animated animate__bounceIn"
          mode="indeterminate"></mat-progress-spinner>
      </div>
    </main>


    <nav class="navbar-secondary toolbar-bottom d-flex align-items-center flex-row" *ngIf="screenWidth<992"
      appanimatecss="animate__fadeInUp animate__delay-1s">
      <div class="d-flex flex-fill option flex-column align-items-center" (click)="openMenu()" matRipple>
        <mat-icon>more_horiz</mat-icon>
        <label>Menu</label>
      </div>
      <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/dashboard"
        routerLinkActive="active-link">
        <mat-icon>house</mat-icon>
        <label>Dashboard</label>
      </div>
      <div class="d-flex flex-fill option flex-column align-items-center" routerLink="/personal-details"
        routerLinkActive="active-link">
        <mat-icon>person</mat-icon>
        <label>Profile</label>
      </div>
    </nav>
  </div>

  <app-footer></app-footer>

  <ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet>

    <mat-accordion *ngIf="menuList$ | async; let menuList" class="mobile-menu">
      <mat-expansion-panel [hideToggle]="item.children.length == 0?true: false" *ngFor="let item of menuList"
        [disabled]="item.children.length == 0?true: false" [ngClass]="{'d-none': (item.featureFlag | featureToggle | async) === featureToggleValues?.hidden,
        'disabled': (item.featureFlag | featureToggle | async) === featureToggleValues?.disabled}">
        <mat-expansion-panel-header (click)="onItemSelectedClick(item)">
          <mat-panel-title class="d-flex align-items-center">
            <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon><span class="nav-label"
              style="white-space: pre;">{{item.name | textTransform}}</span>
          </mat-panel-title>

        </mat-expansion-panel-header>
        <ul class="pl-2 matmenu-list list-unstyled">
          <li *ngFor="let child of item.children">
            <ng-container *ngIf="child.featureFlag | featureToggle | async; let childFlagValue;">
              <a *ngIf="child.link?.startsWith('/') && childFlagValue !== featureToggleValues?.hidden"
                [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center"
                [routerLink]="child.link" [fragment]="child.fragment">
                <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
                <div class="nav-label">{{child.name|textTransform}}</div>
              </a>
              <a *ngIf="child.link?.startsWith('http') && childFlagValue !== featureToggleValues?.hidden"
                [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center" [href]="child.link"
                [attr.target]="child.target ? child.target : null">
                <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
                <div class="nav-label">{{child.name|textTransform}}</div>
              </a>
            </ng-container>
          </li>
        </ul>
      </mat-expansion-panel>

    </mat-accordion>
  </ng-template>

  <ng-template #templateMenu>
    <ng-container *ngIf="menuList$ | async; let menuList">
      <div *ngFor="let item of menuList" class="menu-items" [ngClass]="{'d-none': (item.featureFlag | featureToggle | async) === featureToggleValues?.hidden,
        'disabled': (item.featureFlag | featureToggle | async) === featureToggleValues?.disabled}">

        <a class="btn d-flex align-items-center" [class.active]="isMenuExpanded(item)"
          *ngIf="(item.featureFlag | featureToggle | async) !== featureToggleValues?.hidden"
          [ngClass]="{'disabled': (item.featureFlag | featureToggle | async) === featureToggleValues?.disabled, 'active-link': selectedParentMenu == item.name}"
          [routerLink]="item.link" routerLinkActive="active-link" [attr.target]="item.target ? item.target : null"
          (click)="onItemSelectedClick(item)" [matMenuTriggerFor]="item.children.length > 0?menu:placeholder"
          aria-expanded="false">
          <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon><span class="nav-label"
            style="white-space: pre;">{{item.name|textTransform}}</span>
          <mat-icon *ngIf="item.children.length > 0" class="item-arrow">expand_more</mat-icon>
        </a>
        <mat-menu #placeholder="matMenu" class="placeholder"></mat-menu>
        <mat-menu #menu="matMenu" class="main-menu">
          <ul class="pl-4 matmenu-list list-unstyled">
            <li *ngFor="let child of item.children" style="">
              <ng-container *ngIf="child.featureFlag | featureToggle | async; let childFlagValue;">
                <a *ngIf="child.link?.startsWith('/') && childFlagValue !== featureToggleValues?.hidden"
                  [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                  [routerLinkActive]="['is-active']"
                  class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center"
                  [routerLink]="child.link" [fragment]="child.fragment">
                  <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
                  <div class="nav-label">{{child.name|textTransform}}</div>
                </a>
                <a *ngIf="child.link?.startsWith('http') && childFlagValue !== featureToggleValues?.hidden"
                  [ngClass]="{'disabled': childFlagValue === featureToggleValues?.disabled}"
                  class="btn no-text-decoration menu-item sub-menu-item d-flex align-items-center" [href]="child.link"
                  [attr.target]="child.target ? child.target : null">
                  <mat-icon class="position-relative" *ngIf="child.icon">{{child.icon}}</mat-icon>
                  <div class="nav-label">{{child.name|textTransform}}</div>
                </a>
              </ng-container>
            </li>
          </ul>
        </mat-menu>
      </div>
    </ng-container>
  </ng-template>

</div>