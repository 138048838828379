<div id="start-a-pension-account-details">
    <h2 class="mb-3">Pension account details</h2>
    <div *ngIf="form$|async; let form">
        <form [ngrxFormState]="form" class="ml-3 mr-3">

            <div class="question d-flex flex-column description mt-4 pl-2">
                <div class="title d-flex flex-row align-items-center">{{formData.customWording.accountInfo}}:
                </div>
                <div class="info">{{formData.accumulationAccountInfo}}</div>
            </div>

            <div class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">{{formData.customWording.transferAmount}}:
                        <mat-icon class="ml-2 tooltip-readonly"
                            [matTooltip]="formData.customWording.partialAmountWarning">help_outline</mat-icon>
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio [customList]="transferTypes$"
                            [control]="form.controls.transferType"></app-entry-radio>
                    </div>
                </div>
            </div>

            <div *ngIf="form.value.transferType === 1" class="question d-flex flex-row description mt-4 pl-2">
                <i [innerHtml]="formData.customWording.fullAmountWarning"></i>
            </div>

            <ng-container *ngIf="form.value.transferType === 3">
                <div class="question d-flex flex-row description mt-4 pl-2">
                    <div>
                        <div class="title d-flex flex-row align-items-center">Partial balance amount:
                        </div>
                        <div class="custom-control custom-radio answer">
                            <app-entry-number icon="attach_money" title="" [control]="form.controls.partialAmount"
                                [decimalplaces]="2" [showincremental]="false"></app-entry-number>

                        </div>
                    </div>
                </div>
                <p class="error" *ngIf="form.value.partialAmount > form.value.accountBalance">Amount cannot be larger
                    than your
                    account balance.</p>
                <p class="error" *ngIf="form.value.partialAmount < 20000">Amount must be $20000 or larger.</p>

            </ng-container>

            <div class="question d-flex flex-column description mt-4 pl-2 options-container">
                <div class="title mb-1 d-flex flex-row align-items-center">
                    {{formData.customWording.pensionOptionsQuestion}}:
                    <mat-icon class="ml-2 tooltip-readonly"
                        [matTooltip]="formData.customWording.investmentOptionsTooltip">help_outline</mat-icon>
                </div>
                <app-start-a-pension-investment-options [form]="form.controls.options"
                    [totalCount]="currentTotalPercent$ | async"></app-start-a-pension-investment-options>
            </div>

        </form>
        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.start-a-pension.cancel.icon' | translate}}"
                    [isDisabled]="isSubmitting" color="{{'BTN.start-a-pension.cancel.color' | translate}}"
                    (onClick)="exit()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.start-a-pension.back.class' | translate}}" title="Back"
                    icon="{{'BTN.start-a-pension.back.icon' | translate}}" [isDisabled]="isSubmitting"
                    color="{{'BTN.start-a-pension.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.start-a-pension.continue.icon' | translate}}"
                    color="{{'BTN.start-a-pension.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || isSubmitting || ((currentTotalPercent$|async) !== 100)" loading
                    loading (onClick)="saveAccountDetails(form.value)"></app-button>
            </div>
        </div>
    </div>
</div>