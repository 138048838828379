<div id="access-your-super-start" *ngIf="filter$|async; let filter">
  <div class="d-flex justify-content-end">
    <app-button-toggle [control]="filter.controls.activeOptionButton"
      list="accessYourSuperApplicationStatuses"></app-button-toggle>
  </div>
  <p class="pt-2" *ngIf="filter.value.activeOptionButton === 'Draft'">Your applications in draft will be automatically
    cancelled after 30 days from when they were created.</p>
  <p class="pt-2" *ngIf="filter.value.activeOptionButton === 'Submitted'">You can add additional documentation to your
    submitted applications which have not been completed.</p>
  <div class="table-wrapper mt-3">
    <table class="table {{'VIEWS.Global.table-default-style' | translate}}">
      <thead>
        <tr>
          <th width="33%" scope="col">Condition of release</th>
          <th *ngIf="filter.value.activeOptionButton !== 'Submitted'" width="20%" scope="col">Created on</th>
          <th *ngIf="filter.value.activeOptionButton === 'Submitted'" width="20%" scope="col">Submitted on</th>
          <th *ngIf="filter.value.activeOptionButton === 'Submitted'" width="20%" scope="col">Case status</th>
          <th *ngIf="filter.value.activeOptionButton !== 'Cancelled' && (screenWidth > 768)" width="30%" scope="col">
            Actions
          </th>
          <th *ngIf="filter.value.activeOptionButton === 'Cancelled'" width="20%" scope="col">Cancelled on</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of currentList">
          <tr>
            <td>{{item.applicationType}}</td>
            <td *ngIf="filter.value.activeOptionButton !== 'Submitted'">{{item.startedDate | date:'dd MMM yyyy'}}</td>
            <!-- created date -->
            <td *ngIf="filter.value.activeOptionButton !== 'Draft'">{{item.lastModified | date:'dd MMM yyyy'}}</td>
            <!-- submitted on / cancelled on field -->
            <td *ngIf="filter.value.activeOptionButton === 'Submitted'">{{item.caseStatus}}</td> <!-- case status -->
            <td *ngIf="filter.value.activeOptionButton === 'Draft' && (screenWidth > 768)">
              <div class="d-flex flex-row">
                <app-button class="continue-btn" title="Continue"
                  icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                  color="{{'BTN.access-your-super.continue.color' | translate}}"
                  (onClick)="continueApplication(item.applicationId, true)"
                  [isDisabled]="isSubmitting$|async"></app-button>
                <app-button class="ml-2 cancel-btn" title="Cancel"
                  icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                  color="{{'BTN.access-your-super.cancel.color' | translate}}"
                  (onClick)="cancelApplication($event, item.applicationId)" [displayDialog]="true"
                  [dialogText]="cancelDialogText" [isDisabled]="isSubmitting$|async"></app-button>
              </div>
            </td>
            <td *ngIf="filter.value.activeOptionButton === 'Submitted' && (screenWidth > 768)">
              <div class="d-flex flex-row flex-fill">
                <app-button *ngIf="item.caseStatusId !== 3" class="continue-btn" title="Update documents"
                  icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                  color="{{'BTN.access-your-super.continue.color' | translate}}"
                  (onClick)="continueApplication(item.applicationId, false)"
                  [isDisabled]="isSubmitting$|async"></app-button>
              </div>
            </td>
          </tr>
          <tr *ngIf="filter.value.activeOptionButton === 'Draft' && (screenWidth < 769)">
            <td colspan="2">
              <div class="d-flex flex-row flex-fill">
                <app-button class="mr-1 flex-fill continue-btn" title="Continue"
                  icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                  color="{{'BTN.access-your-super.continue.color' | translate}}"
                  (onClick)="continueApplication(item.applicationId, true)"
                  [isDisabled]="isSubmitting$|async"></app-button>
                <app-button class="ml-1 flex-fill cancel-btn" title="Cancel"
                  icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                  color="{{'BTN.access-your-super.cancel.color' | translate}}"
                  (onClick)="cancelApplication($event, item.applicationId)" [displayDialog]="true"
                  [dialogText]="cancelDialogText" [isDisabled]="isSubmitting$|async"></app-button>
              </div>
            </td>
          </tr>
          <tr *ngIf="filter.value.activeOptionButton === 'Submitted' && (screenWidth < 769)">
            <td colspan="3">
              <app-button *ngIf="item.caseStatusId !== 3" class="continue-btn" title="Update documents"
                icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                color="{{'BTN.access-your-super.continue.color' | translate}}"
                (onClick)="continueApplication(item.applicationId, false)"
                [isDisabled]="isSubmitting$|async"></app-button>
            </td>
          </tr>

        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-end">
    <app-button class="mt-3 ml-1" title="Start new application"
      icon="{{'BTN.access-your-super.start.icon' | translate}}"
      color="{{'BTN.access-your-super.start.color' | translate}}" (onClick)="createNewApplication()"
      [isDisabled]="isSubmitting$|async">
    </app-button>
  </div>
</div>