import { Component, Input, OnDestroy, OnInit, } from "@angular/core";
import { ComponentBase } from "src/app/views/component-base";
import { AppState } from "src/app/store/app.states";
import { Store, } from "@ngrx/store";
import { PensionApplicationDocumentModel, StartAPensionFormModel } from "src/app/model/start-a-pension-form.model";

@Component({
    selector: 'app-start-a-pension-review-display',
    templateUrl: './start-a-pension-review-display.component.html',
    styleUrls: ['./start-a-pension-review-display.component.scss']
})

export class StartAPensionReviewDisplayComponent extends ComponentBase implements OnInit, OnDestroy {
    @Input() set data(value: StartAPensionFormModel) {
        this.formData = value;
    }

    formData: StartAPensionFormModel = null;

    constructor(public store: Store<AppState>) {
        super();
    }

    async ngOnInit() {
        super.ngOnInitBase();

    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
    }

    checkIfDocumentsExist(overview: PensionApplicationDocumentModel) {
        var uploadedDocs = overview.documentList.filter(x => x.originalFileName)

        if (uploadedDocs.length > 0 || overview.documentsUploaded)
            return true;

        return false;
    }
}
