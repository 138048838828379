<div *ngIf="model$ | async; let model">
    <h2>Terminal medical condition benefit payment</h2>
    <div *ngIf="form$ | async; let form">
        <form [ngrxFormState]="form" class="ml-3 mr-3">
            <div class="question d-flex flex-row description mt-4 pl-2">
                <div>
                    <div class="title mb-1">{{model.formLabels.paymentType}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio list="accessYourSuperPaymentTypes"
                            [control]="form.controls.paymentType"></app-entry-radio>
                    </div>
                </div>
            </div>
            <div class="question description mt-4 pl-2">
                <div>
                    <div class="title d-flex flex-row align-items-center">{{model.formLabels.benefitAmount}}
                        <mat-icon *ngIf="showBenefitAmountTooltip" class="ml-2 mb-1 tooltip-readonly"
                            [matTooltip]="model.formLabels.benefitAmountTooltip">help_outline</mat-icon>
                    </div>
                    <div *ngIf="form.value.paymentType === 0" class="custom-control custom-radio answer">
                        <app-entry-radio [customList]="fullAmountRadioOptions$"
                            [control]="form.controls.fullAmountSelection"></app-entry-radio>
                    </div>
                    <div *ngIf="form.value.paymentType === 1"
                        class="custom-control custom-radio answer benefit-amount resizing">
                        <app-entry-number title="Please enter an amount"
                            [control]="form.controls.partialAmountSpecified" icon="attach_money" appanimatecss
                            [decimalplaces]="0" [showincremental]="false">
                        </app-entry-number>
                    </div>
                </div>
            </div>
            <div class="question d-flex flex-row description pl-2" [ngClass]="{'mt-4': form.value.paymentType === 0}">
                <div>
                    <div class="title mb-1">{{model.formLabels.releaseCondition}}</div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio [customList]="releaseConditionOptions$"
                            [control]="form.controls.releaseCondition"></app-entry-radio>
                    </div>
                </div>
            </div>
            <div class="question d-flex flex-row description mt-4  pl-2">
                <div>
                    <div class="title mb-1">{{model.formLabels.illHealthDate}}</div>
                    <div class="custom-control answer illhealthdate">
                        <app-entry-date [maxdate]="maxDate" [date]="form.controls.illHealthDate"></app-entry-date>
                    </div>
                </div>
            </div>
            <div *ngIf="showSummaryTextBox"
                class="question d-flex flex-row description mt-4 pl-2 flex-fill text-resizing">
                <div class="w-100">
                    <div class="title mb-1 d-flex flex-row align-items-center">
                        {{model.formLabels.medicalConditionSummary}}
                        <mat-icon class="ml-2 tooltip-readonly"
                            [matTooltip]="model.formLabels.medicalConditionSummaryTooltip">help_outline</mat-icon>
                    </div>
                    <div class="custom-control answer w-100">
                        <app-entry-textarea [control]="form.controls.medicalConditionSummary"></app-entry-textarea>
                    </div>
                </div>
            </div>
            <div *ngIf="form.value.paymentType === 1" class="question d-flex flex-row description mt-2 pl-2">
                <div>
                    <div class="title mb-1 d-flex flex-row align-items-center">
                        {{model.formLabels.investmentOptionChoice}}
                        <mat-icon class="ml-2 tooltip-readonly"
                            [matTooltip]="model.formLabels.investmentOptionChoiceTooltip">help_outline</mat-icon>
                    </div>
                    <div class="custom-control custom-radio answer">
                        <app-entry-radio list="yesNo"
                            [control]="form.controls.partialWithdrawalSelection"></app-entry-radio>
                    </div>
                </div>
            </div>
            <div *ngIf="form.value.paymentType === 1 && form.value.partialWithdrawalSelection"
                class="question d-flex flex-column description mt-4 pl-2">
                <div class="title mb-1">
                    {{model.formLabels.provideInvestmentOption}}
                </div>
                <app-access-your-super-drawdown [form]="form$ | async"
                    [disclaimer]="model.formLabels.drawdownDisclaimer"
                    [totalCount]="currentTotalPercent$ | async"></app-access-your-super-drawdown>
            </div>
        </form>

        <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                    [isDisabled]="isSubmitting" color="{{'BTN.access-your-super.cancel.color' | translate}}"
                    (onClick)="exitForm()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button *ngIf="showBackButton" [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="flex-fill {{'BTN.access-your-super.back.class' | translate}}" title="Back"
                    icon="{{'BTN.access-your-super.back.icon' | translate}}" [isDisabled]="isSubmitting"
                    color="{{'BTN.access-your-super.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                    color="{{'BTN.access-your-super.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || isSubmitting" loading (onClick)="goNextStep()"></app-button>
            </div>
        </div>
    </div>
</div>