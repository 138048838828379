import { createReducer, on, Action } from '@ngrx/store';
import {
    createFormGroupState,
    onNgrxForms, onNgrxFormsAction, setValue, SetValueAction,
    updateGroup,
    validate,
    wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { IStartAPensionPaymentDetailsFormComponentState, StartAPensionPaymentDetailsForm } from './state';
import { ResetComponentStateAction, SetPaymentDetailsFormDataAction, SubmitFormAction, SubmitFormErrorAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

const formName = 'StartAPensionPaymentDetailsForm';

var helper = new Helper();

export const state: IStartAPensionPaymentDetailsFormComponentState = {
    formData: null,
    form: createFormGroupState(formName, new StartAPensionPaymentDetailsForm()),
    frequencyTypes: [],
    paymentTypes: [],
    commencementOfPaymentsOption: [],
    investmentOptions: [],
};

export const validateStartAPensionPaymentDetailsForm = updateGroup<StartAPensionPaymentDetailsForm>({
    commencementOfDate: (state, parentState) => {
        const validateCommencementDate = (date: string) => {
            const errors = {};
            if (parentState.value.commencementOfDateOption === 1) return errors;

            const currentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
            const selectedMonth = new Date(date);
            const selectedMonthFormatted = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);

            if (selectedMonthFormatted instanceof Date && isNaN(selectedMonthFormatted.getTime())) {
                errors["customError"] = "Date is invalid.";
                return errors;
            }

            if (selectedMonthFormatted <= currentMonth) {
                errors["customError"] = "Commencement date must be in the future.";
                return errors;
            }
            return errors;
        }

        state = validate(state, validateCommencementDate);
        return state;
    },
    paymentAmount: (state, parentState) => {
        const validatePaymentAmount = (value: number) => {
            const errors = {};

            if (parentState.value.paymentType !== 1) return errors;

            if (value <= 0) {
                errors["customError"] = "Payment amount must be above $0."
                return errors;
            }

            if (value > parentState.value.annualMax)
                errors["customError"] = `Payment amount must be below your annual maximum of $${parentState.value.annualMax}.`
            return errors;
        }

        state = validate(state, validatePaymentAmount);
        return state;
    }
})

const reducer = createReducer(state,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, (state, _action) => {
        return state;
    }),
    on(SetPaymentDetailsFormDataAction, (state, { data, investmentOptions, isTtr,
        accountBalance
    }) => {
        const options = helper.clone(investmentOptions);

        options.forEach(option => {
            const existingOption = data.savedData?.futureOptions.find(x => x.investmentOptionId == option.investmentOptionId);

            // if there is an existing option from saved data, assigned the saved percentage
            if (existingOption) {
                option.percentage = existingOption.percentage;
            }
            // if there isnt a matching saved option, set the percentage to 0 as they didnt allocate it
            else if (data.savedData?.futureOptions.length > 0) {
                option.percentage = 0;
            }

            // if none of these if statements match then the value defaults to what was provided in current options selection
        })

        const nextMonth = new Date(new Date().setMonth(new Date().getMonth() + 1))
        const formattedDate = `${nextMonth.getFullYear()}-${(nextMonth.getMonth() + 1).toString().padStart(2, '0')}-${nextMonth.getDate().toString().padStart(2, '0')}`;

        return {
            ...state,
            formData: data,
            form: setValue(Object.assign(new StartAPensionPaymentDetailsForm(),
                {
                    ...state.form.value,
                    frequencyType: data.savedData?.frequencyId ?? 4,
                    commencementOfDateOption: data.savedData?.paymentCommencementDate ? 2 : 1,
                    commencementOfDate: data.savedData?.paymentCommencementDate?.split("T")[0] ?? formattedDate,
                    paymentType: data.savedData?.pensionPaymentTypeId ?? 2,
                    paymentAmount: data.savedData?.paymentAmount ?? 0,
                    options,
                    wording: data.customWording,
                    accountBalance,
                    isTtr,
                    annualMax: isTtr ? accountBalance * .10 : accountBalance
                }
            ))(state.form),
            frequencyTypes: data.paymentFrequencyTypes.filter(x => Number(x.key) <= 4).reverse(),
            paymentTypes: isTtr ? data.paymentTypeTypes.filter(x => Number(x.key) <= 3) : data.paymentTypeTypes.filter(x => Number(x.key) <= 2),
            commencementOfPaymentsOption: [
                { key: 1, value: "First available payment date after receipt of your investment" },
                { key: 2, value: "Nominate a date for first payment" },
            ]
        }
    }),
    on(ResetComponentStateAction, (_state) => state),
);

const editStartAPensionPaymentDetailsFormState = wrapReducerWithFormStateUpdate(
    reducer,
    s => s.form,
    validateStartAPensionPaymentDetailsForm
)

export function startAPensionPaymentDetailsFormComponentReducer(state: any | undefined, action: Action) {
    return editStartAPensionPaymentDetailsFormState(state, action);
}
