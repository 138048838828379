import { createAction, props } from "@ngrx/store";
import { StartAPensionApplicationSummariesModel } from "src/app/model/start-a-pension-application-summaries.model";

export const ResetComponentStateAction = createAction('[Start a pension start component] reset component state')
export const RequestAction = createAction('[Start a pension start component] get data', props<{ accountId: number }>());
export const ResponseAction = createAction('[Start a pension start component] set data', props<{ payload: StartAPensionApplicationSummariesModel }>());

export const CancelApplicationAction = createAction('[Start a pension start component] cancel application', props<{ accountId: number, applicationId: number }>());
export const SetApplicationToCancelledAction = createAction('[Start a pension start component] set applicationToCancelled', props<{ applicationId: number }>());
export const ContinueApplicationRequestAction = createAction('[Start a pension start component] get form data', props<{ accountId: number, applicationId: number }>());
export const UpdateSubmittedApplicationRequestAction = createAction('[Start a pension start component] update submitted app', props<{ accountId: number, applicationId: number }>())

export const ProcessStartedAction = createAction('[Start a pension start component] started process');