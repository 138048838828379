import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-insurance-update-history-view',
    templateUrl: './insurance-update-history-view.component.html',
    styleUrls: ['./insurance-update-history-view.component.scss'],
    host: {
        class: 'w-100'
    }
})

export class InsuranceUpdateHistoryViewComponent extends ComponentBase implements OnInit, OnDestroy {

    constructor(public store: Store<AppState>, public router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
    }

}
