import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from 'src/app/modules/shared/store/shared.reducers';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { IStartAPensionPaymentDetailsFormComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionPaymentDetailsFormState = createSelector(memberPortalState, (state) => state.startAPensionPaymentDetailsFormComponentState);

export const startAPensionPaymentDetailsForm_Form = createSelector(
    getStartAPensionPaymentDetailsFormState,
    (state: IStartAPensionPaymentDetailsFormComponentState) => state.form
);

export const startAPensionEligibilityForm_FrequencyTypes = createSelector(
    getStartAPensionPaymentDetailsFormState,
    (state: IStartAPensionPaymentDetailsFormComponentState) => state.frequencyTypes
);

export const startAPensionEligibilityForm_CommencementOfDateTypes = createSelector(
    getStartAPensionPaymentDetailsFormState,
    (state: IStartAPensionPaymentDetailsFormComponentState) => state.commencementOfPaymentsOption
);

export const startAPensionEligibilityForm_PaymentTypes = createSelector(
    getStartAPensionPaymentDetailsFormState,
    (state: IStartAPensionPaymentDetailsFormComponentState) => state.paymentTypes
);

export const startAPensionPaymentDetailsForm_FutureTotalPercent = createSelector(
    getStartAPensionPaymentDetailsFormState,
    (state: IStartAPensionPaymentDetailsFormComponentState) => state.form?.value?.options?.reduce((a, b) => a + b.percentage, 0) ?? 0
);