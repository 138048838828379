import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from 'src/app/modules/shared/store/shared.reducers';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { IStartAPensionAccountDetailsFormComponentState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getStartAPensionAccountDetailsFormState = createSelector(memberPortalState, (state) => state.startAPensionAccountDetailsFormComponentState);

export const startAPensionAccountDetailsForm_Form = createSelector(
    getStartAPensionAccountDetailsFormState,
    (state: IStartAPensionAccountDetailsFormComponentState) => state.form
);

export const startAPensionEligibilityForm_TransferAmounts = createSelector(
    getStartAPensionAccountDetailsFormState,
    (state: IStartAPensionAccountDetailsFormComponentState) => state.transferTypes
);

export const startAPensionAccountDetailsForm_CurrentTotalPercent = createSelector(
    getStartAPensionAccountDetailsFormState,
    (state: IStartAPensionAccountDetailsFormComponentState) => state.form?.value?.options?.reduce((a, b) => a + b.percentage, 0) ?? 0
);